import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import CabinetDate from './CabinetDate';
import Cabinets from './Cabinets';
import NewCabinets from './NewCabinets';
import OldCabinets from './OldCabinets';
export default function CabinetRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Cabinets />} />
      <Route path="/new" element={<NewCabinets />} />
      <Route path="/existing" element={<OldCabinets />} />
      {/* <Route path="/date" element={<CabinetDate />} /> */}
    </Routes>
  );
}

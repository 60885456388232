import React, { useContext, useEffect, useState } from 'react';
import { WizardContext } from 'context/Context';
import CustomCheckbox from 'si/components/cw/components/CustomCheckbox';
import Wrapper from 'si/components/cw/components/Wrapper';
import { Alert, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { onVOC } from './WizardHome';

export default function QA() {
  const { qaInfo, setQaInfo, handleQaInfo, job } = useContext(WizardContext);
  const [enterIssue, setEnterIssue] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!onVOC(job)) {
      navigate('../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const toggleCheck = value => {
    setQaInfo({
      ...qaInfo,
      satisfied: value
    });
  };

  const toggleCheck2 = value => {
    setQaInfo({
      ...qaInfo,
      contact: value
    });
  };

  function setIssueText(e) {
    setQaInfo({
      ...qaInfo,
      issueText: e.target.value
    });
  }

  const next = () => {
    if (qaInfo?.satisfied === 'yes' || qaInfo?.contact) {
      handleQaInfo(qaInfo);
      navigate('../');
    } else {
      setEnterIssue(true);
    }
  };

  return enterIssue ? (
    <Wrapper>
      {job?.plumbingInstall && (
        <Alert variant="secondary">
          We are scheduled to reconnect your plumbing on{' '}
          {job?.plumbingInstall?.dateFormatted}
        </Alert>
      )}
      {job?.tileInstall && (
        <Alert variant="secondary">
          We are scheduled to install your tile backsplash on{' '}
          {job?.tileInstall?.dateFormatted}
        </Alert>
      )}
      <p>
        I'm sorry your experience was less than exceptional - but thanks for
        sharing your feedback!
      </p>
      <p>Please summarize your issue or concern:</p>
      <Form className="mb-3">
        <Form.Group>
          <Form.Control
            as="textarea"
            bssize="sm"
            id="issueText"
            value={qaInfo?.issueText}
            onChange={setIssueText}
            rows={5}
          />
        </Form.Group>
      </Form>
      <p>Would you like a member of our team to contact you about this?</p>
      <CustomCheckbox
        name="contact"
        type="radio"
        label={`Yes, please call me`}
        value="yes"
        toggleCheck={toggleCheck2}
        checked={qaInfo?.contact === 'yes'}
      />
      <CustomCheckbox
        name="contact"
        type="radio"
        label="No, this is feedback only"
        value="no"
        toggleCheck={toggleCheck2}
        checked={qaInfo?.contact === 'no'}
      />
      <Button
        className="w-100"
        variant="primary"
        disabled={!qaInfo?.contact || !qaInfo?.issueText}
        onClick={next}
      >
        Continue
      </Button>
    </Wrapper>
  ) : (
    <Wrapper>
      {job?.plumbingInstall && (
        <Alert variant="secondary">
          We're scheduled to reconnect your plumbing on{' '}
          {job?.plumbingInstall?.dateFormatted}
        </Alert>
      )}
      {job?.tileInstall && (
        <Alert variant="secondary">
          We're scheduled to install your tile backsplash on{' '}
          {job?.tileInstall?.dateFormatted}
        </Alert>
      )}
      <p>
        We strive to ensure that you are EXTREMELY SATISFIED with your final
        product and your experience working with Precision Countertops.
      </p>
      <p>Are you happy with your countertops?</p>
      <CustomCheckbox
        name="satisfied"
        type="radio"
        label={`Yes, I'm extremely satisfied!`}
        value="yes"
        toggleCheck={toggleCheck}
        checked={qaInfo?.satisfied === 'yes'}
      />
      <CustomCheckbox
        name="satisfied"
        type="radio"
        label="No, I have an issue or concern"
        value="no"
        toggleCheck={toggleCheck}
        checked={qaInfo?.satisfied === 'no'}
      />
      <Button
        className="w-100"
        variant="primary"
        disabled={!qaInfo?.satisfied}
        onClick={next}
      >
        Continue
      </Button>
    </Wrapper>
  );
}

import React, { useContext, useEffect, useState, Fragment } from 'react';
import { ConfigContext, WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Button } from 'react-bootstrap';
import Wrapper from './components/Wrapper';
import Edges from './components/edge-descriptions.json';

function massageEdgeName(str) {
  return str.replace(/(\d+)-(\d+")/, '$1/$2');
}

function Edge() {
  const { job, imgEdge, handleConfirmEdge, edgeDetails, edgeChanged } =
    useContext(WizardContext);
  const { roomInfo } = job;
  const navigate = useNavigate();
  const edgeDescription = edgeDetails
    ? edgeDetails.edgeDescription
    : roomInfo?.materialType &&
      Edges[roomInfo.materialType] &&
      Edges[roomInfo.materialType][roomInfo.edge];

  const [edgeName, setEdgeName] = useState('');
  const [selectEdge, setSelectEdge] = useState(false);
  const [decision, setDecision] = useState('');

  useEffect(() => {
    console.log(roomInfo);
    if (roomInfo?.edge) {
      setEdgeName(massageEdgeName(roomInfo.edge));
    }
  }, [roomInfo]);

  useEffect(() => {
    if (!job.roomInfo) {
      navigate('../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return selectEdge || edgeChanged || roomInfo?.missingEdge ? (
    <EdgeSelect />
  ) : (
    <Wrapper>
      <div className="container">
        <p>
          You've chosen a <strong>{edgeName}</strong> Edge.
        </p>
        {edgeDescription && <p className="fs--1">{edgeDescription}.</p>}
        <Row className="flex-center pt-1 pb-3">
          <img
            // style={{ width: '400px' }}
            alt={edgeName}
            src={imgEdge?.src}
          />
        </Row>
        <p>Is that correct?</p>
        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="yes"
              label="Yes, this is my edge"
              checked={decision === 'yes'}
              onChange={() => {
                setDecision('yes');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="no"
              label="No, this edge is not what I want"
              checked={decision === 'no'}
              onChange={() => {
                setDecision('no');
              }}
            />
          </Row>
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            if (decision === 'yes') {
              handleConfirmEdge();
              navigate('../rooms');
            } else {
              setSelectEdge(true);
            }
          }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  );
}

export default Edge;

function EdgeSelect() {
  const { job, handleConfirmEdge, edgeChoices } = useContext(WizardContext);
  const { roomInfo } = job;
  const { filesBucket } = useContext(ConfigContext);
  const navigate = useNavigate();
  const [decision, setDecision] = useState();

  useEffect(() => {
    console.log(`Edge: ${roomInfo?.edge}`);
  }, []);

  function compareEdges(edge1, edge2) {
    // console.log(`${edge1} ${edge1 === edge2 ? '===' : '!=='} ${edge2}`);
    return edge1 === edge2;
  }

  return edgeChoices ? (
    <Wrapper>
      <div className="container">
        <p>
          {roomInfo.missingEdge ? (
            <>Next, we need to select an edge. </>
          ) : (
            <>OK, let's get that corrected. </>
          )}
          Which edge would you like for your new {job?.roomInfo?.brand}{' '}
          countertops?
        </p>
        <Form className="outdoor-field">
          {edgeChoices
            .filter(e => e.darkKey || e?.customerName === roomInfo?.edge)
            .map(e => (
              <Fragment key={massageEdgeName(e?.customerName)}>
                <Row className="pl-3">
                  <Form.Check
                    type="radio"
                    name="splashtype"
                    id={e?.customerName}
                    label={
                      compareEdges(e?.customerName, roomInfo?.edge)
                        ? `${massageEdgeName(
                            e?.customerName
                          )} (your current edge)`
                        : massageEdgeName(e?.customerName)
                    }
                    checked={decision === e?.customerName}
                    onChange={() => {
                      setDecision(e?.customerName);
                    }}
                  />
                </Row>
                {e.edgeDescription && (
                  <p className="fs--1">{e.edgeDescription}</p>
                )}
                <Row className="flex-center pt-1 pb-3">
                  <img
                    // style={{ width: '400px' }}
                    alt={massageEdgeName(e?.customerName)}
                    src={`${filesBucket}/${e.darkKey}`}
                  />
                </Row>
                <hr />
              </Fragment>
            ))}
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="splashtype"
              id="contactMe"
              label="I'm not sure ... please call me later to discuss"
              checked={decision === 'contactMe'}
              onChange={() => {
                setDecision('contactMe');
              }}
            />
          </Row>
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            if (decision === roomInfo?.edge) {
              handleConfirmEdge(); // same as confirming original edge
            } else {
              handleConfirmEdge(
                // causes change to take place on back end
                decision === 'contactMe' ? null : decision,
                decision === 'contactMe' || null
              );
            }
            navigate('../rooms');
          }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  ) : (
    <p>loading ...</p>
  );
}

import React, { useState } from 'react';
import DisclosureLayout from './DisclosureLayout';
import PropTypes from 'prop-types';

export default function ButcherBlock({ standalone = false }) {
  const [list] = useState([
    {
      title: 'Water Rings',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/butcher-block-water-ring-2-pb4ewyloctzfuraikl5jf12b0zbf2t322do1frs4vs.jpg'
    },
    {
      title: 'Apply Mineral Oil',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/oiling-butcher-block-pajum12d6qv9b799akq96arwgqd75aqf8ikb97dsxk.jpg'
    },
    {
      title: 'Finished Island',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/Prop-Bros-2-e1521661426834-400x284-1-e1645221383690-pkpo5mwfq0ypdfbe7yun5elo101au46s3hvqcn8n94.jpg'
    }
  ]);
  return (
    <>
      {/* <p>test</p>
      <video
        src="https://library.staging.countertopwizard.com/Disclosure%2FCaesarstone%2FBlack%20Tempal%20(Natural%20Finish)-video"
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        controls={true}
        width={'100%'}
        height={'auto'}
      ></video> */}

      <DisclosureLayout
        standalone={standalone}
        title={'Butcher Block Disclosure'}
        videoId={'DH9d_u3Fgt0'}
        videoFile={
          'https://library.staging.countertopwizard.com/Disclosure%2FCaesarstone%2FBlack%20Tempal%20(Natural%20Finish)-video'
        }
        review={
          'PLEASE REVIEW THE INFORMATION BELOW REGARDING YOUR BUTCHER BLOCK COUNTERTOPS'
        }
        sliderList={list}
        aboutTitle={
          'Wood Butcher Block Countertops can add warmth and natural coloring to your home. It is a soft material easy on your belongings that is long lasting and durable if maintained properly. Here are some things you need to know regarding Butcher Block countertops:'
        }
        aboutList={[
          'Wood counters are not heat or stain resistant. Hot pans can not be set down on the counter without a pad or trivet.',
          'Butcher block requires some maintenance. Natural Penetrating Oil Finish seals and protects the surface of the wood and unlike a polyurethane finish, can be cut directly upon the surface. It is recommended to re-oil the wood surface every 3-4 months. This maintenance keeps the butcher block from drying out and helps preserve the block and extend its life over time. Natural oil finishes can easily be sanded and re-oiled for a like-new appearance.',
          'Being softer, butcher block countertops are more vulnerable to scratches and dents, however nicks and burns can be lightly sanded and the surface re-oiled.',
          'It expands or contracts as the temperature fluctuates. The wood can swell and shrink in conditions of extreme dryness or humidity, which may cause cracking.'
        ]}
      />
    </>
  );
}

ButcherBlock.propTypes = {
  standalone: PropTypes.bool
};

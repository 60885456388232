import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Wrapper from '../components/Wrapper';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';

// THIS SCREEN IS DEPRECATED - IT SHOULD NOT BE USED

export default function SinkCall() {
  const { job } = useContext(WizardContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!job.roomInfo || job.sinkMoraName) {
      navigate('../../');
    }
  }, []);
  function handleClick() {
    navigate('../../');
  }

  return (
    <Wrapper>
      <p>OK, I'll give you a call soon to discuss your sink details.</p>
      <Button className="w-100" onClick={handleClick}>
        Continue
      </Button>
    </Wrapper>
  );
}

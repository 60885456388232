import React, { useContext, useEffect } from 'react';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { WizardContext } from 'context/Context';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { prices } from 'prices';

export default function Existing() {
  const { sinkInfo, setSinkInfo, job, handleSinkDetails } =
    useContext(WizardContext);
  const { roomInfo } = job;
  console.log(roomInfo);
  const navigate = useNavigate();
  useEffect(() => {
    if (!job.roomInfo || job.sinksDecided) {
      navigate('../..');
    }
  }, []);

  const toggleCheck = value => {
    setSinkInfo({ ...sinkInfo, existing: value });
  };
  function next() {
    console.log(sinkInfo);
    if (sinkInfo?.existing === 'use old') {
      handleSinkDetails();
      navigate('../..');
    } else if (sinkInfo?.existing === 'pick new') {
      navigate('../add-sink');
    } else {
      console.log('unknown selection!');
    }
  }
  return (
    <Wrapper>
      <div>
        <h2 className="fs-0 mb-3 fw-normal">Are you sure?</h2>
        <p>
          We know it often seems like a good idea to try to re-install your old
          sink. Who wants to add to the landfill! But from our experience, the
          old sink is rarely in good condition. Then the project gets delayed at
          the last minute, and there is a {prices.secondTrip} fee if the team
          has to make a second trip. We strongly recommend that you purchase a
          new sink for your new countertop.
        </p>
        <CustomCheckbox
          name="existing"
          type="radio"
          label={`OK, show me a list of sinks that will work with ${roomInfo?.brand}`}
          value="pick new"
          checked={sinkInfo.existing === 'pick new'}
          toggleCheck={toggleCheck}
        />
        <CustomCheckbox
          name="existing"
          type="radio"
          label="I understand, but I’d rather risk it and use my old sink"
          value="use old"
          checked={sinkInfo.existing === 'use old'}
          toggleCheck={toggleCheck}
        />
      </div>
      <Button className="w-100" onClick={next} disabled={!sinkInfo?.existing}>
        Continue
      </Button>
    </Wrapper>
  );
}

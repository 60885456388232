import React, { useContext, useState, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import PropTypes from 'prop-types';
import { Row, Spinner } from 'react-bootstrap';
import Edges from './edge-descriptions.json';

const ConfirmedEdge = ({ roomInfo, imgEdge }) => {
  const { job, edgeDetails, waitForEdge, missingEdgeImage } =
    useContext(WizardContext);

  const edgeDescription = edgeDetails
    ? edgeDetails.edgeDescription
    : roomInfo?.materialType &&
      Edges[roomInfo.materialType] &&
      Edges[roomInfo.materialType][roomInfo.edge];

  const [edgeName, setEdgeName] = useState('');

  useEffect(() => {
    if (roomInfo?.edge) {
      setEdgeName(roomInfo.edge.replace(/(\d+)-(\d+")/, '$1/$2'));
    }
  }, [roomInfo.edge]);

  return (
    roomInfo &&
    (waitForEdge ? (
      <>
        <hr />
        <h5>Edge</h5>
        <div className="spinner-wrapper-home">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </>
    ) : (
      edgeName && (
        <>
          <hr />
          <h5>Edge</h5>
          <p>
            Edge Name: <strong>{edgeName}</strong>
            {job.edgeCall && (
              <span className="fs--1" style={{ fontStyle: 'italic' }}>
                {' '}
                (I will call you soon to verify)
              </span>
            )}
          </p>
          {edgeDescription && <p className="fs--1">{edgeDescription}.</p>}
          {!missingEdgeImage && imgEdge?.src && (
            <Row className="flex-center pt-1 pb-3">
              <img
                // style={{ width: '400px' }}
                alt={edgeName}
                src={imgEdge?.src}
              />
            </Row>
          )}
        </>
      )
    ))
  );
};

ConfirmedEdge.propTypes = {
  roomInfo: PropTypes.object,
  imgEdge: PropTypes.object
};

export default ConfirmedEdge;

import React, { useState, useContext, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Form, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import Wrapper from './components/Wrapper';

const SplashType = () => {
  const { job, selectedRooms, setSelectedRooms, handleSplashes } =
    useContext(WizardContext);
  const { room } = useParams();
  const navigate = useNavigate();
  const [currentSelection, setCurrentSelection] = useState('');
  const [otherNotes, setOtherNotes] = useState('');

  useEffect(() => {
    if (!job.roomInfo || job.splashesConfirmed) {
      navigate('../');
    }
  }, []);

  useEffect(() => {
    if (!selectedRooms || !selectedRooms[room]) return;
    setCurrentSelection(selectedRooms[room].splashType);
    setOtherNotes(selectedRooms[room].otherNotes || '');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [room]);

  // function hasTile() {
  //   return selectedRooms.find(r => r.splashType === 'tile');
  // }

  return selectedRooms && selectedRooms.length > 0 ? (
    <Wrapper>
      <FalconCardHeader
        className="text-center"
        title={`${selectedRooms[room].name} backsplash`}
        light={false}
      />
      <p>
        What type of backsplash are you planning for
        <em>{selectedRooms[room].name}</em>?
      </p>
      <Form className="outdoor-field">
        <>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="splashtype"
              id="full"
              label="Same color as countertop (Full Height from countertop to bottom of upper cabinets)"
              checked={currentSelection === 'full'}
              onChange={() => {
                setCurrentSelection('full');
                selectedRooms[room].splashType = 'full';
                setSelectedRooms(selectedRooms);
              }}
            />
          </Row>
          <Row className="flex-center pt-1 pb-3">
            <img alt="Full Backsplash" src="/splash/fullback.jpg" width="350" />
          </Row>
        </>
        <>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="splashtype"
              id="short"
              label="Same color as countertop (2-10 inches)"
              checked={currentSelection === 'short'}
              onChange={() => {
                setCurrentSelection('short');
                selectedRooms[room].splashType = 'short';
                setSelectedRooms(selectedRooms);
              }}
            />
          </Row>
          <Row className="flex-center pt-1 pb-3">
            <img
              alt="Short Backsplash"
              src="/splash/shortback.jpg"
              width="350"
            />
          </Row>
        </>
        <>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="splashtype"
              id="tile"
              label="Tile"
              checked={currentSelection === 'tile'}
              onChange={() => {
                setCurrentSelection('tile');
                selectedRooms[room].splashType = 'tile';
                setSelectedRooms(selectedRooms);
              }}
            />
          </Row>
          <Row className="flex-center pt-1 pb-3">
            <img alt="Tile Backsplash" src="/splash/tileback.jpg" width="350" />
          </Row>
        </>
        <>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="splashtype"
              id="none"
              label="No Backsplash"
              checked={currentSelection === 'none'}
              onChange={() => {
                setCurrentSelection('none');
                selectedRooms[room].splashType = 'none';
                setSelectedRooms(selectedRooms);
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="splashtype"
              id="notsure"
              label="Not Sure"
              checked={currentSelection === 'notsure'}
              onChange={() => {
                setCurrentSelection('notsure');
                selectedRooms[room].splashType = 'notsure';
                setSelectedRooms(selectedRooms);
              }}
            />
            {currentSelection === 'notsure' && (
              <p className="fs--1 ms-2">
                We will discuss with you when your technician comes to measure.
              </p>
            )}
          </Row>
        </>
        <>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="splashtype"
              id="other"
              label="Other"
              checked={currentSelection === 'other'}
              onChange={() => {
                setCurrentSelection('other');
                selectedRooms[room].splashType = 'other';
                setSelectedRooms(selectedRooms);
              }}
            />
            {currentSelection === 'other' && (
              <>
                <p className="fs--1 ms-2">Please summarize below:</p>
                <Form.Control
                  as="textarea"
                  bssize="sm"
                  id="otherNotes"
                  value={otherNotes}
                  onChange={({ target }) => {
                    setOtherNotes(target.value);
                    selectedRooms[room].otherNotes = target.value;
                    setSelectedRooms(selectedRooms);
                  }}
                  rows={5}
                />
              </>
            )}
          </Row>
        </>
      </Form>
      {/* {chooseBsTypeError && (
        <p className="text-danger pt-2">Please select a backsplash type.</p>
      )} */}
      <Row className="flex-center py-3">
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={
            selectedRooms[room] ? selectedRooms[room].splashType === null : true
          }
          onClick={() => {
            if (selectedRooms.length > parseInt(room) + 1) {
              navigate(`../splashtype/${parseInt(room) + 1}`);
              // } else if (hasTile()) {
              //   navigate('../tileinstall');
            } else {
              handleSplashes(selectedRooms);
              navigate('../');
            }
          }}
        >
          Continue
        </Button>
      </Row>
    </Wrapper>
  ) : null;
};

SplashType.propTypes = {
  roomType: PropTypes.string,
  step: PropTypes.number,
  setStep: PropTypes.func,
  setNext: PropTypes.func
};

export default SplashType;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DisclosureSummary = ({ job }) => {
  const { disclosuresDecided, disclosureDetails, roomInfo } = job;
  const { none, disclosureType } = disclosureDetails || {}; // if accepted is true, then it's OLD disclosures
  const { brand, color, materialType, isDirectional } = roomInfo || {};
  return (
    (job && disclosuresDecided && !none && !disclosureType && (
      <>
        <p className="mb-1">
          <strong>Disclosures</strong>
        </p>
        <p>
          More information about{' '}
          <Link
            to={`/disclosure/${brand}/${encodeURIComponent(
              color
            )}?materialType=${materialType}&isDirectional=${isDirectional}`}
          >
            {brand} {color}
          </Link>
        </p>
      </>
    )) ||
    null
  );
};

DisclosureSummary.propTypes = {
  job: PropTypes.object
};

export default DisclosureSummary;

import React from 'react';
import Video from '../components/Video';
import Video2 from '../components/Video2';
import SliderComponent from '../components/SliderComponent';
import PropTypes from 'prop-types';

function DisclosureLayoutPlain({
  standalone = false,
  title,
  videoId,
  videoFile,
  review,
  sliderList,
  aboutTitle,
  aboutSubTitle = '',
  aboutList = [],
  aboutConclusion = '',
  warranty
}) {
  return (
    <>
      <div>
        <h2 className="fw-lighter textStyleHead">{title}</h2>
        {!standalone && (
          <p className="textStyle fw-1 mb-0">
            {review}, and <b>acknowledge your acceptance</b> to continue.
          </p>
        )}
      </div>
      {videoFile ? <Video2 file={videoFile} /> : <Video id={videoId} />}
      <div className="my-5">
        <SliderComponent list={sliderList} style={'imgSize'} />
      </div>
      <div>
        <h2 className="textStyleHead fs-2">{aboutTitle}</h2>
        <h4 className="fs-0 fw-normal">{aboutSubTitle}</h4>
        <div className="text-dark my-3">
          <ol>
            {aboutList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ol>
        </div>
        <div>{aboutConclusion}</div>
        <div>
          <p>
            {warranty?.description} <b>{warranty?.warning}</b>
          </p>
        </div>
      </div>
    </>
  );
}
DisclosureLayoutPlain.propTypes = {
  standalone: PropTypes.bool,
  title: PropTypes.string,
  videoId: PropTypes.string,
  videoFile: PropTypes.string,
  review: PropTypes.string,
  sliderList: PropTypes.array,
  aboutList: PropTypes.array,
  aboutTitle: PropTypes.string,
  aboutSubTitle: PropTypes.string,
  aboutConclusion: PropTypes.string,
  warranty: PropTypes.object
};

export default DisclosureLayoutPlain;

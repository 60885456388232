import React, { useContext, useEffect } from 'react';
import Wrapper from '../components/Wrapper';
import { Button } from 'react-bootstrap';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';

export default function Inspect() {
  const { job } = useContext(WizardContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!job.roomInfo || job.sinkMoraName) {
      navigate('../../');
    }
  }, []);
  function handleClick() {
    navigate('../type');
  }
  return (
    <Wrapper>
      <div>
        <h2 className="fs-0 fw-normal mb-3">Inspect Your New Sink</h2>
        <p>
          When your new sink arrives, be sure to unpack it and{' '}
          <strong>inspect it thoroughly</strong> for any visible defects or
          damage. Look for cracks, scratches, dents, or chips on the surface.
        </p>
        <p>
          Also, <strong>measure the sink</strong>, including its width, depth,
          and height. Compare these measurements to the cabinet sink box
          dimensions to confirm a proper fit in the cabinet's interior space.
        </p>
      </div>
      <Button className="w-100" onClick={handleClick}>
        Continue
      </Button>
    </Wrapper>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { prices } from 'prices';
import { Alert } from 'react-bootstrap';

const TearoutAndPlumbingSummary = ({ job }) => {
  const { tearoutInfo, plumbingInfo } = job;
  // console.log({ tearoutInfo, plumbingInfo });
  return tearoutInfo?.tearout === 'unknown' &&
    plumbingInfo?.disconnect === 'unknown' &&
    plumbingInfo.reconnect === 'unknown' ? null : (
    <>
      {tearoutInfo && (
        <>
          <hr />
          <h5>Tearout</h5>
          {tearoutInfo?.status === 'no' && (
            <p>
              You will be handling tearout, disposal, and plumbing disconnect
              yourself.{' '}
              <em>
                This must be completed before we arrive to install your new
                countertops.
              </em>
            </p>
          )}
          {tearoutInfo?.tearout === 'yes' && (
            <p>
              We will be handling tearout and disposal of your old countertops
              for you.
            </p>
          )}
          {tearoutInfo?.status === 'yes' && (
            <p>
              We will be handling tearout and disposal of your old countertops
              for you ({prices.tearoutLaminate}-{prices.tearoutOther}, depending
              on material).
            </p>
          )}
          {tearoutInfo?.status === 'call' && (
            <p>
              We will discuss removing and disposing of your old countertops
              with you when the technician comes to measure (
              {prices.tearoutLaminate}-{prices.tearoutOther}, depending on
              material).
            </p>
          )}
        </>
      )}
      {plumbingInfo && (
        <>
          <hr />
          <h5>Plumbing</h5>
        </>
      )}
      {(plumbingInfo?.disconnect === 'yes' ||
        plumbingInfo?.disconnect === 'already') && (
        <p>
          We will be disconnecting your plumbing
          {plumbingInfo?.disconnect === 'yes' && (
            <> ({prices.plumbingDisconnect})</>
          )}
          .
        </p>
      )}
      {plumbingInfo?.disconnect === 'no' && (
        <p>You will be disconnecting your plumbing before tearout.</p>
      )}
      {plumbingInfo?.reconnect === 'yes' && job.reconnectZip && (
        <>
          <p>
            We will be reconnecting your existing plumbing (
            {prices.plumbingReconnect} for basic package).
          </p>
          <Alert variant="secondary">
            Reminder: we can't work with hardwired appliances, and we can't
            install new appliances.
          </Alert>
        </>
      )}
      {plumbingInfo?.reconnect === 'call' && (
        <p>
          We will discuss reconnecting your plumbing when the technician comes
          to measure ({prices.plumbingReconnect} for basic package).
        </p>
      )}
      {plumbingInfo?.reconnect === 'yes' && !job.reconnectZip && (
        <p>
          We will discuss reconnecting your plumbing when the technician comes
          to measure.
        </p>
      )}
      {(plumbingInfo?.reconnect === 'no' ||
        plumbingInfo?.reconnect === 'n/a') && (
        <p>
          You will be reconnecting your plumbing after we install your
          countertops.
        </p>
      )}
    </>
  );
};

TearoutAndPlumbingSummary.propTypes = {
  job: PropTypes.object
};

export default TearoutAndPlumbingSummary;

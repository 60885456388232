import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import Success from './Success';
import AppContext, { WizardContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import {
  faLocationArrow,
  faCalendar,
  faCheck,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';
import Confirm from './Confirm';
import Acknowledge from './Acknowledge';
import Schedule from './Schedule';

const WizardLayout = () => {
  const { isRTL } = useContext(AppContext);
  const { startingRooms, job, user, setUser, step, setStep } =
    useContext(WizardContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: faLocationArrow,
      label: 'Confirm'
    },
    {
      icon: faCheck,
      label: 'Acknowledge'
    },
    {
      icon: faCalendar,
      label: 'Schedule'
    },
    {
      icon: faThumbsUp,
      label: 'Done'
    }
  ];

  const onSubmitData = data => {
    setUser({ ...user, ...data });
    setStep(step + 1);
  };
  // const onError = () => {
  //   setStep(step + 1);
  // };
  useEffect(() => {
    console.log(user, 'test');
  }, [step, user]);
  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData);
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData)}
        className="theme-wizard mb-5"
      >
        <Card.Header className={classNames('bg-light')}>
          <Nav className="justify-content-center">
            {navItems.map((item, index) => (
              <NavItem
                key={item.label}
                index={index + 1}
                step={step}
                handleNavs={handleNavs}
                icon={item.icon}
                label={item.label}
              />
            ))}
          </Nav>
        </Card.Header>
        {/* {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />} */}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <Confirm
              register={register}
              errors={errors}
              startingRooms={startingRooms}
              job={job}
            />
          )}
          {step === 2 && <Acknowledge register={register} errors={errors} />}
          {step === 3 && <Schedule register={register} errors={errors} />}
          {step === 4 && <Success reset={reset} />}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.any,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;

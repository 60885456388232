import React, { useContext, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { prices } from 'prices';

const TearoutConfirmNo = () => {
  const navigate = useNavigate();

  const { tearoutInfo, setTearoutInfo, job, handleTearoutDetails } =
    useContext(WizardContext);

  useEffect(() => {
    if (!job.roomInfo || job.tearoutConfirmed) {
      navigate('../..');
    }
  }, []);

  const toggleCheck = value => {
    setTearoutInfo({ ...tearoutInfo, status: value });
  };

  const next = () => {
    handleTearoutDetails();
    navigate('../..');
  };

  return (
    <Wrapper>
      <p>
        You have currently selected to remove and dispose of your old
        countertops yourself.
      </p>
      <p>
        Would you prefer to have Precision remove your old countertops and
        dispose of them properly instead? That way you'll have your old, working
        kitchen right up until the day of your install. We charge{' '}
        {prices.tearoutLaminate} to remove and dispose of laminate countertops
        (like Formica) and {prices.tearoutOther} for most other surfaces (like
        tile or stone).
      </p>
      <CustomCheckbox
        name="tearout"
        type="radio"
        label="Yes, please add removal and disposal of my old countertops to my order."
        value="yes"
        checked={tearoutInfo?.status === 'yes'}
        toggleCheck={toggleCheck}
      />
      <CustomCheckbox
        name="tearout"
        type="radio"
        label="No, I made other arrangements to remove & dispose of my old countertops AND will have it complete before the Precision team arrives for install."
        value="no"
        checked={tearoutInfo?.status === 'no'}
        toggleCheck={toggleCheck}
      />
      <CustomCheckbox
        name="tearout"
        type="radio"
        label="I'm not sure"
        value="call"
        checked={tearoutInfo?.status === 'call'}
        toggleCheck={toggleCheck}
      />
      {tearoutInfo?.status === 'call' && (
        <p>We will discuss with you when your technician comes to measure.</p>
      )}
      <Button
        className="w-100"
        variant="primary"
        disabled={!tearoutInfo?.status}
        onClick={next}
      >
        Continue
      </Button>
    </Wrapper>
  );
};

export default TearoutConfirmNo;

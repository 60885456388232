import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Wrapper from '../components/Wrapper';

export default function Undermount() {
  const [value] = useState('SinkValue');
  return (
    <Wrapper>
      <p>
        Terrific. I see that you’ve purchased a [{value}] sink. Does that sound
        right?
      </p>
      <img src="" alt="Confirming the sink" />
      <div className="d-flex gap-2 mt-4">
        <Button className="w-100">No</Button>
        <Button className="w-100">Yes</Button>
      </div>
    </Wrapper>
  );
}

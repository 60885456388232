import React, { useEffect } from 'react';
import { Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import WrapperPlain from './components/WrapperPlain';
import PropTypes from 'prop-types';
import { prices } from 'prices';

export default function DigitalRenderingExplanation({ wrapped = false }) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const nodes = (
    <div>
      <h4>Digital Rendering Service ({prices.digitalRendering})</h4>
      <p>
        A digital rendering is a personalized photograph of your stone
        countertops, showing the pattern and seam locations.{' '}
      </p>
      <p style={{ fontStyle: 'italic' }}>
        The pictures below are only an example - your digital rendering will be
        specific to your home.
      </p>
      <Row className="flex-center pt-1 pb-3">
        <img
          alt="Digital Rendering Image"
          src="/digital-rendering/digital-layout.jpg"
          width="350"
        />
      </Row>
      <p>
        This rendering will be designed by our engineering department using
        specialized tools and software to illustrate how your countertops will
        look, by overlaying a laser template computer file on top of photographs
        of your actual stone slabs.
      </p>
      <Row className="flex-center pt-1 pb-3">
        <img
          alt="Screen showing digital layout software"
          src="/digital-rendering/computer-programming.jpg"
          width="350"
        />
      </Row>
      <p>
        This gives you the opportunity to view and approve your specific layout
        prior to fabrication.
      </p>
      <p>Then we send the layout of your kitchen for your approval.</p>
      <Row className="flex-center pt-1 pb-3">
        <img
          alt="Layout on slab"
          src="/digital-rendering/slab-pink.jpg"
          width="350"
        />
      </Row>
      <Row className="flex-center pt-1 pb-3">
        <img
          alt="Installed countertops"
          src="/digital-rendering/installed.jpg"
          width="350"
        />
      </Row>
    </div>
  );
  return (
    <>
      {wrapped ? (
        <WrapperPlain>
          {nodes}
          <Button
            className="w-100 my-3"
            variant="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </WrapperPlain>
      ) : (
        nodes
      )}
    </>
  );
}

DigitalRenderingExplanation.propTypes = {
  wrapped: PropTypes.bool
};

import React from 'react';

const NotFound = () => {
  return (
    <>
      <h4>Not Found</h4>
      <p>
        So sorry, but we couldn't find information on your countertops at this
        link.
      </p>
      <p>
        For more information, please call{' '}
        <a href="tel:1-800-548-4445">800.548.4445</a>.
      </p>
    </>
  );
};

export default NotFound;

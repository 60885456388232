import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import DisclosureSummary from '../disclosure/DisclosureSummary';
import { Link } from 'react-router-dom';
import { prices } from 'prices';

const ConfirmedColor = ({ roomInfo, imgColor, imgColorSlab, job }) => {
  return (
    roomInfo?.brand &&
    roomInfo?.color && (
      <>
        <hr />
        <h5>Material</h5>
        <p>
          Color:{' '}
          <strong>
            {roomInfo?.brand} {roomInfo?.color}
          </strong>{' '}
        </p>
        {imgColor && (
          <>
            <Row className="flex-center pt-1">Close-up</Row>
            <Row className="flex-center pt-1 pb-2">
              <img
                // style={{ width: '400px' }}
                alt={`${roomInfo?.brand} ${roomInfo?.color}`}
                src={imgColor?.src}
              />
            </Row>
          </>
        )}
        {imgColorSlab && (
          <>
            <Row className="flex-center pt-2">Full Slab</Row>
            <Row className="flex-center pt-1 pb-2">
              <img
                // style={{ width: '400px' }}
                alt={`${roomInfo?.brand} ${roomInfo?.color} Slab`}
                src={imgColorSlab?.src}
              />
            </Row>
          </>
        )}
        {(imgColor || imgColorSlab) && (
          <Row>
            <p
              className="fs--1"
              style={{ fontStyle: 'italic', textAlign: 'center' }}
            >
              Pictures are representative ...
              <br />
              your specific slab(s) will vary
            </p>
          </Row>
        )}
        <DisclosureSummary job={job} />
        <p className="mb-1">
          <strong>Digital Rendering</strong>
        </p>
        {job?.rendering?.decision === 'yes' && (
          <p>
            You added <Link to={'/rendering'}>Digital Rendering</Link> (
            {prices.digitalRendering})
          </p>
        )}
        {job?.rendering?.decision === 'no' && (
          <>
            <p>
              You declined <Link to={'/rendering'}>digital rendering</Link> (
              {prices.digitalRendering}).
            </p>
          </>
        )}
        {job?.rendering?.decision === 'notsure' && (
          <p>
            We will discuss <Link to={'/rendering'}>digital rendering</Link> (
            {prices.digitalRendering}) with you when your technician comes to
            measure.
          </p>
        )}
      </>
    )
  );
};

ConfirmedColor.propTypes = {
  roomInfo: PropTypes.object,
  imgColor: PropTypes.object,
  imgColorSlab: PropTypes.object,
  job: PropTypes.object
};

export default ConfirmedColor;

import React, { useState, useEffect, useContext } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row } from 'react-bootstrap';
import Wrapper from './components/Wrapper';

function Problem() {
  const [problemText, setProblemText] = useState('');
  const { job, handleProblemEntered } = useContext(WizardContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (job?.problem) {
      navigate('../');
    }
  }, [job]);

  return (
    <Wrapper>
      <div>
        <p>
          Sorry for the confusion. I'll give you a call to straighten things out
          and confirm the rest of your details.
        </p>
        <p>
          If there's anything you'd like to share before I call, you can enter
          it below.
        </p>
        <Form>
          <Form.Group>
            <Form.Control
              as="textarea"
              bssize="sm"
              id="experienceDescription"
              value={problemText}
              onChange={({ target }) => setProblemText(target.value)}
              rows={5}
            />
          </Form.Group>
        </Form>
        <Row className="flex-center py-3">
          <Button
            color="primary"
            className="mx-2 my-1 save-comment-btn"
            type="submit"
            onClick={() => handleProblemEntered(problemText)}
          >
            Send Comment and Finish
          </Button>
        </Row>
      </div>
    </Wrapper>
  );
}

export default Problem;

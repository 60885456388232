import React, { useEffect, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { useNavigate } from 'react-router-dom';
import { WizardContext } from 'context/Context';
import { prices } from 'prices';

const PlumbingDisconnect = () => {
  const navigate = useNavigate();
  const [disconnect, setDisconnect] = useState(null);
  // const [sure, setSure] = useState(null);

  const { plumbingInfo, setPlumbingInfo, job, handlePlumbingDetails } =
    useContext(WizardContext);

  useEffect(() => {
    if (
      !job?.roomInfo ||
      job?.plumbingConfirmed ||
      (!job?.tearoutConfirmed && job?.tearoutInfo.tearout !== 'yes') ||
      job?.tearoutInfo?.status === 'no'
    ) {
      navigate('../..');
    }
  }, []);

  const toggleCheck1 = value => {
    setDisconnect(value);
  };

  // const toggleCheck2 = value => {
  //   setSure(value);
  // };

  const next = () => {
    const decision = disconnect; // sure || disconnect;
    setPlumbingInfo({
      ...plumbingInfo,
      disconnect: decision,
      reconnect: decision === 'yes' ? 'n/a' : undefined
    });
    if (decision && job?.reconnectZip) {
      navigate('../reconnect');
      return;
    }
    handlePlumbingDetails();
    navigate('../..');
  };

  return (
    <Wrapper>
      <p>
        {job?.tearoutInfo?.status !== 'yes' &&
          // this will only appear if they didn't just add tearout
          'I see we will be removing and disposing of your old countertops. '}
        We can also disconnect your plumbing for a fee of{' '}
        {prices.plumbingDisconnect}, which will allow you to keep your fully
        functioning countertop and sink all the way up to our arrival on the day
        of installation.
      </p>
      <CustomCheckbox
        name="plumbing"
        type="radio"
        label="Yes, I would like Precision to disconnect plumbing."
        value="yes"
        checked={disconnect === 'yes'}
        toggleCheck={toggleCheck1}
      />
      <CustomCheckbox
        name="plumbing"
        type="radio"
        label="No, I’ve arranged for someone else to disconnect plumbing before Precision arrives to remove my old countertops."
        value="no"
        checked={disconnect === 'no'}
        toggleCheck={toggleCheck1}
      />
      {disconnect === 'no' &&
        (job?.tearoutInfo?.status === 'yes' ||
          job?.tearoutInfo?.tearout === 'yes') && (
          <>
            <hr />
            <p className="fs--1" style={{ fontStyle: 'italic' }}>
              Please have your sink completely disconnected from all of the
              plumbing and from countertops prior to our arrival on the day of
              installation.
            </p>
            {/* <CustomCheckbox
              name="plumbing2"
              type="radio"
              label="Yes, please add plumbing disconnect"
              value="yes"
              checked={sure === 'yes'}
              toggleCheck={toggleCheck2}
            />
            <CustomCheckbox
              name="plumbing2"
              type="radio"
              label="No, I will make sure plumbing is disconnected before Precision's arrival for installation."
              value="no"
              checked={sure === 'no'}
              toggleCheck={toggleCheck2}
            /> */}
          </>
        )}
      <Button
        className="w-100"
        variant="primary"
        disabled={!disconnect} // || (disconnect === 'no' && !sure)}
        onClick={next}
      >
        Continue
      </Button>
    </Wrapper>
  );
};

export default PlumbingDisconnect;

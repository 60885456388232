import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import TearoutConfirmNo from './TearoutConfirmNo';
import TearoutUnknown from './TearoutUnknown';
import { WizardContext } from 'context/Context';

export default function TearoutRoutes() {
  const { job } = useContext(WizardContext);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            replace
            to={
              job?.tearoutInfo?.tearout === 'no' ? './confirmself' : './unknown'
            }
          />
        }
      />
      <Route path="/unknown" element={<TearoutUnknown />} />
      <Route path="/confirmself" element={<TearoutConfirmNo />} />
    </Routes>
  );
}

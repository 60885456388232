import React from 'react';
import PropTypes from 'prop-types';
function Video({ id }) {
  return (
    <div className="video-responsive my-4">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${id}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}
Video.propTypes = {
  id: PropTypes.string
};
export default Video;

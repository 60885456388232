import React, { useContext, useEffect } from 'react';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { WizardContext } from 'context/Context';
import { Button, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function ConfirmModel() {
  const { sinkInfo, setSinkInfo, job, sinkLibDetails, handleSinkDetails } =
    useContext(WizardContext);
  const { roomInfo } = job;
  console.log('roomInfo', roomInfo);
  const navigate = useNavigate();

  useEffect(() => {
    if (!job.roomInfo || job.sinksDecided) {
      navigate('../../');
    }
  }, []);

  const toggleCheck = value => {
    setSinkInfo({ ...sinkInfo, confirmExisting: value });
  };

  const next = () => {
    console.log('sinkInfo', sinkInfo, 'job', job);
    if (
      sinkInfo?.confirmExisting === 'yes' &&
      sinkLibDetails?.sinkType === 'Undermount' &&
      (job?.roomInfo?.materialType === 'Stone' ||
        job?.roomInfo?.materialType === 'Ultracompact') &&
      !job?.chipMinimizerAlready
    ) {
      navigate('../../chipminizer/undermount-stone');
      return;
    }
    handleSinkDetails();
    navigate('../..');
  };

  return (
    <Wrapper>
      <div>
        <h3>Confirm sink selection</h3>
        {sinkLibDetails ? (
          <>
            <p>
              Brand: <strong>{sinkLibDetails?.brandName}</strong>
            </p>
            <p>
              Model: <strong>{sinkLibDetails?.customerName}</strong>
            </p>
            {sinkLibDetails?.mainImageURL && (
              <>
                <Row className="flex-center pt-1">
                  <img
                    // style={{ width: '400px' }}
                    alt={`${sinkLibDetails?.moraName}`}
                    src={sinkLibDetails?.mainImageURL}
                  />
                </Row>
              </>
            )}
          </>
        ) : (
          <>
            <p>{roomInfo?.sinkMoraName}</p>
          </>
        )}
        <h2 className="fs-0 mb-3 fw-normal">Is this your sink?</h2>
        <CustomCheckbox
          name="confirmExisting"
          type="radio"
          label="Yes"
          value="yes"
          checked={sinkInfo?.confirmExisting === 'yes'}
          toggleCheck={toggleCheck}
        />
        <CustomCheckbox
          name="confirmExisting"
          type="radio"
          label={`No, please call me to select a new sink`}
          value="no"
          checked={sinkInfo?.confirmExisting === 'no'}
          toggleCheck={toggleCheck}
        />
      </div>
      <Button
        className="w-100"
        onClick={next}
        disabled={!sinkInfo?.confirmExisting}
      >
        Continue
      </Button>
    </Wrapper>
  );
}

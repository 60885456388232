import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Branding from './Branding';
import is from 'is_js';
import Section from 'components/common/Section';

function WrapperPlain({ children, branding = false }) {
  if (is.mobile())
    return (
      <div className="p-3">
        {branding && <Branding />}
        {children}
      </div>
    );
  else
    return (
      <Section className="py-0" style={{ minHeight: 'calc(100vh - 130px)' }}>
        <Row className="flex-center p-0">
          <Col sm={10} lg={7} className="col-xxl-5">
            <Card className="my-3">
              {branding && <Branding />}
              <Card.Body>{children}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Section>
    );
}

WrapperPlain.propTypes = {
  children: PropTypes.node,
  branding: PropTypes.bool
};

export default WrapperPlain;

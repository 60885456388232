import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WizardContext } from 'context/Context';
import ContactInfo from './components/ContactInfo';
import Wrapper from './components/Wrapper';
import ButtonGroup from './components/ButtonGroup';
import Confirmed from './components/Confirmed';
import ConfirmedColor from './components/ConfirmedColor';
import ConfirmedEdge from './components/ConfirmedEdge';
import ConfirmedRooms from './components/ConfirmedRooms';
import Intro from './components/Intro';
// import Upgrades from './components/Upgrades';
import SinkSummary from './components/SinkSummary';
import CooktopSummary from './components/CooktopSummary';
import CabinetSummary from './components/CabinetSummary';
import TearoutAndPlumbingSummary from './components/TearoutAndPlumbingSummary';

export function showCooktop(job, selectedRooms) {
  const rooms = job?.rooms || selectedRooms;
  if (!rooms) {
    return false;
  }
  const room = rooms.find(r => !r.name.match(/(bath|laundry)/i));
  return !!room;
}

function timedOut(voc) {
  if (!voc?.textSent) return false;
  try {
    const now = new Date();
    const diff = now.getTime() - new Date(voc.textSent).getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    return hours > 48;
  } catch (err) {
    return false;
  }
}

export function onVOC(job) {
  return job && job.voc && !job.voc.formComplete && !timedOut(job.voc);
}

const WizardHome = () => {
  const {
    job,
    handleConfirm,
    imgColor,
    imgColorSlab,
    imgEdge,
    selectedRooms,
    plumbingInfo,
    cooktopInfo,
    setCooktopInfo
  } = useContext(WizardContext);
  const navigate = useNavigate();

  useEffect(() => {
    // start from the end and work backwards
    if (onVOC(job)) {
      navigate('../qa');
    } else if (job.install) {
      if (job?.install?.status === 'Pending Approval') {
        navigate('./install');
      }
    } else if (job.template) {
      if (job?.template?.status === 'Pending Approval') {
        navigate('./template');
      }
    } else if (job.confirmed && (job.verifyOrder || job.roomInfo)) {
      if (job.problem) {
        return;
      }
      if (!job.colorConfirmed) {
        navigate('./color');
      } else if (!job.disclosuresDecided) {
        const { brand, color, materialType, isDirectional } = job.roomInfo;
        navigate(
          `./disclosure/${brand}/${encodeURIComponent(
            color
          )}?materialType=${materialType}&isDirectional=${isDirectional}`
        );
      } else if (job?.disclosures?.length > 0 && !job.disclosuresAccepted) {
        navigate(`./disclosure/${job.disclosures[0].toLowerCase()}`);
      } else if (job.offerRendering && !job.renderingDecided) {
        navigate('./rendering');
      } else if (!job.edgeConfirmed) {
        navigate('./edge');
      } else if (!job.roomsConfirmed) {
        navigate('./rooms');
      } else if (!job.splashesConfirmed) {
        navigate('./splashtype/0');
      } else if (!job.cooktopDecided) {
        if (showCooktop(job, selectedRooms)) {
          navigate('./cooktop');
        } else {
          // go straight to appliances confirmation
          setCooktopInfo({ ...cooktopInfo, exists: 'no' });
          navigate('./appliances');
        }
      } else if (!job.cabinetsConfirmed) {
        navigate('./cabinets');
      } else if (
        !job.tearoutConfirmed &&
        job?.tearoutInfo?.tearout !== 'yes' &&
        job?.cabinetInfo?.type !== 'new'
      ) {
        navigate('./tearout');
      } else if (
        !job.plumbingConfirmed &&
        !plumbingInfo?.disconnect &&
        job?.plumbingInfo?.disconnect !== 'yes' &&
        (job?.tearoutInfo?.status === 'yes' ||
          job?.tearoutInfo?.tearout === 'yes')
      ) {
        navigate('./plumbing/disconnect');
      } else if (
        !job.plumbingConfirmed &&
        !plumbingInfo?.reconnect &&
        job?.plumbingInfo?.reconnect !== 'yes' &&
        job.reconnectZip
      ) {
        navigate('./plumbing/reconnect');
      } else if (!job.sinksDecided && !job.roomInfo.sinkMoraName) {
        navigate('./sink/0/unknown');
      } else if (!job.sinksDecided && !job.sinksConfirmed) {
        navigate('./sink/0/confirm');
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [job]);

  const additionalPurchases =
    job?.rendering?.decision === 'yes' ||
    job?.sinkInfo?.chipMinimizer === 'yes' ||
    job?.tileInstall === 'yes' ||
    job?.tearoutInfo?.status === 'yes' ||
    job?.plumbingInfo?.disconnect === 'yes' ||
    job?.plumbingInfo?.reconnect === 'yes' ||
    job?.sinkInfo?.purchased === 'new';

  return (
    <Wrapper branding={true}>
      {job.confirmed ? (
        <>
          <Confirmed />
          {/* {job?.walkthroughLink && (
            <>
              <h5>
                Your <a href={job.walkthroughLink}>Final Countertop Details</a>{' '}
                from your measure
              </h5>
            </>
          )} */}
          {!job?.walkthroughLink &&
            !job?.install &&
            job?.template?.status !== 'Templated' &&
            job?.template?.status !== 'In Progress' && (
              <>
                {job?.install?.status !== 'Installed' && !job?.voc && (
                  <>
                    <hr />
                    <ContactInfo
                      address={job.address || {}}
                      textOK={job.textOK}
                    />
                  </>
                )}
                {job.roomInfo && job.verifyOrder && !job.problem && (
                  <>
                    {selectedRooms?.length > 0 && (
                      <>
                        <hr />
                        <h3>Pre-Measure Summary</h3>
                        {additionalPurchases && (
                          <>
                            <p>
                              <em>
                                NOTE: Payment for all additional purchases will
                                be handled when the technician comes to measure.
                              </em>
                            </p>
                          </>
                        )}
                        <h4>{selectedRooms[0].name}</h4>
                      </>
                    )}
                    {/* <ConfirmedRooms rooms={selectedRooms} /> */}
                    <ConfirmedColor
                      roomInfo={job.roomInfo}
                      imgColor={imgColor}
                      imgColorSlab={imgColorSlab}
                      job={job}
                    />
                    <ConfirmedEdge roomInfo={job.roomInfo} imgEdge={imgEdge} />
                    <SinkSummary job={job} />
                    <CooktopSummary job={job} />
                    <CabinetSummary job={job} />
                    <TearoutAndPlumbingSummary job={job} />
                    <ConfirmedRooms rooms={selectedRooms} />
                  </>
                )}
                {/* <Upgrades job={job} /> */}
                {job.lastUpdated && (
                  <>
                    <hr />
                    <p style={{ fontStyle: 'italic' }}>
                      {job.splashesConfirmedDetails &&
                      job?.orderVerifiedText?.phone ? (
                        <>
                          The order confirmation text was sent to{' '}
                          {job.orderVerifiedText?.phone} and completed on{' '}
                          {job.splashesConfirmedDetails?.date} at{' '}
                          {job.splashesConfirmedDetails?.time} - before the
                          Precision technician visits the job site to finalize
                          the measurements and details.
                        </>
                      ) : (
                        <>Information confirmed as of {job.lastUpdated}</>
                      )}
                    </p>
                  </>
                )}
                {/* <hr />
                <h5>Job Site Summary</h5> */}
              </>
            )}
        </>
      ) : (
        <>
          <Intro />
          <ContactInfo address={job.address || {}} textOK={job.textOK} />
          <ButtonGroup
            primaryFunc={() => {
              handleConfirm(job.address);
            }}
            primaryText="This is Correct"
            secondaryFunc={() => navigate('edit')}
            secondaryText="Make Changes"
          />
        </>
      )}
    </Wrapper>
  );
};

export default WizardHome;

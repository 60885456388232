import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export default function Schedule({ register }) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    register('date', { value: selectedDate });
    console.log(selectedDate, selectedDate.toDateString().split(' '));
  }, [selectedDate]);
  return (
    <div>
      <p>Please choose a date for your template:</p>
      <div>
        <DatePicker
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          inline
        />
      </div>
      <p>
        You have chosen{' '}
        {selectedDate.toDateString().split(' ')[0] +
          ', ' +
          selectedDate.toDateString().split(' ')[1] +
          ' ' +
          selectedDate.getDate()}
        . I will call or text you before this date to confirm a time window for
        your template.
      </p>
    </div>
  );
}
Schedule.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

import React from 'react';
import { Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ButtonGroup = ({
  primaryFunc,
  primaryText,
  secondaryFunc = null,
  secondaryText = 'Make Changes'
}) => {
  return (
    <Row className="flex-center py-3">
      {secondaryFunc && (
        <Button
          variant="outline-primary"
          color="primary"
          className="mx-2 my-1 btn-width"
          type="submit"
          onClick={secondaryFunc}
        >
          {secondaryText}
        </Button>
      )}
      <Button
        color="primary"
        className="mx-2 my-1 btn-width"
        type="submit"
        onClick={primaryFunc}
      >
        {primaryText}
      </Button>
    </Row>
  );
};

ButtonGroup.propTypes = {
  primaryFunc: PropTypes.func,
  primaryText: PropTypes.string,
  secondaryFunc: PropTypes.func,
  secondaryText: PropTypes.string
};

export default ButtonGroup;

import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Wrapper from '../components/Wrapper';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';

// THIS SCREEN IS DEPRECATED - SHOULD NOT SEE IT!

export default function TopMount() {
  const { job } = useContext(WizardContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!job.roomInfo || job.sinkMoraName) {
      navigate('../../');
    }
  }, []);
  function handleClick() {
    navigate('../../');
  }
  return (
    <Wrapper>
      <p>
        Great. And just to let you know, our installers aren’t allowed to caulk
        the sink once it’s in place, so make sure the person doing the plumbing
        takes care of that step.
      </p>
      <div className="d-flex gap-2 mt-4">
        <Button className="w-100" onClick={handleClick}>
          Continue
        </Button>
      </div>
    </Wrapper>
  );
}

import React, { Fragment, useContext } from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { ConfigContext, WizardContext } from 'context/Context';

const GuidTest = () => {
  const { fabricator } = useContext(ConfigContext);
  const { job } = useContext(WizardContext);

  return (
    <Fragment>
      <div className="container">
        <div className="card-deck pt-3">
          <Card className="mb-3">
            <FalconCardHeader
              title={`Countertop Wizard ${
                fabricator ? `| ${fabricator.name}` : ''
              }`}
              light={false}
            ></FalconCardHeader>
            <Card.Body>
              <p>This is a test page for debugging purposes.</p>
              <p>Fabricator: {fabricator && fabricator.name}</p>
              <p>Address Contact: {job.address && job.address.contactName}</p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default GuidTest;

import React, { useContext, useEffect } from 'react';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { Button } from 'react-bootstrap';
import { WizardContext } from 'context/Context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const topSinkCodes = ['WEL 5050-3219', 'WEL 2318', 'WEL 3219']; // reverse order of importance!
const regexCodes = new RegExp(topSinkCodes.join('|'));
const regexMap = topSinkCodes.map(s => new RegExp(s));

function sortScore(sink) {
  const topSink = regexCodes.test(sink.customerName) ? 100 : 0;
  const specificTopSink =
    topSink && regexMap.findIndex(r => r.test(sink.customerName));
  const bestSeller = sink.bestSeller ? 10 : 0;
  return topSink + specificTopSink + bestSeller;
}

export default function AddSink() {
  // const [topSinks, setTopSinks] = useState([]);
  const { sinkInfo, setSinkInfo, job, sinkLibList, handleSinkDetails } =
    useContext(WizardContext);
  const navigate = useNavigate();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const sinkSelector = !!searchParams.get('sinkSelector');
  const sinkType = searchParams.get('type');

  const { room } = useParams();

  const kitchenOnly =
    job?.rooms &&
    job.rooms.length === 1 &&
    job.rooms[room] &&
    job.rooms[room].name &&
    job.rooms[room].name.match(/kitchen/i);

  const bathOnly =
    job?.rooms &&
    job.rooms.length === 1 &&
    job.rooms[room] &&
    job.rooms[room].name &&
    job.rooms[room].name.match(/bathroom/i);

  function sinkNotPurchased() {
    return job?.sinkInfo?.purchased === 'not yet';
  }

  useEffect(() => {
    if (
      (!sinkSelector && !job.roomInfo) ||
      (job.sinksDecided && !sinkNotPurchased()) ||
      (sinkSelector && !['Undermount', 'Integral'].includes(sinkType))
    ) {
      navigate('../..');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // // get the actual sinks containing the topSinkCodes
      // const sinks = sinkLibList.filter(s => regexCodes.test(s.customerName));
      // // sort sinks in the same order as topSinkCodes
      // const sortedSinks = sinks.sort((a, b) => {
      //   const indexA = topSinkCodes.indexOf(a.customerName);
      //   const indexB = topSinkCodes.indexOf(b.customerName);

      //   if (indexA === -1 && indexB === -1) return 0; // neither is in topSinkCodes
      //   if (indexA === -1) return 1; // only b is in topSinkCodes
      //   if (indexB === -1) return -1; // only a is in topSinkCodes

      //   return indexA - indexB; // both are in topSinkCodes
      // });
      // setTopSinks(sortedSinks);
    }
  }, []);
  const toggleCheck = value => {
    console.log(value);
    const sink = sinkLibList.find(i => i.moraInitialA === value);
    console.log(sink);
    setSinkInfo({
      ...sinkInfo,
      purchased: value === 'none' ? 'not yet' : 'new',
      sinkToPurchase: value,
      sinkName: sink ? `${sink.brandName} ${sink.customerName}` : undefined,
      sinkType: sink ? sink.sinkType : undefined
    });
  };
  function next() {
    if (
      (job?.roomInfo?.materialType === 'Stone' ||
        job?.roomInfo?.materialType === 'Ultracompact') &&
      sinkInfo?.sinkType === 'Undermount' &&
      !job?.chipMinimizerAlready
    ) {
      navigate('../../chipminizer/undermount-stone');
      return;
    }
    handleSinkDetails();
    navigate('../..');
  }

  return (
    <Wrapper>
      <h5 className={`${sinkSelector && 'mb-3'}`}>
        Choose your new sink
        {sinkSelector && (
          <Link to={'../..'}>
            <FontAwesomeIcon icon="home" className="float-end fs-3" />
          </Link>
        )}
      </h5>
      {!sinkSelector && (
        <>
          <p>
            We make it super easy to ensure that you have the right sink for
            your project - and we bring it with us to install, so you don’t have
            to worry about shipping delays or damage. Here are the sinks we
            carry for{' '}
            {job?.roomInfo?.materialType &&
              job?.roomInfo?.materialType.toLowerCase()}{' '}
            countertops.
          </p>
          <p>
            If you would like to order your sink through us so we can take care
            of the details, just choose one of the sinks below. We’ll deal with
            payment later, but I’ll make sure we have the sink all ready for
            install.
          </p>
        </>
      )}
      {sinkSelector && (
        <>
          <p>
            Choose one of the {sinkType} sinks below, and we'll handle payment
            later. We'll also make sure we have the sink ready for install.
          </p>
        </>
      )}
      <div className="my-4">
        {sinkLibList
          .filter(s =>
            sinkSelector ? s.img && s.sinkType === sinkType : s.bestSeller
          )
          .filter(s => (kitchenOnly ? s.area === 'Kitchen' : true))
          .filter(s => (bathOnly ? s.area !== 'Kitchen' : true))
          .sort((a, b) => sortScore(b) - sortScore(a))
          .map((item, index) => (
            <div key={index} className="p-3 mb-3 shadow border">
              <p className="fs--1 fw-normal mb-0">
                Add the following sink to my order:
              </p>
              <div className="mb-0 my-2" key={index}>
                <CustomCheckbox
                  key={index}
                  name="sinkselection"
                  type="radio"
                  label={`${item.brandFullName} ${item.customerName}`}
                  value={item.moraInitialA}
                  checked={sinkInfo.sinkToPurchase === item.moraInitialA}
                  toggleCheck={toggleCheck}
                />
              </div>
              <p className="fs--1 fw-normal mb-0">
                <b>Price: </b> ${item.price}
              </p>
              <p className="fs--1 fw-normal mb-2">
                <b>Dimensions: </b> {item.dimensions}
              </p>
              {item.img && <img src={item.img} className="img-fluid" />}
            </div>
          ))}
        {!sinkSelector && (
          <div className="p-3 shadow border">
            <div className="mb-0 my-2">
              <CustomCheckbox
                name="sinkselection"
                type="radio"
                label={
                  <>
                    Don't add a sink to my order yet - I need to consider more
                    options
                  </>
                }
                value="none"
                checked={sinkInfo.sinkToPurchase === 'none'}
                toggleCheck={toggleCheck}
              />
            </div>
          </div>
        )}
      </div>
      <Button
        className="w-100"
        onClick={next}
        disabled={!sinkInfo?.sinkToPurchase}
      >
        Continue
      </Button>
    </Wrapper>
  );
}

import React, { useEffect, useContext } from 'react';
import { WizardContext } from 'context/Context';
// import { useNavigate } from 'react-router-dom';
// import CustomCheckbox from './components/CustomCheckbox';
import Wrapper from './components/Wrapper';
import { Col, Button, Form } from 'react-bootstrap';

export default function DecisionMakers() {
  // const navigate = useNavigate();
  const { job, decisionMakers, setDecisionMakers, handleDecisionMakers } =
    useContext(WizardContext);

  useEffect(() => {
    if (!job.roomInfo || job.cooktopDecided) {
      // navigate('../');
    }
  }, []);

  const handleChange = e => {
    console.log(e);
    setDecisionMakers({ ...decisionMakers, [e.target.id]: e.target.value });
  };
  // const toggleFarm = () => {
  //   setSinkInfo({ ...sinkInfo, farmInPlace: !sinkInfo.farmInPlace });
  // };
  const next = () => {
    console.log(decisionMakers);
    handleDecisionMakers();
  };

  return (
    <Wrapper>
      <div>
        <h5>Onsite Decision Maker</h5>
        <p>
          When we send a technician to do your final measure, we will need an
          onsite decision maker to approve any changes and financial
          adjustments. Other than yourself, who else is authorized to be the
          onsite decision maker for this job?
        </p>
        <h5>Primary Decision Maker</h5>
        <p>
          Name: {job?.address?.contactName}
          <br />
          Cell #:{' '}
          {job?.address?.cell || job?.address?.phone || job?.address?.phone2}
          <br />
        </p>
        <h5>Additional Decision Maker 1</h5>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="name1"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Cell #</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="cell1"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Role</Form.Label>
            <Form.Select
              type="select"
              className="form-control"
              id="role1"
              onChange={handleChange}
            >
              <option value=""></option>
              <option value="contractor">Contractor</option>
              <option value="designer">Designer</option>
              <option value="homeowner">Homeowner</option>
              <option value="family">Family/friend</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <hr />
        <h5>Additional Decision Maker 2</h5>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="name2"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Cell #</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="cell2"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Role</Form.Label>
            <Form.Select
              type="select"
              className="form-control"
              id="role2"
              onChange={handleChange}
            >
              <option value=""></option>
              <option value="contractor">Contractor</option>
              <option value="designer">Designer</option>
              <option value="homeowner">Homeowner</option>
              <option value="family">Family/friend</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12}>
          <hr />
          <Form.Group className="form-group">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              className="form-control"
              id="comments"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </div>
      <Button className="w-100 my-3" variant="primary" onClick={next}>
        Continue
        {!decisionMakers.name1 &&
          !decisionMakers.name2 &&
          ' (I am the only authorized decision maker)'}
      </Button>
    </Wrapper>
  );
}

import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import AddNewThings from './AddNewThings';
// import Call from './Call';
// import Confirmation from './Confirmation';
import UndermountStone from './UndermountStone';
export default function ChipMinimizerRoutes() {
  return (
    <Routes>
      <Route path="undermount-stone" element={<UndermountStone />} />
      {/* <Route path="confirmation" element={<Confirmation />} />
      <Route path="addlater" element={<AddNewThings />} />
      <Route path="call" element={<Call />} /> */}
    </Routes>
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useWizardContext } from '../WizardProvider';
import Wrapper from './Wrapper';
import { Link } from 'react-router-dom';
import Video2 from './Video2';

const steveVideo =
  'https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fmost-popular-sinks.mp4';

const InternalAction = ({ link, text }) => {
  const navigate = useNavigate();
  return (
    <Button
      className="mb-3"
      onClick={() => {
        navigate(link);
      }}
    >
      {text}
    </Button>
  );
};

InternalAction.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export const ExternalAction = ({ link, text }) => {
  return (
    <>
      <p className="my-2">
        <a href={link} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon="external-link-alt" /> {text}
        </a>
      </p>
      <p className="fs--1 mt-1" style={{ fontStyle: 'italic' }}>
        This will take you to Home Depot's website ... please return when you're
        done!
      </p>
    </>
  );
};

ExternalAction.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const sinkTypes = [
  {
    name: 'Undermount',
    decoration: (
      <>
        {' '}
        <FontAwesomeIcon icon="star" />{' '}
        <span className="text-secondary" style={{ fontStyle: 'italic' }}>
          most popular!
        </span>
      </>
    ),
    Stone: true,
    Ultracompact: true,
    'Butcher Block': true,
    'Solid Surface': true,
    description: (
      <p>
        Undermount is the most popular sink type. It is installed under the
        countertop, so there is no lip between the sink and the countertop.
      </p>
    ),
    image:
      'https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fundermount.jpeg',
    description2: (
      <>
        <p>
          You can buy an undermount sink directly from us, and we'll bring it to
          your home for your final measure appointment.
        </p>
        <p>
          We carry stainless steel undermount sinks for the kitchen and
          porcelain undermount sinks for bathroom vanities.
        </p>
      </>
    ),
    action: (
      <>
        <InternalAction
          link="../add-sink?sinkSelector=1&type=Undermount"
          text="Show me undermount sinks to purchase"
        />
        <p>
          You can find more undermount sinks (including sinks made from other
          materials like granite or quartz composite) from Home Depot
        </p>
        <ExternalAction
          link="https://www.homedepot.com/s/undermount%20sink"
          text="Show me undermount sinks at Home Depot"
        />
      </>
    )
  },
  {
    name: 'Farm / Apron',
    Stone: true,
    Ultracompact: true,
    'Butcher Block': true,
    'Solid Surface': true,
    description: (
      <p>
        A farm sink (also known as an apron sink) extends past the front of your
        countertops, creating a rustic look that's also quite dramatic.
      </p>
    ),
    description2: (
      <>
        <p>
          Farm sinks tend to be larger than other sinks, so make sure you have
          the necessary space.
        </p>
        <p>
          You can buy a farm sink from Home Depot -{' '}
          <strong>
            it must be in place in your cabinets and level when we come to
            measure
          </strong>
          .
        </p>
      </>
    ),
    image:
      'https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fundermount-farm.jpeg',
    action: (
      <ExternalAction
        link="https://www.homedepot.com/b/Kitchen-Kitchen-Sinks-Farmhouse-Kitchen-Sinks/N-5yc1vZcgk2"
        text="Show me farm sinks at Home Depot"
      />
    )
  },
  {
    name: 'Integral',
    'Solid Surface': false,
    description: (
      <p>
        An integral sink is a special option for solid surface countertops. It's
        made of the same material as your countertops in the same or a
        complementary color.
      </p>
    ),
    description2: (
      <>
        <p>
          An integral sink must be purchased directly from us, because we attach
          it to your countertops in our facilities before installation.
        </p>
      </>
    ),
    image:
      'https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fintegral.jpg',
    action: (
      <InternalAction
        link="../add-sink?sinkSelector=1&type=Integral"
        text="Show me integral sinks to purchase"
      />
    )
  },
  {
    name: 'Top Mount',
    Stone: true,
    Ultracompact: true,
    'Butcher Block': true,
    'Solid Surface': true,
    Laminate: true,
    description: (
      <p>
        A top mount sink is a solid, basic choice. As the name suggests, it is
        installed from the top of your countertops, and is held up by a lip
        integrated into the sink.
      </p>
    ),
    description2: (
      <>
        You can buy a top mount sink from Home Depot. It must be in your home
        for your final measure appointment.
      </>
    ),
    image:
      'https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Ftopmount.jpeg',
    action: (
      <ExternalAction
        link="https://www.homedepot.com/s/top%20mount%20kitchen%20sink?NCNI-5"
        text="Show me top mount sinks at Home Depot"
      />
    )
  },
  {
    name: 'Vessel',
    Stone: true,
    Ultracompact: true,
    'Butcher Block': true,
    'Solid Surface': true,
    description: (
      <p>
        A vessel sink sits on top of the countertop. There are many varieties,
        but they are typically quite decorate and are most commonly used in half
        baths.
      </p>
    ),
    description2: (
      <>
        You can buy a a vessel sink from Home Depot. It must be in your home for
        your final measure appointment.
      </>
    ),
    image:
      'https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fvessel.jpg',
    action: (
      <ExternalAction
        link="https://www.homedepot.com/b/Bath-Bathroom-Sinks-Vessel-Sinks/N-5yc1vZbz9i?NCNI-5"
        text="Show me vessel sinks at Home Depot"
      />
    )
  }
];

const SinkSelector = () => {
  const [visible, setVisible] = useState(sinkTypes.map(() => false));
  const navigate = useNavigate();
  const { sinkInfo, setSinkInfo, job } = useWizardContext();
  const { room } = useParams();

  const kitchenOnly =
    job?.rooms &&
    job.rooms.length === 1 &&
    job.rooms[room] &&
    job.rooms[room].name &&
    job.rooms[room].name.match(/kitchen/i);

  const bathOnly =
    job?.rooms &&
    job.rooms.length === 1 &&
    job.rooms[room] &&
    job.rooms[room].name &&
    job.rooms[room].name.match(/bathroom/i);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Wrapper>
      <h3 id="sink-selector">
        Choosing Your Sink
        <Link to={'../..'}>
          <FontAwesomeIcon icon="home" className="float-end" />
        </Link>
      </h3>
      {!bathOnly && <Video2 file={steveVideo} vertical={true} />}
      <p>
        Can't decide between 50/50, 60/40, or single bowl? Choose what you're
        used to! Unless you have a strong opinion about wanting to choose
        something new, most people prefer what they already know.
      </p>
      {job?.roomInfo?.materialType &&
        job?.roomInfo?.materialType !== 'Laminate' && (
          <>
            <p>
              With that in mind, you also need to select the <em>type</em> of
              sink you want. The options for your {job?.roomInfo?.brand}{' '}
              countertops are:
            </p>
          </>
        )}
      {job?.roomInfo?.materialType === 'Laminate' && (
        <>
          <p>
            For laminate countertops, the sink must be a top mount sink. You can
            find a top mount sink at Home Depot. Note that it must be in your
            home for your final measure appointment.
          </p>
          <ExternalAction
            link="https://www.homedepot.com/s/top%20mount%20kitchen%20sink?NCNI-5"
            text="Show me top mount sinks at Home Depot"
          />
        </>
      )}
      <p className="fs--1 my-2" style={{ fontStyle: 'italic' }}>
        Click on a sink type for more detail.
      </p>
      {sinkTypes
        .filter(t => t[job?.roomInfo?.materialType])
        .filter(t => (kitchenOnly ? t.name !== 'Vessel' : true))
        .filter(t => (bathOnly ? t.name !== 'Farm / Apron' : true))
        .map((sinkType, index) => {
          return (
            <div key={index}>
              <p className="my-1">
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    // const newVisible = sinkTypes.map(() => false);
                    const newVisible = [...visible];
                    newVisible[index] = !visible[index];
                    setVisible(newVisible);
                    return false;
                  }}
                >
                  <FontAwesomeIcon
                    icon={visible[index] ? 'angle-down' : 'angle-right'}
                  />{' '}
                  {sinkType.name}
                  {sinkType?.decoration}
                </a>
              </p>
              {visible[index] && (
                <>
                  <div>{sinkType.description}</div>
                  {sinkType.image && (
                    <img
                      className="img-fluid"
                      alt={sinkType.name + ' sink'}
                      src={sinkType.image}
                    />
                  )}
                  <div className="my-1">{sinkType.description2}</div>
                  {sinkType.action}
                </>
              )}
            </div>
          );
        })}
      <hr />
      <p>
        If you purchase a sink from elsewhere, please let us know:{' '}
        <Button
          onClick={() => {
            setSinkInfo({
              ...sinkInfo,
              purchased: 'yes',
              arrival: null,
              arrivalDate: null
            });
            navigate('../purchased');
          }}
        >
          I purchased a sink ...
        </Button>
      </p>
    </Wrapper>
  );
};

export default SinkSelector;

import React, { useState } from 'react';
import DisclosureLayout from './DisclosureLayout';
import PropTypes from 'prop-types';

export default function Soapstone({ standalone = false }) {
  const [list] = useState([
    {
      title: 'Scratches',
      img: 'https://precisioncountertops.com/wp-content/uploads/2021/09/soapstone-scratches.jpg'
    },
    {
      title: 'Spotting',
      img: 'https://precisioncountertops.com/wp-content/uploads/2022/01/soapstone-spotting-2.jpg'
    },
    {
      title: 'Applying Oil',
      img: 'https://precisioncountertops.com/wp-content/uploads/2022/01/applying-oil-to-soapstone.jpg'
    }
  ]);
  return (
    <DisclosureLayout
      standalone={standalone}
      title={'Soapstone Disclosure'}
      disclosureType={'Soapstone'}
      videoId={'-cMGHSkYm3M'}
      review={
        'PLEASE REVIEW THE INFORMATION BELOW REGARDING YOUR SOAPSTONE COUNTERTOPS'
      }
      sliderList={list}
      aboutTitle={
        'Soapstone is a beautiful unique natural material that can add a rustic, elegant and urban feel, similar to that of slate or granite to your home.'
      }
      aboutSubTitle={
        'While this material is very beautiful it does have some characteristics that need to be considered prior to being produced and installed.'
      }
      aboutList={[
        'Soapstone is a soft material that makes it susceptible to scratching.',
        'Oily foods or products can easily stain or darken the material.',
        'This material does require regular maintenance, which over time can alter the texture of the material.',
        "Mineral oil will help to keep your soapstone countertops looking beautiful and uniform across it's entirety. However, after applying mineral oil on the soapstone countertop, the texture may change to a soap-like, leathery feel, hence the name. It is impossible to change this non-slick texture."
      ]}
      aboutConclusion={
        'Caring for this natural stone requires extra attention. Spilled food and beverages need to be cleaned up immediately. Cleaning should be done with soft towels and mild soap and water.'
      }
    />
  );
}

Soapstone.propTypes = {
  standalone: PropTypes.bool
};

import React, { useContext, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { WizardContext } from 'context/Context';
import PropTypes from 'prop-types';
import Branding from './Branding';
import Loading from './Loading';
import is from 'is_js';
import NotFound from './NotFound';
import Saving from './Saving';

function Wrapper({ children, branding = false, standalone = false }) {
  const { loading, saving, notFound, agent } = standalone
    ? {
        loading: () => {
          return false;
        }
      }
    : useContext(WizardContext);

  useEffect(() => {
    if (agent) {
      document.body.style.backgroundColor = '#FFFFED';
    }
  }, [agent]);

  if (is.mobile())
    return (
      <div className="pt-4">
        {agent && (
          <div className="text-center m-0 p-0 fs--1">Agent: {agent}</div>
        )}
        {(branding || notFound) && <Branding />}
        {loading() ? (
          <Loading />
        ) : saving ? (
          <Saving />
        ) : notFound ? (
          <NotFound />
        ) : (
          children
        )}
      </div>
    );
  else
    return (
      <>
        {agent && (
          <div className="text-center m-0 mt-2 p-0 fs--1">Agent: {agent}</div>
        )}
        <Card className="my-3">
          {(branding || notFound) && <Branding />}
          <Card.Body>
            {loading() ? (
              <Loading />
            ) : saving ? (
              <Saving />
            ) : notFound ? (
              <NotFound />
            ) : (
              children
            )}
          </Card.Body>
        </Card>
      </>
    );
}

Wrapper.propTypes = {
  children: PropTypes.node,
  branding: PropTypes.bool,
  standalone: PropTypes.bool
};

export default Wrapper;

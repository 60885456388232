import React from 'react';
import { Row, Col } from 'react-bootstrap';
import is from 'is_js';
import { useParams } from 'react-router-dom';

const Branding = () => {
  const { guid } = useParams();
  const isHD = !guid.match(/^r-/);
  const mobile = is.mobile();
  return (
    <Row className="py-4 px-lg-7 py-lg-3">
      {isHD && (
        <Col className="text-center">
          <img
            width={mobile ? '100' : '150'}
            height={mobile ? '100' : '150'}
            src="/hdlogo.svg"
            data-src="/hdlogo.svg"
            className=""
            alt="Home Depot Logo"
          />
        </Col>
      )}
      <Col className="text-center">
        <img
          style={{ backgroundColor: '#263A64' }}
          width={mobile ? '100' : '150'}
          height={mobile ? '100' : '150'}
          src="//precisioncountertops.com/wp-content/uploads/2020/04/pct-footer-logo.svg"
          data-src="//precisioncountertops.com/wp-content/uploads/2020/04/pct-footer-logo.svg"
          className="p-1"
          alt="Precision Countertops Logo"
        />
      </Col>
    </Row>
  );
};

export default Branding;

import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { WizardContext } from 'context/Context';
import Wrapper from './components/Wrapper';
import EditForm from './components/EditForm';

const Edit = () => {
  const { job, loading } = useContext(WizardContext);

  return job.confirmed ? (
    <Navigate to={'../'} />
  ) : (
    <Wrapper>{!loading() && <EditForm />}</Wrapper>
  );
};

export default Edit;

import React, { useState } from 'react';
import DisclosureLayout from './DisclosureLayout';
import PropTypes from 'prop-types';

export default function Quartzite({ standalone = false }) {
  const [list] = useState([
    {
      title: 'Finished kitchen',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/Design-by-JC-Remodeling-Taj-Mahal-Leathered-Quartzite-cropped-e1645226049835-pkprjgrfypbhdyo5nge7jtj5vjz7tc9ry31nwjk4ug.jpg'
    },
    {
      title: 'Finished kitchen',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/DAL_TajMajal-quartzite-scaled-pitvi8c7q0600e4rmcqqdl5lzjzwjizp3lxxao54zs.jpg'
    },
    {
      title: 'Finished kitchen',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/Quartz-vs.-Quartzite-Countertops-PLUS-Quartzite-Pros-Cons-18_Sebring-Design-Build-e1645226067611-pkprjxojdpyn6xzkwnphsp9gkhntnw4y0esejiv1qg.jpg'
    }
  ]);
  return (
    <DisclosureLayout
      standalone={standalone}
      title={'Quartzite Disclosure'}
      videoId={'zRy0V3tyWyw'}
      review={
        'PLEASE REVIEW THE INFORMATION BELOW REGARDING YOUR QUARTZITE COUNTERTOPS'
      }
      sliderList={list}
      aboutTitle={'Quartzite is often confused with Quartz countertops'}
      aboutSubTitle={
        'Quartzite is a natural stone that has many qualities of other natural stones like granite. It is a very hard surface similar to granite, that will not scratch easily. However, Quartzite is a very porous material that may require more frequent sealing. Due to the porous nature of this material while it works through our wet shop it will have longer production times. This is to ensure the tops are completely dry prior to the sealant being applied.'
      }
      aboutList={[]}
    />
  );
}

Quartzite.propTypes = {
  standalone: PropTypes.bool
};

import React, { Fragment, useContext } from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { ConfigContext } from 'context/Context';

const Home = () => {
  const { fabricator } = useContext(ConfigContext);

  return (
    <Fragment>
      <div className="container">
        <div className="card-deck pt-3">
          <Card className="mb-3">
            <FalconCardHeader
              title={`Countertop Wizard ${
                fabricator ? `| ${fabricator.name}` : ''
              }`}
              light={false}
            ></FalconCardHeader>
            <Card.Body>
              <p>
                Your project coordinator will send you a personalized link with
                more information.
              </p>
              {fabricator ? (
                <p>
                  In the meantime, if you have any questions, call{' '}
                  <a href={fabricator.phonelink}>{fabricator.phone}</a>.
                </p>
              ) : (
                ''
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;

import React, { useContext, useEffect, useState } from 'react';
import { WizardContext } from 'context/Context';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isoShortDate } from 'si/helpers/isoShortDate';

const msWeek = 1000 * 60 * 60 * 24 * 7;

export function isTopmount(sinkInfo) {
  return (
    sinkInfo?.type === 'topmount' ||
    sinkInfo?.type === 'topmount farm' ||
    (sinkInfo?.type === 'undermount' && // they purchased a dualmount and confirmed that it will be installed as topmount
      sinkInfo.dualMount === 'purchased')
  );
}

export default function Range() {
  const { cooktopInfo, setCooktopInfo, job, cooktopFollowUp } =
    useContext(WizardContext);
  const [dateVal, setDateVal] = useState('');
  const [weeks, setWeeks] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    if (!cooktopFollowUp() && (!job.roomInfo || job.cooktopDecided)) {
      navigate('../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const toggleCheck = value => {
    setCooktopInfo({
      ...cooktopInfo,
      existsRaw: value,
      exists: value === 'no' ? 'no' : 'yes',
      arrival:
        value === 'yes-on-site'
          ? 'on site'
          : value === 'yes-date'
          ? 'date'
          : value === 'yes-existing'
          ? 'existing'
          : undefined,
      arrivalDate: dateVal || undefined
    });
  };
  const arrivalData = (value, date) => {
    setCooktopInfo({ ...cooktopInfo, arrival: value, arrivalDate: date });
  };

  const arrivalWeeks = e => {
    const value = e.target.value;
    setWeeks(value);
    const date = new Date().getTime() + msWeek * value;
    const isoDate = isoShortDate(new Date(date));
    setDateVal(isoDate);
    setCooktopInfo({
      ...cooktopInfo,
      exists: 'yes',
      existsRaw: 'yes-date',
      arrival: 'date',
      arrivalDate: isoDate
    });
  };

  const next = () => {
    navigate('../appliances');
  };

  return (
    <Wrapper>
      {cooktopFollowUp() ? (
        <p>Has your new cooktop or range arrived yet?</p>
      ) : (
        <p>Will your countertops have a new cooktop or range?</p>
      )}
      <CustomCheckbox
        name="cooktopyesonsite"
        type="radio"
        label={
          cooktopFollowUp()
            ? `Yes, it's on site`
            : `Yes, there's a new cooktop/range, and it's already on site`
        }
        value="yes-on-site"
        toggleCheck={toggleCheck}
        checked={cooktopInfo?.existsRaw === 'yes-on-site'}
      />
      <CustomCheckbox
        name="coooktopdate"
        type="radio"
        label={
          cooktopFollowUp()
            ? 'No, we expect it to arrive approximately'
            : `Yes, there's a new cooktop/range, but it will arrive later`
        }
        value="yes-date"
        toggleCheck={toggleCheck}
        checked={cooktopInfo?.existsRaw === 'yes-date'}
      />
      {(cooktopFollowUp() || cooktopInfo?.existsRaw === 'yes-date') && (
        <div className={`${cooktopFollowUp() ? '' : 'mx-4'} mb-3`}>
          <p className="fs-0">
            {!cooktopFollowUp() && <>Great. When do you expect it to arrive?</>}
          </p>
          {!cooktopFollowUp() && <>Approximately</>}
          <p className="mx-3 my-2">
            <input
              type={'number'}
              min={1}
              max={30}
              value={weeks}
              onChange={arrivalWeeks}
            />{' '}
            week(s)
          </p>
          <p className="mx-4 my-2">or</p>
          <p className="mx-3 my-0">
            <input
              type={'date'}
              value={dateVal}
              onChange={e => {
                setDateVal(e.target.value);
                arrivalData('date', e.target.value);
              }}
            />
          </p>
        </div>
      )}
      {!cooktopFollowUp() && (
        <CustomCheckbox
          name="cooktopexiting"
          type="radio"
          label={'No, we will be reusing our existing cooktop/range'}
          value="yes-existing"
          toggleCheck={toggleCheck}
          checked={cooktopInfo?.existsRaw === 'yes-existing'}
        />
      )}
      {!cooktopFollowUp() && (
        <CustomCheckbox
          name="cabinets"
          type="radio"
          label="No, there's no cooktop/range"
          value="no"
          toggleCheck={toggleCheck}
          checked={cooktopInfo?.existsRaw === 'no'}
        />
      )}
      <Button
        className="w-100"
        variant="primary"
        disabled={
          !cooktopInfo?.exists ||
          (cooktopInfo?.existsRaw === 'yes-date' && !cooktopInfo?.arrivalDate)
        }
        onClick={next}
      >
        Continue
      </Button>
    </Wrapper>
  );
}

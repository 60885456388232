import React from 'react';
import WizardLayout from './WizardLayout';

const WizardForms = () => {
  return (
    <div>
      <h2 className="text-center my-5">Confirm your Countertops</h2>
      <WizardLayout />
    </div>
  );
};

export default WizardForms;

import React, { useEffect, useState } from 'react';
import WizardInput from './WizardInput';
import PropTypes from 'prop-types';
import { prices } from 'prices';
export default function Acknowledge({ register, errors }) {
  const [newCabinets, setNewCabinates] = useState(false);
  const [oldCabinets, setOldCabinates] = useState(false);
  const [whoProviding, setWhoProviding] = useState(false);

  useEffect(() => {
    console.log(newCabinets);
  }, [newCabinets]);
  return (
    <div>
      <div className="border-bottom mb-3">
        <p>Will any of your countertops be installed on new cabinets?</p>
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue={'yes'}
          label={<>Yes</>}
          name={'newCabinets'}
          onClicked={() => setNewCabinates(true)}
          formControlProps={{
            ...register('newCabinets')
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue={'no'}
          label={<>No</>}
          defaultChecked={true}
          name={'newCabinets'}
          onClicked={() => setNewCabinates(false)}
          formControlProps={{
            ...register('newCabinets', { value: 'no' })
          }}
        />
        {newCabinets && (
          <div>
            <p className="fs--1">
              We can only template once your new cabinets are permanently
              installed (including all end panels and fillers), and cabinets
              must be perfectly level before you can schedule your template.
              Please schedule your template for a date after your cabinet
              install is completely finished to avoid incurring additional trip
              charges.
            </p>
            <WizardInput
              type="checkbox"
              errors={errors}
              label={<>I acknowledge the above statement on new cabinets</>}
              name={'newCabinetsAccept'}
              formControlProps={{
                ...register('newCabinetsAccept', {
                  required:
                    'Please acknowledge the above statement on new cabinets'
                })
              }}
            />
          </div>
        )}
      </div>
      <div className="border-bottom mb-3">
        <p>Will any of your countertops be installed on existing cabinets?</p>
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="yes"
          label={<>Yes</>}
          name={'existingCabinets'}
          onClicked={() => setOldCabinates(true)}
          formControlProps={{
            ...register('existingCabinets')
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="no"
          label={<>No</>}
          defaultChecked={true}
          name={'existingCabinets'}
          onClicked={() => setOldCabinates(false)}
          formControlProps={{
            ...register('existingCabinets', { value: 'no' })
          }}
        />
        {oldCabinets && (
          <div>
            <h6>Cabinets must be installed and level!</h6>
            <p>
              If you are reusing old cabinets, they must be fully installed and
              completely level before you can schedule your template.
              <b>
                If your cabinets are not installed and level, please wait until
                they are before completing this form.
              </b>
            </p>
            <WizardInput
              type="checkbox"
              errors={errors}
              label={
                <>
                  I acknowledge that my existing cabinets are fully installed
                  and completely level.
                </>
              }
              name={'newCabinetsAccept'}
              formControlProps={{
                ...register('oldExistingCabinets', {
                  required:
                    'Please acknowledge the above statement on existing cabinets'
                })
              }}
            />
          </div>
        )}
      </div>
      <div className="border-bottom mb-3">
        <p>Who is providing the sink for this project?</p>
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="I am providing the sink."
          label={<>I am providing the sink.</>}
          onClicked={() => setWhoProviding(false)}
          name={'whoProviding'}
          defaultChecked={true}
          formControlProps={{
            ...register('whoProviding', { value: 'I am providing the sink.' })
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="I bought the sink through Accent"
          label={<>I bought the sink through Accent</>}
          onClicked={() => setWhoProviding(false)}
          name={'whoProviding'}
          formControlProps={{
            ...register('whoProviding')
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="other"
          label={<>Other</>}
          onClicked={() => setWhoProviding(true)}
          name={'whoProviding'}
          formControlProps={{
            ...register('whoProviding')
          }}
        />
        {whoProviding && (
          <div>
            <p>
              When you provide your own sink, you must have it on-site at your
              template appointment. If it's a Farm Sink, it must be set in it's
              final place, and the top of the farm sink must be level with the
              top of the cabinet before you can schedule your template. If it's
              a dual-mount sink, it can only be mounted as a top mount sink
            </p>
            <WizardInput
              type="checkbox"
              errors={errors}
              label={
                <>
                  I acknowledge that my sink is on site and ready for the
                  template appointment.
                </>
              }
              name={'providingAcknowledgeAppointment'}
              formControlProps={{
                ...register('providingAcknowledgeAppointment', {
                  required: 'Please acknowledge the above statement.'
                })
              }}
            />
            <WizardInput
              type="checkbox"
              errors={errors}
              label={
                <>
                  I acknowledge that if my sink is a Farm Sink, it is set in
                  it's final place, and the top of the farm sink is lavel with
                  the top of the cabinet.
                </>
              }
              name={'providingAcknowledgeCabinet'}
              formControlProps={{
                ...register('providingAcknowledgeCabinet', {
                  required: 'Please acknowledge the above statement.'
                })
              }}
            />
            <WizardInput
              type="checkbox"
              errors={errors}
              label={
                <>
                  I acknowledge that if my sink is a dual mount sink, it is set
                  in it's final place, and the top of the farm sink is level
                  with the a top mount sink.
                  <br />
                  <i>
                    If you would like an undermounted sink, you will need to
                    obtain a new sink prior to scheduling your template
                    appointment.
                  </i>
                </>
              }
              name={'providingAcknowledgeSink'}
              formControlProps={{
                ...register('providingAcknowledgeSink', {
                  required: 'Please acknowledge the above statement.'
                })
              }}
            />
          </div>
        )}
      </div>
      <div className="border-bottom mb-3">
        <h5>Seams Agreement</h5>
        <p>
          Hard surface countertops exhibit visible seams. Every effort is made
          to limit the number of seams. Seam location is designed to be
          aesthetically pleasing and economically sensitive. Expect large
          degrees of color variation or pattern at the seam. Final seam
          placement will be determined during production. Most slabs will not be
          perfectly flat although all possible measures are taken to ensure
          tight and smooth seams. Slight variations are to be expected including
          ledges with the tolerance of the thickness of a business card.
        </p>
        <WizardInput
          type="checkbox"
          errors={errors}
          label={<>I acknowledge the above statement on seams</>}
          name={'seamsAgreement'}
          formControlProps={{
            ...register('seamsAgreement', {
              required: 'Please acknowledge the above statement on seams'
            })
          }}
        />
      </div>
      <div className="border-bottom mb-3">
        <h5>What to Expect: Quartz / Ultra Compact</h5>
        <p>
          To continue, please read{' '}
          <a href="https://asf.schedulecountertops.com/WTEQuartzAndUltraCompact.pdf">
            What to Expect for Quartz / Ultra Compact
          </a>
        </p>
        <WizardInput
          type="checkbox"
          errors={errors}
          label={
            <>
              I acknowledge reading the above What to Expect document for Quartz
              / Ultra Compact
            </>
          }
          name={'quartzAgreement'}
          formControlProps={{
            ...register('quartzAgreement', {
              required:
                'Please acknowledge the above document on What to Expect.'
            })
          }}
        />
      </div>
      <div className="border-bottom mb-3">
        <h5>Digital Slab Layout({prices.digitalRendering})</h5>
        <p>
          A digital slab layout will show you details like seam placement and
          vein direction before your countertops are cut.{' '}
          <a href="https://accentcountertops.com/digital-slab-view/">
            Click here for an example.
          </a>
        </p>
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="yes"
          label={
            <>
              Yes, I would like to view a digital slab layout of my project for
              {prices.digitalRendering}
            </>
          }
          name={'slabLayout'}
          formControlProps={{
            ...register('slabLayout')
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="no"
          label={<>No slab view</>}
          name={'slabLayout'}
          defaultChecked={true}
          formControlProps={{
            ...register('slabLayout')
          }}
        />
      </div>
      <div className="border-bottom mb-3">
        <h5>
          Who is taking care of the tear out and removal of your existing
          countertops?
        </h5>
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="myself"
          label={<>Myself</>}
          defaultChecked={true}
          name={'existingCountertops'}
          formControlProps={{
            ...register('existingCountertops', { value: 'myself' })
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="accent"
          label={<>Accent</>}
          name={'existingCountertops'}
          formControlProps={{
            ...register('existingCountertops')
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="third-party"
          label={<>Third Party</>}
          name={'existingCountertops'}
          formControlProps={{
            ...register('existingCountertops')
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="quartz"
          label={
            <>
              Add tear out for quartz, granite and/or tile:{' '}
              {prices.tearoutOther}
            </>
          }
          name={'existingCountertops'}
          formControlProps={{
            ...register('existingCountertops')
          }}
        />
        <WizardInput
          type="radio"
          errors={errors}
          defaultValue="laminate"
          label={
            <>
              Add tear out for laminate and/or solid surface:{' '}
              {prices.tearoutLaminate}
            </>
          }
          name={'existingCountertops'}
          formControlProps={{
            ...register('existingCountertops')
          }}
        />
      </div>
      <div>
        <h5>Additional Acknowledgements</h5>
        <p>
          To avoid an incomplete template appointment and incurring additional
          trip charges, please acknowledge the following:
        </p>
        <WizardInput
          type="checkbox"
          errors={errors}
          label={
            <>
              I acknowledge that a decision maker over the age of 18 will be
              on-site during the entire appointment in order to verify specific
              details (overhangs, radiuses, backsplash, etc.)
            </>
          }
          name={'additionalAcknowledgementOnAge'}
          formControlProps={{
            ...register('additionalAcknowledgementOnAge', {
              required: 'Please acknowledge the above statement.'
            })
          }}
        />
        <WizardInput
          type="checkbox"
          errors={errors}
          label={
            <>
              I acknowledge that if my range or cooktop is part of or next to
              any new countertop, then it will be onsite at the template
              appointment
            </>
          }
          name={'additionalAcknowledgementOnCooktop'}
          formControlProps={{
            ...register('additionalAcknowledgementOnCooktop', {
              required: 'Please acknowledge the above statement.'
            })
          }}
        />
      </div>
    </div>
  );
}
Acknowledge.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

import React, { useEffect, useState, useContext } from 'react';
import Wrapper from '../components/Wrapper';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import Loading from '../components/Loading';
import SliderComponent from '../components/SliderComponent';
import { ConfigContext, WizardContext } from 'context/Context';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from '../components/CustomCheckbox';

function SplitParagraphs({ text }) {
  const paragraphs = text.split('\n');
  return (
    <>
      {paragraphs.map((paragraph, index) => {
        if (paragraph.startsWith('* ')) {
          return (
            <ul>
              <li key={index}>{paragraph.substring(1)}</li>
            </ul>
          );
        }
        return <p key={index}>{paragraph}</p>;
      })}
    </>
  );
}

SplitParagraphs.propTypes = {
  text: PropTypes.string
};

function getDisclosureDefault({ materialType, isDirectional }) {
  // console.log({ materialType, isDirectional });
  if (isDirectional === 'true' && materialType === 'Solid Surface') {
    return 'Default-Directional';
  }
  return 'Default';
}

export default function LibraryDisclosure({ standalone = false }) {
  const [loading, setLoading] = useState(true);
  const [sliderList, setSliderList] = useState([]);
  const [data, setData] = useState({});
  const [answer, setAnswer] = useState('');

  const { brand, color } = useParams();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const materialType = searchParams.get('materialType');
  const isDirectional = searchParams.get('isDirectional');

  const navigate = useNavigate();

  const { libraryEndpoint } = useContext(ConfigContext);
  const { job, setJob, handleLibraryDisclosure } = standalone
    ? {}
    : useContext(WizardContext);

  useEffect(() => {
    if (!standalone && !job.roomInfo) {
      navigate('../..');
    }
  }, []);

  useEffect(() => {
    async function fetchDisclosure() {
      const path = `${libraryEndpoint}/disclosures/${brand}/${color}?default=${getDisclosureDefault(
        { materialType, isDirectional }
      )}`;
      const result = await fetch(path);
      if (result.ok) {
        const data = await result.json();
        console.log(data);
        const newList = [];
        if (data.image1URL) {
          newList.push({
            img: data.image1URL,
            title: data.image1Description
          });
        }
        if (data.image2URL) {
          newList.push({
            img: data.image2URL,
            title: data.image2Description
          });
        }
        if (data.image3URL) {
          newList.push({
            img: data.image3URL,
            title: data.image3Description
          });
        }
        setSliderList(newList);
        setData(data);
        setLoading(false);
      } else {
        // handle error
        if (standalone) {
          setData({ none: true });
          setLoading(false);
        } else {
          handleLibraryDisclosure({ none: true });
          navigate('../..');
        }
      }
    }
    if (libraryEndpoint) fetchDisclosure();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [libraryEndpoint]);

  const next = () => {
    handleLibraryDisclosure({
      answer,
      ...data,
      color
    });
    navigate('../..');
  };

  const back = () => {
    navigate(-1);
  };

  const toggleCheck = value => {
    setAnswer(value);
    setJob({ ...job, disclosuresAccepted: true });
  };

  return (
    <>
      {/* <p>test</p>
      <video
        src="https://library.staging.countertopwizard.com/Disclosure%2FCaesarstone%2FBlack%20Tempal%20(Natural%20Finish)-video"
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        controls={true}
        width={'100%'}
        height={'auto'}
      ></video> */}

      <Wrapper standalone={standalone}>
        <h2 className="fw-lighter textStyleHead">
          Disclosures for {brand} {color}
        </h2>
        {loading && <Loading msg="Retrieving disclosures ..." />}
        {data?.videoURL && (
          <video
            src={data.videoURL}
            title="Video Player"
            frameBorder="0"
            allow="accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
            controls={true}
            width={'100%'}
            style={{ maxHeight: '400px' }}
          ></video>
        )}
        {data?.videoSummary && <SplitParagraphs text={data.videoSummary} />}
        <div className="my-5">
          <SliderComponent list={sliderList} style={'imgSize'} />
        </div>
        <p>{data?.closingStatement}</p>
        {data?.none && (
          <p>
            No disclosures for {brand} {color}
          </p>
        )}
        {!standalone && !loading && (
          <>
            <hr />
            <p>
              Are you still happy with {brand} {color}?
            </p>
            <CustomCheckbox
              name="disclosure"
              type="radio"
              label="Yes"
              value="yes"
              checked={answer === 'yes'}
              toggleCheck={toggleCheck}
            />
            <CustomCheckbox
              name="disclosure"
              type="radio"
              label="I'm not sure"
              value="no"
              checked={answer === 'no'}
              toggleCheck={toggleCheck}
            />
            {answer === 'no' && (
              <p>I'll call you to discuss, but let's continue for now ...</p>
            )}
          </>
        )}
        {standalone ? (
          <Button className="w-100" variant="primary" onClick={back}>
            Back
          </Button>
        ) : (
          <Button
            className="w-100"
            variant="primary"
            disabled={!answer}
            onClick={next}
          >
            Continue
          </Button>
        )}
      </Wrapper>
    </>
  );
}

LibraryDisclosure.propTypes = {
  standalone: PropTypes.bool
};

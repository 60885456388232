import React, { useEffect, useContext } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Wrapper from '../components/Wrapper';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DisclosureLayoutPlain from './DisclosureLayoutPlain';

function DisclosureLayout({
  standalone = false,
  disclosureType,
  title,
  videoId,
  videoFile,
  review,
  sliderList,
  aboutTitle,
  aboutSubTitle = '',
  aboutList = [],
  aboutConclusion = '',
  warranty
}) {
  const { job, handleDisclosures } = standalone
    ? { job: null }
    : useContext(WizardContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!standalone && !job.roomInfo) {
      navigate('../..');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [job]);

  return (
    <Wrapper standalone={standalone}>
      <DisclosureLayoutPlain
        standalone={standalone}
        title={title}
        disclosureType={disclosureType}
        videoId={videoId}
        videoFile={videoFile}
        review={review}
        sliderList={sliderList}
        aboutTitle={aboutTitle}
        aboutList={aboutList}
        aboutSubTitle={aboutSubTitle}
        aboutConclusion={aboutConclusion}
        warranty={warranty}
      />
      <div>
        {standalone ? (
          <>
            <Button
              className="w-100 my-3"
              variant="primary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </>
        ) : (
          <>
            <div className="my-3">
              <Button
                onClick={() => {
                  const { userAgent } = navigator;
                  handleDisclosures({
                    disclosureType,
                    url: window.location.href,
                    userAgent
                  });
                  if (job?.offerRendering) {
                    navigate('../../rendering');
                  } else {
                    navigate('../../edge');
                  }
                }}
                className="w-100 mt-3"
              >
                I accept the above conditions
              </Button>
            </div>
            <div className="text-center my-3">
              <Link to={'../../problem'}>I'm unsure, can someone call me?</Link>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
}
DisclosureLayout.propTypes = {
  standalone: PropTypes.bool,
  title: PropTypes.string,
  disclosureType: PropTypes.string,
  videoId: PropTypes.string,
  videoFile: PropTypes.string,
  review: PropTypes.string,
  sliderList: PropTypes.array,
  aboutList: PropTypes.array,
  aboutTitle: PropTypes.string,
  aboutSubTitle: PropTypes.string,
  aboutConclusion: PropTypes.string,
  warranty: PropTypes.object
};

export default DisclosureLayout;

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext, WizardContext } from 'context/Context';
import Package from '../../../../package.json';

const availableDisclosures = ['Dekton'];

const WizardProvider = ({ children, guid }) => {
  const [job, setJob] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [saving, setSaving] = useState(false);
  const [imgColor, setImgColor] = useState();
  const [imgColorSlab, setImgColorSlab] = useState();
  const [imgEdge, setImgEdge] = useState();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [edgeDetails, setEdgeDetails] = useState();
  const [edgeChoices, setEdgeChoices] = useState();
  const [edgeChanged, setEdgeChanged] = useState(false);
  const [waitForEdge, setWaitForEdge] = useState(false);
  const [missingEdgeImage, setMissingEdgeImage] = useState(false);
  const [startingRooms] = useState([
    'Kitchen (no island)',
    'Kitchen (with same color island)',
    'Kitchen (with different color island)',
    'Kitchen (island only)',
    'Master bathroom',
    'Guest bathroom',
    'Other bathroom',
    'Laundry',
    'Outdoor area',
    'Other'
  ]);
  const [sink] = useState([
    'undermount',
    'unknown',
    'type',
    'dual-mount',
    'sink-call',
    'existing',
    'topmount'
  ]);
  const [sinkInfo, setSinkInfo] = useState({});
  const [cooktopInfo, setCooktopInfo] = useState({});
  const [cabinetInfo, setCabinetInfo] = useState({});
  const [tearoutInfo, setTearoutInfo] = useState({});
  const [plumbingInfo, setPlumbingInfo] = useState({});
  const [decisionMaker, setDecisionMaker] = useState({});
  const [sinkLibDetails, setSinkLibDetails] = useState();
  const [sinkLibList, setSinkLibList] = useState([]);
  const [roomChecks, setRoomChecks] = useState(
    startingRooms.reduce((acc, curr) => ((acc[curr] = false), acc), {})
  );
  const [otherRoom, setOtherRoom] = useState('');
  const [qaInfo, setQaInfo] = useState({});
  const [agent, setAgent] = useState(null);

  const { db, endpoint, libraryEndpoint, test, filesBucket } =
    useContext(ConfigContext);
  const version = Package.version;

  useEffect(() => {
    const agentEmail = localStorage.getItem('agentEmail');
    if (agentEmail) {
      setAgent(agentEmail);
      document.body.style.backgroundColor = '#FFFFED';
      console.log(`Agent email found: ${agentEmail}`);
    }
  }, []);

  useEffect(() => {
    if (version) console.log(`Build version: ${version}`);
  }, [version]);

  useEffect(() => {
    async function getTest() {
      console.log(`Using test data`);
      if (test[guid]) setJob(test[guid]);
      else setNotFound(true);
      setSelectedRooms(test[guid].rooms);
      setSinkInfo(test[guid].sink);
    }
    if (guid && test) getTest();
  }, [guid, test]);

  async function getData() {
    const jobPath = `${endpoint}/${db}/job/${guid}`;
    const res = await fetch(jobPath); // if not found, this will return 404 or 500
    if (!res.ok) {
      console.log(res);
      setNotFound(true);
    } else {
      console.log('retrieved initial job data');
      const jobData = await res.json();
      const empty = {
        contactName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        cell: '',
        phone: '',
        phone2: ''
      };
      jobData.address = { ...empty, ...jobData.address };
      // console.log(jobData?.roomInfo);
      jobData.disclosures = [];
      if (jobData?.roomInfo) {
        const { brand, color, materialType, isDirectional } = jobData?.roomInfo;
        if (brand && color && materialType) {
          if (availableDisclosures.includes(brand)) {
            jobData.disclosures.push(brand);
          } else if (materialType === 'Solid Surface' && isDirectional) {
            jobData.disclosures.push('solid-surface-directional');
          }
          // if (brand === 'Granite') {
          //   if (color.match(/^Soapstone/)) {
          //     jobData.disclosures.push('Soapstone');
          //   }
          // }
        }
      }
      console.log({ jobData });
      setJob(jobData);
      if (jobData.rooms) {
        setSelectedRooms(jobData.rooms);
      }
      if (jobData?.install?.decisionMaker) {
        if (jobData?.install?.decisionMaker?.who === 'other') {
          const { name, cell, role } = jobData?.install?.decisionMaker;
          setDecisionMaker({ name, cell, role });
        }
      } else if (jobData?.template?.decisionMaker?.who === 'other') {
        const { name, cell, role } = jobData?.template?.decisionMaker;
        setDecisionMaker({ name, cell, role });
      }
      if (jobData?.voc?.formDetails) {
        setQaInfo(jobData?.voc?.formDetails?.qaInfo);
      }
    }
  }

  useEffect(() => {
    if (guid && endpoint && !test) getData();
  }, [guid, endpoint, test]);

  async function getEdgeDetails(materialType, edge) {
    // console.log({ materialType, edge });
    if (edge) {
      const path = `${libraryEndpoint}/edge`;
      console.log(path);
      const res = await fetch(path, {
        method: 'POST',
        body: JSON.stringify({ materialType, edge })
      }); // if not found, this will return 404 or 500
      if (res.ok) {
        const details = await res.json();
        console.log(details);
        setEdgeDetails(details);
      } else {
        console.log(
          `Couldn't find edge ${edge} for materialType ${materialType}`
        );
      }
    }
    if (!job.splashesConfirmed) {
      const path = `${libraryEndpoint}/edges/${encodeURIComponent(
        materialType
      )}`;
      console.log('Getting EDGES');
      console.log(path);
      const res = await fetch(path);
      if (!res.ok) {
        console.log(
          `Couldn't find edge ${edge} for materialType ${materialType}`
        );
        return;
      }
      const choices = await res.json();
      console.log(choices);
      const offeredChoices = choices.filter(e => e.homeDepot && e.offeredInCw);
      console.log(offeredChoices);
      setEdgeChoices(offeredChoices);
    }
  }

  async function loadEdgeImg() {
    function doMissing(step) {
      console.log(`Couldn't load edge image ${step || ''}`);
      setMissingEdgeImage(true);
      setWaitForEdge(false);
    }
    // try to load image from lov id
    const edgeInitial =
      job?.roomInfo?.edgeInitial && job?.roomInfo?.edgeInitial;
    if (!edgeInitial) return doMissing(1);
    setWaitForEdge(true);
    const path = `${libraryEndpoint}/edge/initial/${edgeInitial}`;
    console.log(path);
    const res = await fetch(path);
    if (!res.ok) return doMissing(2);
    const json = await res.json();
    const mainImageURL = json?.mainImageURL;
    if (!mainImageURL) return doMissing(3);
    const imgEdge = new Image(600, 600);
    imgEdge.onerror = doMissing;
    imgEdge.onload = () => {
      console.log(`Edge Image load (from lov id) SUCCEEDED`);
      setWaitForEdge(false);
      setMissingEdgeImage(false);
      setImgEdge(imgEdge);
    };
    imgEdge.src = mainImageURL;
  }

  async function loadSinkLibDetails(sinkMoraName) {
    function doMissing(step) {
      console.log(`Couldn't load sink library details ${step || ''}`);
    }
    const path = `${libraryEndpoint}/sink/moraname/${encodeURIComponent(
      sinkMoraName
    )}`;
    console.log(path);
    const res = await fetch(path);
    if (!res.ok) return doMissing();
    const json = await res.json();
    console.log('sinkLibDetails', json);
    setSinkLibDetails(json);
  }

  async function loadSinkLibList(materialType) {
    function doMissing(step) {
      console.log(`Couldn't load sink library list ${step || ''}`);
    }
    const path = `${libraryEndpoint}/sinks/forsale/${materialType}`;
    console.log(path);
    const res = await fetch(path);
    if (!res.ok) return doMissing();
    const json = await res.json();
    if (!json || !json.length) {
      console.log(`Sink list isn't an array`);
      return;
    }
    const sinkList = json
      .map(i => ({
        ...i,
        img: i.cadKey
          ? `${filesBucket}/${encodeURIComponent(i.cadKey)}`
          : undefined
      }))
      .filter(i => !i?.model?.match(/(QUWS|QU-81|QU-82|WS-3|HDI K)/));
    // console.log('sinkLibList', sinkList);
    setSinkLibList(sinkList);
    // preload the images
    for (const sink of sinkList) {
      // console.log('preload sink image', sink);
      try {
        if (sink.img) {
          console.log('loading', sink.img);
          new Image().src = sink.img;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    // Pre-load the color and edge images and sink info
    if (!job.roomInfo) return;
    if (!libraryEndpoint) return;
    if (!filesBucket) return;
    const {
      materialPath,
      edgePath,
      brand: material,
      color,
      materialType,
      edge,
      sinkMoraName
    } = job.roomInfo;
    if (materialPath && material && color) {
      const imgColor = new Image(600, 600);
      imgColor.onerror = () => {
        console.log(`Color Image load FAILED`);
      };
      imgColor.onload = () => {
        console.log(`Color Image load SUCCEEDED`);
        setImgColor(imgColor);
      };
      imgColor.src = `${filesBucket}/Materials/${material}/${color}-Closeup.jpeg`;
      console.log(imgColor.src);

      const imgColorSlab = new Image(600, 600);
      imgColorSlab.onerror = () => {
        console.log(`SLAB Color Image load FAILED`);
      };
      imgColorSlab.onload = () => {
        console.log(`SLAB Color Image load SUCCEEDED`);
        setImgColorSlab(imgColorSlab);
      };
      imgColorSlab.src = `${filesBucket}/Materials/${material}/${color}-Slab.jpeg`;

      const imgEdge = new Image(600, 600);
      imgEdge.onerror = () => {
        console.log(`Edge Image load (from name) FAILED`);
        loadEdgeImg();
      };
      imgEdge.onload = () => {
        console.log(`Edge Image load (from name) SUCCEEDED`);
        setImgEdge(imgEdge);
      };
      setWaitForEdge(false);
      if (edgePath) {
        console.log(edgePath);
        if (edgePath.match(/Edge%20Profiles/)) imgEdge.src = edgePath;
        else imgEdge.src = `${filesBucket}${edgePath}`;
      }
      getEdgeDetails(materialType, edge);

      if (sinkMoraName) {
        loadSinkLibDetails(sinkMoraName);
      }

      loadSinkLibList(materialType);
    } else {
      console.log(`Couldn't retrieve brand, color, or edge`);
    }
  }, [job.roomInfo, libraryEndpoint, filesBucket]);

  function setAddress(address) {
    setJob({ ...job, address });
  }

  function loading() {
    return !job.address && !notFound;
  }

  async function sendMsg(body) {
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });

    return result;
  }

  async function handleConfirm(address) {
    if (test) {
      setJob({ ...job, confirmed: true });
      return;
    }
    setSaving(true);
    const { textOK, comments, verifyOrder } = job;
    const body = {
      type: 'contactInfo',
      body: {
        confirmed: true,
        address,
        textOK,
        comments,
        verifyOrder,
        moreSteps: false,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    setJob({ ...job, address, confirmed: true });
    setSaving(false);
  }

  async function handleConfirmColor() {
    setJob({ ...job, colorConfirmed: true });
    if (test) return;
    console.log('Starting save for color confirmed');
    const body = {
      type: 'color',
      body: {
        confirmed: true,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    console.log('Finished save for color confirmed');
  }

  async function handleDisclosures(disclosureDetails) {
    setJob({ ...job, disclosuresAccepted: true });
    if (test) return;
    console.log('Starting save for disclosures');
    const body = {
      type: 'disclosures',
      body: {
        accepted: true,
        disclosureDetails,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    console.log('Finished save for disclosures');
  }

  async function handleLibraryDisclosure(disclosureDetails) {
    setJob({ ...job, disclosuresDecided: true, disclosureDetails });
    console.log('Starting save for library disclosures');
    const { none, answer, brand, color, disclosure } = disclosureDetails;
    const body = {
      type: 'disclosures',
      body: {
        decided: true,
        disclosureDetails: { none, answer, brand, color, disclosure },
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    console.log('Finished save for disclosures');
  }

  async function handleRendering(renderingDetails) {
    setJob({ ...job, renderingDecided: true, rendering: renderingDetails });
    if (test) return;
    console.log('Starting save for rendering');
    const body = {
      type: 'rendering',
      body: {
        decided: true,
        renderingDetails,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    console.log('Finished save for rendering');
  }

  async function handleConfirmEdge(newEdge = null, contactMe = null) {
    const edgeCall = contactMe;
    setJob({ ...job, edgeCall, edgeConfirmed: true });
    if (newEdge) {
      setEdgeChanged(true);
      setWaitForEdge(true);
    }
    if (test) return;
    console.log('Starting save for edge confirmed');
    const body = {
      type: 'edge',
      body: {
        confirmed: true,
        newEdge,
        contactMe,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    console.log('Finished save for edge confirmed');
  }

  async function handleRooms(newRooms) {
    console.log(newRooms);
    setSelectedRooms(newRooms);
    setJob({ ...job, roomsConfirmed: true });
    if (test) return;
    console.log('Starting save for rooms');
    const body = {
      type: 'rooms',
      body: {
        rooms: newRooms,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    console.log('Finished save for rooms');
  }

  async function handleSplashes(newRooms, tileInstall, postOV) {
    console.log(newRooms);
    setJob({ ...job, splashesConfirmed: true, tileInstall });
    if (test) return;
    console.log('Starting save for splashes');
    setSaving(true);
    const body = {
      type: 'splashes',
      body: {
        rooms: newRooms,
        materialType: job?.roomInfo?.materialType,
        tileInstall,
        postOV,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    setSaving(false);
    console.log('Finished save for splashes');
  }

  async function handleProblemEntered(problemText) {
    setJob({ ...job, problem: true });
    if (test) return;
    console.log('Starting save for problem');
    setSaving(true);
    const body = {
      type: 'problem-new',
      body: {
        problemText,
        agent
      }
    };
    // eslint-disable-next-line
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    setSaving(false);
    console.log('Finished save for problem');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async function handleTemplate(answer, template, details) {
    setJob({ ...job, template, decisionMaker });
    console.log('Starting save for template');
    setSaving(true);
    const body = {
      type: 'template',
      body: {
        answer,
        details,
        decisionMaker,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    setSaving(false);
    console.log('Finished save for template');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // setTimeout(getData, 3000);
  }

  async function handleInstall(answer, install, details) {
    setJob({ ...job, install, decisionMaker });
    console.log('Starting save for install');
    setSaving(true);
    const body = {
      type: 'install',
      body: {
        answer,
        details,
        decisionMaker,
        agent
      }
    };
    const result = await fetch(`${endpoint}/${db}/job-msg/${guid}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
    if (!result.ok) {
      console.log(`handle saving error`);
    }
    setSaving(false);
    console.log('Finished save for install');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // setTimeout(getData, 3000);
  }

  async function handleSinkDetails() {
    // console.log({ sinkInfo });
    sendMsg({
      // intentionally not awaiting!
      type: 'sinks',
      body: {
        sinkInfo,
        agent
      }
    });
    setJob({ ...job, sinkInfo, sinksDecided: true });
    if (sinkInfo.purchased === 'new') {
      const listItem = sinkLibList.find(
        i => i.moraInitialA === sinkInfo.sinkToPurchase
      );
      if (listItem)
        setSinkLibDetails({ ...listItem, mainImageURL: listItem.img });
    }
  }

  async function handleCooktopDetails() {
    sendMsg({
      // intentionally not awaiting!
      type: 'cooktop',
      body: {
        cooktopInfo,
        agent
      }
    });
    setJob({ ...job, cooktopInfo, cooktopDecided: true });
  }

  async function handleCabinetDetails() {
    sendMsg({
      // intentionally not awaiting!
      type: 'cabinets',
      body: {
        cabinetInfo,
        agent
      }
    });
    setJob({ ...job, cabinetInfo, cabinetsConfirmed: true });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!test && waitForEdge) setTimeout(getData, 5000);
  }

  async function handleTearoutDetails() {
    sendMsg({
      // intentionally not awaiting!
      type: 'tearout',
      body: {
        tearoutInfo,
        agent
      }
    });
    setJob({ ...job, tearoutInfo, tearoutConfirmed: true });
  }

  async function handlePlumbingDetails(pi) {
    sendMsg({
      // intentionally not awaiting!
      type: 'plumbing',
      body: {
        plumbingInfo: pi || plumbingInfo,
        agent
      }
    });
    setJob({ ...job, plumbingInfo, plumbingConfirmed: true });
  }

  async function handleQaInfo(qaInfo) {
    sendMsg({
      // intentionally not awaiting!
      type: 'qa',
      body: {
        qaInfo,
        agent
      }
    });
    setJob({ ...job, voc: { formComplete: true } });
  }

  async function handleDigitalLayout(decision) {
    const layoutInfo = {
      decision,
      approved: decision === 'yes'
    };
    await sendMsg({
      // intentionally not awaiting!
      type: 'layout',
      body: {
        layoutInfo,
        agent
      }
    });
    setJob({ ...job, layoutInfo });
  }

  function cooktopFollowUp() {
    return job?.cooktopDecided && job?.cooktopInfo?.arrival === 'date';
  }

  function cabinetFollowUp() {
    return (
      job?.cabinetsConfirmed &&
      (job?.cabinetInfo?.status === 'date' ||
        job?.cabinetInfo?.status === 'not sure')
    );
  }

  function sinkFollowUp() {
    return (
      job?.sinksDecided &&
      (job?.sinkInfo?.arrival === 'date' ||
        job?.sinkInfo?.purchased === 'not yet')
    );
  }

  function countFollowUps() {
    let count = 0;
    if (cooktopFollowUp()) count++;
    if (cabinetFollowUp()) count++;
    if (sinkFollowUp()) count++;
    // console.log('countFollowUps', count);
    return count;
  }

  // console.log(job);
  const value = {
    agent,
    sinkInfo,
    setSinkInfo,
    cooktopInfo,
    setCooktopInfo,
    cabinetInfo,
    setCabinetInfo,
    tearoutInfo,
    setTearoutInfo,
    plumbingInfo,
    setPlumbingInfo,
    decisionMaker,
    setDecisionMaker,
    qaInfo,
    setQaInfo,
    sinkLibDetails,
    sinkLibList,
    sink,
    roomChecks,
    setRoomChecks,
    startingRooms,
    selectedRooms,
    setSelectedRooms,
    otherRoom,
    setOtherRoom,
    handleRooms,
    handleSplashes,
    job,
    setJob,
    notFound,
    saving,
    setAddress,
    imgColor,
    imgColorSlab,
    imgEdge,
    loading,
    handleConfirm,
    handleConfirmColor,
    handleDisclosures,
    handleLibraryDisclosure,
    handleRendering,
    handleConfirmEdge,
    handleProblemEntered,
    handleTemplate,
    handleInstall,
    handleSinkDetails,
    handleCooktopDetails,
    handleCabinetDetails,
    handleTearoutDetails,
    handlePlumbingDetails,
    handleQaInfo,
    handleDigitalLayout,
    edgeDetails,
    edgeChoices,
    edgeChanged,
    waitForEdge,
    missingEdgeImage,
    cooktopFollowUp,
    cabinetFollowUp,
    sinkFollowUp,
    countFollowUps
  };

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  );
};

WizardProvider.propTypes = {
  children: PropTypes.node,
  guid: PropTypes.string
};

export default WizardProvider;

export function useWizardContext() {
  return useContext(WizardContext);
}

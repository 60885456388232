import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import WrapperPlain from './components/WrapperPlain';
import { useNavigate } from 'react-router-dom';

export default function Agent() {
  // State
  const [formData, setFormData] = useState({
    email: '',
    remember: false
  });

  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    localStorage.setItem('agentEmail', formData.email);
    navigate('/');
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleFieldChange = e => {
    // console.log(e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <WrapperPlain>
      <div>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            {<Form.Label>Please enter your email</Form.Label>}
            <Form.Control
              placeholder={'Email address'}
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="email"
            />
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              color="primary"
              className="mt-3 w-100"
              disabled={!formData.email}
            >
              Set Agent Email
            </Button>
          </Form.Group>
        </Form>
      </div>
    </WrapperPlain>
  );
}

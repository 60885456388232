import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorLayout from '../../layouts/ErrorLayout';
import Home from 'si/components/cw/Home';
import WizardLayout from 'si/components/cw/WizardLayout';
import ConfigProvider from 'si/components/cw/ConfigProvider';
import DigitalRenderingExplanation from 'si/components/cw/DigitalRenderingExplanation';
import DisclosureRoutes from 'si/components/cw/disclosure/DisclosureRoutes';
import ChipMinimizerExplanation from 'si/components/cw/ChipMinimizerExplanation';
import Agent from 'si/components/cw/Agent';

const Layout = () => {
  return (
    <ConfigProvider>
      <Routes>
        <Route path="/errors" element={<ErrorLayout />} />
        <Route path="/" exact element={<Home />} />
        <Route path="/wizard/:guid/*" element={<WizardLayout />} />
        <Route
          path="/disclosure/*"
          element={<DisclosureRoutes standalone={true} />}
        />
        <Route
          path="/rendering"
          exact
          element={<DigitalRenderingExplanation wrapped={true} />}
        />
        <Route
          path="/chipminimizer"
          exact
          element={<ChipMinimizerExplanation />}
        />
        <Route path="/agent" exact element={<Agent />} />
        <Route element={<Navigate replace to="/errors/404" />} />
      </Routes>
    </ConfigProvider>
  );
};

export default Layout;

import React, { useState } from 'react';
import finishedIsland from '../../../../assets/img/disclosure/finishedIsland.jpeg';
import DisclosureLayout from './DisclosureLayout';
import PropTypes from 'prop-types';

export default function Dekton({ standalone = false }) {
  const [list] = useState([
    {
      title: 'Finished Front Edge',
      img: '/disclosures/Dekton/dekton edge.jpg'
    },
    {
      title: 'Sink Cut Out',
      img: '/disclosures/Dekton/dekton sink cut out.jpg'
    },
    {
      title: 'Finished Island',
      img: finishedIsland
    }
  ]);
  return (
    <DisclosureLayout
      standalone={standalone}
      title={'Dekton Disclosure'}
      disclosureType={'Dekton'}
      videoId={'x0lRUK-2Zl4'}
      videoFile={'/disclosures/Dekton/Dekton Disclosure Video.mp4'}
      review={
        'Please review the information below regarding your Dekton countertops'
      }
      sliderList={list}
      aboutTitle={`Ultra-Compact Surfaces like Dekton are unique. Because of the manner in which they're manufactured and the manner in which the final countertop must be produced and installed, please consider the following:`}
      aboutList={[
        'The pattern on the surface of the product does not continue through the entire product (as it would with natural stone and most quartz). Because of this, the edge of your countertop and the inside of your sink cutout will not have any of the patterns and will be a solid, base color without substantial variation and lacking pattern entirely.',
        'Seams are required on every inside corner of the countertop (at minimum) to safely fabricate and install the product. Seams will be more noticeable than other products and may feature a slight beveling of the top edge.',
        'While Dekton and other Ultra-Compact Surfaces are highly durable and highly resilient, they are not indestructible.'
      ]}
    />
  );
}

Dekton.propTypes = {
  standalone: PropTypes.bool
};

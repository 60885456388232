import React, { useContext, useEffect } from 'react';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { Button } from 'react-bootstrap';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';

export default function Type() {
  const { sinkInfo, setSinkInfo, job, handleSinkDetails } =
    useContext(WizardContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!job.roomInfo || job.sinkMoraName) {
      navigate('../../');
    } else {
      console.log(sinkInfo);
    }
  }, []);
  const toggleCheck = value => {
    setSinkInfo({ ...sinkInfo, ordered: true, type: value });
  };
  function handleClick() {
    console.log(sinkInfo);
    if (sinkInfo?.type === 'undermount') {
      navigate('../dual-mount');
    } else if (
      sinkInfo?.type === 'undermount farm' &&
      (job?.roomInfo?.materialType === 'Stone' ||
        job?.roomInfo?.materialType === 'Ultracompact') &&
      !job?.chipMinimizerAlready
    ) {
      navigate('../../chipminizer/undermount-stone');
    } else {
      handleSinkDetails();
      navigate('../..');
    }
  }
  return (
    <Wrapper>
      <div>
        <h2 className="fs-0 fw-normal mb-3">
          What type of sink did you order?
        </h2>
        <div className="my-3 p-3 shadow-sm">
          <CustomCheckbox
            name="sinktype"
            type="radio"
            label="Undermount sink"
            value="undermount"
            checked={sinkInfo.type === 'undermount'}
            toggleCheck={toggleCheck}
          />
          <img
            className="img-fluid"
            alt="Undermount sink"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fundermount.jpeg"
          />
        </div>
        <div className="my-3 p-3 shadow-sm">
          <CustomCheckbox
            name="sinktype"
            type="radio"
            label="Undermount farm sink"
            value="undermount farm"
            checked={sinkInfo.type === 'undermount farm'}
            toggleCheck={toggleCheck}
          />
          <img
            className="img-fluid"
            alt="Undermount farm sink"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fundermount-farm.jpeg"
          />
        </div>
        <div className="my-3 p-3 shadow-sm">
          <CustomCheckbox
            name="sinktype"
            type="radio"
            label="Topmount sink"
            value="topmount"
            checked={sinkInfo.type === 'topmount'}
            toggleCheck={toggleCheck}
          />
          <img
            className="img-fluid"
            alt="Topmount sink"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Ftopmount.jpeg"
          />
        </div>
        <div className="my-3 p-3 shadow-sm">
          <CustomCheckbox
            name="sinktype"
            type="radio"
            label="Topmount farm sink"
            value="topmount farm"
            checked={sinkInfo.type === 'topmount farm'}
            toggleCheck={toggleCheck}
          />
          <img
            className="img-fluid"
            alt="Topmount farm sink"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Ftopmount-farm.jpeg"
          />
        </div>
        <div className="my-3 p-3 shadow-sm">
          <CustomCheckbox
            name="sinktype"
            type="radio"
            label="Vessel sink"
            value="vessel"
            checked={sinkInfo.type === 'vessel'}
            toggleCheck={toggleCheck}
          />
          <img
            className="img-fluid"
            alt="Vessel sink"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fvessel.jpg"
          />
        </div>
      </div>
      <Button
        className="w-100"
        onClick={handleClick}
        disabled={!sinkInfo?.type}
      >
        Continue
      </Button>
    </Wrapper>
  );
}

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddSink from './AddSink';
import ConfirmModel from './ConfirmModel';
import DualMount from './DualMount';
import Existing from './Existing';
import SinkCall from './SinkCall';
import TopMount from './TopMount';
import Type from './Type';
import Undermount from './Undermount';
import Unknown from './Unknown';
import Inspect from './Inspect';
import SinkPurchased from './SinkPurchased';
import SinkSelector from '../components/SinkSelector';

export default function SinkRoute() {
  return (
    <Routes>
      <Route path="/confirm" element={<ConfirmModel />} />
      <Route path="/undermount" element={<Undermount />} />
      <Route path="/unknown" element={<Unknown />} />
      <Route path="/type" element={<Type />} />
      <Route path="/inspect" element={<Inspect />} />
      <Route path="/dual-mount" element={<DualMount />} />
      <Route path="/sink-call" element={<SinkCall />} />
      <Route path="/existing" element={<Existing />} />
      <Route path="/topmount" element={<TopMount />} />
      <Route path="/add-sink" element={<AddSink />} />
      <Route path="/purchased" element={<SinkPurchased />} />
      <Route path="/choose" element={<SinkSelector />} />
    </Routes>
  );
}

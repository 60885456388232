import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WizardContext } from 'context/Context';
import { showCooktop } from '../WizardHome';
import { shortDate } from 'si/helpers/shortDate';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CooktopSummary = ({ job }) => {
  const { cooktopInfo } = job;
  const { selectedRooms, cooktopFollowUp, sinkFollowUp } =
    useContext(WizardContext);
  const navigate = useNavigate();
  // console.log({ roomInfo, sinkLibDetails, cooktopInfo });
  return (
    job &&
    showCooktop(job, selectedRooms) && (
      <>
        {cooktopFollowUp() && (
          <>
            {!sinkFollowUp() && <hr style={{ border: '1px solid #bbbbbb' }} />}
            <h3 id="confirm-cooktop">Update Cooktop/Range</h3>
            <p>
              Has your cooktop/range arrived?
              <br />
              <Button
                onClick={() => {
                  navigate('./cooktop');
                }}
              >
                Update cooktop/range status ...
              </Button>
            </p>{' '}
            <hr style={{ border: '1px solid #bbbbbb' }} />
          </>
        )}
        {!cooktopFollowUp() && (
          <>
            <hr />
            <h5>Cooktop/range</h5>
            {cooktopInfo?.exists === 'no' && (
              <p>(no cooktop/range in your countertops)</p>
            )}
            {cooktopInfo?.exists === 'yes' && (
              <>
                <p>
                  You have a cooktop or range
                  {cooktopInfo?.arrival === 'date'
                    ? ' that is expected to arrive approximately ' +
                      shortDate(cooktopInfo?.arrivalDate)
                    : cooktopInfo?.arrival === 'on site' &&
                      ' that is already on site'}
                  .
                </p>
              </>
            )}
          </>
        )}
      </>
    )
  );
};

CooktopSummary.propTypes = {
  job: PropTypes.object
};

export default CooktopSummary;

import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from './components/CustomCheckbox';
import Wrapper from './components/Wrapper';
import { WizardContext } from 'context/Context';
import DigitalRenderingExplanation from './DigitalRenderingExplanation';
import { prices } from 'prices';

export default function DigitalRendering() {
  const navigate = useNavigate();
  const [radioButton, setRadioButton] = useState('');
  const [error, setError] = useState(false);

  const { job, handleRendering } = useContext(WizardContext);

  const toggleCheck = value => {
    setRadioButton(value);
    setError(false);
  };

  const next = () => {
    if (!radioButton) {
      setError(true);
    } else {
      handleRendering({ decision: radioButton });
      navigate('../edge');
    }
  };

  useEffect(() => {
    if (!job.roomInfo) {
      navigate('../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [job]);

  return (
    <Wrapper>
      <DigitalRenderingExplanation />
      <p>
        Do you want to purchase your digital rendering for approval before
        production ({prices.digitalRendering})?
      </p>
      <div className="mt-3">
        <CustomCheckbox
          name="radiochip"
          type="radio"
          label="Yes"
          value="yes"
          checked={radioButton === 'yes'}
          toggleCheck={toggleCheck}
        ></CustomCheckbox>
        <CustomCheckbox
          name="radiochip"
          type="radio"
          label="No"
          value="no"
          checked={radioButton === 'no'}
          toggleCheck={toggleCheck}
        ></CustomCheckbox>
        <CustomCheckbox
          name="radiochip"
          type="radio"
          label="I'm not sure"
          value="notsure"
          checked={radioButton === 'notsure'}
          toggleCheck={toggleCheck}
        ></CustomCheckbox>
        {radioButton === 'notsure' && (
          <p>We will discuss with you when your technician comes to measure.</p>
        )}
      </div>
      {error && <p className="text-danger pt-2">Please make a selection.</p>}
      <Button className="w-100 my-3" variant="primary" onClick={next}>
        Continue
      </Button>
    </Wrapper>
  );
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import { Route, Routes } from 'react-router-dom';
import '../../../../assets/scss/wizard/disclosure.scss';
import ButcherBlock from './ButcherBlock';
import Dekton from './Dekton';
import Marble from './Marble';
import Quartzite from './Quartzite';
import Soapstone from './Soapstone';
import SolidSurfaceDirectional from './SolidSurfaceDirectional';
import PropTypes from 'prop-types';
import LibraryDisclosure from './LibraryDisclosure';

export default function DisclosureRoutes({ standalone = false }) {
  const routes = (
    <Routes>
      <Route path="/dekton" element={<Dekton standalone={standalone} />} />
      <Route
        path="/butcher-block"
        element={<ButcherBlock standalone={standalone} />}
      />
      <Route path="/marble" element={<Marble standalone={standalone} />} />
      <Route
        path="/quartzite"
        element={<Quartzite standalone={standalone} />}
      />
      <Route
        path="/soapstone"
        element={<Soapstone standalone={standalone} />}
      />
      <Route
        path="/solid-surface-directional"
        element={<SolidSurfaceDirectional standalone={standalone} />}
      />
      <Route
        path="/:brand/:color"
        element={<LibraryDisclosure standalone={standalone} />}
      />
    </Routes>
  );

  return standalone ? (
    <Section className="py-0" style={{ minHeight: 'calc(100vh - 130px)' }}>
      <Row className="flex-center p-0">
        <Col sm={10} lg={7} className="col-xxl-5">
          {routes}
        </Col>
      </Row>
    </Section>
  ) : (
    routes
  );
}

DisclosureRoutes.propTypes = {
  standalone: PropTypes.bool
};

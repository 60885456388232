import React, { useState } from 'react';
import DisclosureLayout from './DisclosureLayout';
import PropTypes from 'prop-types';

export default function SolidSurfaceDirectional({ standalone = false }) {
  const [list] = useState([
    {
      title: 'Slab Color Variation',
      key: 'Color',
      keyValue: 'Dune Prima',
      img: '/disclosures/Solid Surface/solid surface directional.jpg'
    },
    {
      title: 'Seam Direction',
      key: 'Color',
      keyValue: 'Dune Prima',
      img: '/disclosures/Solid Surface/solid surface seam.jpg'
    },
    {
      title: 'Slab Color Variation',
      key: 'Color',
      keyValue: 'Dune Prima',
      img: '/disclosures/Solid Surface/directional solid surface seam.jpg'
    },
    {
      title: 'Seam Direction',
      key: 'Color',
      keyValue: 'Dune Prima',
      img: '/disclosures/Solid Surface/solid surface directional 4.jpeg'
    }
  ]);
  return (
    <DisclosureLayout
      standalone={standalone}
      title={'Solid Surface Disclosure'}
      disclosureType={'Solid Surface Directional'}
      videoId={'GbMwSV9Esk4'}
      videoFile={
        '/disclosures/Solid Surface/Solid Surface Directional Disclosure Video.mp4'
      }
      review={
        'Please review the information below regarding your directional solid surface countertops'
      }
      sliderList={list}
      aboutTitle={'Aesthetic Considerations'}
      aboutSubTitle={
        'Solid Surface products have characteristics that require specific fabrication methods:'
      }
      aboutList={[
        'Random directional veining that ranges from subtle to bold',
        'The veining pattern changes through the thickness',
        'The edge of the sheet has a different appearance than the top',
        'The overall shade may change slightly through the thickness',
        'There will be a visual break in the patterns at seams',
        'Cutting into the sheet will reveal a different pattern; this causes problems at coved backsplashes, engraved drain boards, etc.',
        'Edge detail: the veined colors have random directional veining that ranges from subtle to bold. The layering of pattern variation may show in the edge detail',
        'See picture for examples of how the veining looks at seams and on the coved backsplash'
      ]}
    />
  );
}

SolidSurfaceDirectional.propTypes = {
  standalone: PropTypes.bool
};

import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
function CustomCheckbox({
  name,
  type = 'checkbox',
  label,
  checked = false,
  toggleCheck,
  value
}) {
  return (
    <Form.Check
      type={type}
      id={label}
      name={name}
      label={label}
      checked={checked}
      onChange={() => toggleCheck(value, label)}
    />
  );
}

CustomCheckbox.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.node,
  checked: PropTypes.bool,
  toggleCheck: PropTypes.func
};
export default CustomCheckbox;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WizardContext } from 'context/Context';
import { isTopmount } from '../cooktop/Range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shortDate } from 'si/helpers/shortDate';
import { prices } from 'prices';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ConfirmSinkDetails from './ConfirmSinkDetails';

export const ConfirmedSink = ({ job, textOnly = false }) => {
  const { roomInfo } = job;
  const { sinkLibDetails } = useContext(WizardContext);
  if (textOnly)
    return sinkLibDetails ? (
      <>
        {sinkLibDetails?.brandFullName || sinkLibDetails?.brandName}{' '}
        {sinkLibDetails?.customerName}{' '}
        {sinkLibDetails?.price && `($${sinkLibDetails?.price})`}
      </>
    ) : (
      <>{roomInfo?.sinkMoraName}</>
    );
  else
    return (
      <>
        {sinkLibDetails ? (
          <>
            <p>
              Brand:{' '}
              <strong>
                {sinkLibDetails?.brandFullName || sinkLibDetails?.brandName}
              </strong>
            </p>
            <p>
              Model: <strong>{sinkLibDetails?.customerName}</strong>
              {sinkLibDetails?.price && ` ($${sinkLibDetails?.price})`}
            </p>
            {sinkLibDetails?.mainImageURL && (
              <>
                <Row className="flex-center pt-1">
                  <img
                    // style={{ width: '400px' }}
                    alt={`${sinkLibDetails?.moraName}`}
                    src={sinkLibDetails?.mainImageURL}
                  />
                </Row>
              </>
            )}
          </>
        ) : roomInfo?.sinkMoraName ? (
          <p>{roomInfo?.sinkMoraName}</p>
        ) : (
          <p>{job?.sinkInfo?.sinkName}</p>
        )}
      </>
    );
};
ConfirmedSink.propTypes = {
  job: PropTypes.object,
  textOnly: PropTypes.bool
};

const SinkShopping = ({ materialType }) => {
  return (
    <>
      <p>
        In the meantime, you can browse the link
        {materialType === 'Solid Surface' ? 's' : ''} below to get more
        information about sinks we carry that will work well for your
        countertops.
      </p>
      {(materialType === 'Stone' || materialType === 'Ultracompact') && (
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://precisioncountertops.com/sink-products-stone-solid-surface/"
            >
              Sinks for stone countertops{' '}
              <FontAwesomeIcon icon="external-link-alt" className="fs--1" />
            </a>
          </li>
        </ul>
      )}
      {materialType === 'Solid Surface' && (
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://precisioncountertops.com/sink-products-solid-surface/"
            >
              Integral solid surface sinks{' '}
              <FontAwesomeIcon icon="external-link-alt" className="fs--1" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://precisioncountertops.com/sink-products-stone-solid-surface/"
            >
              Additional sinks for solid surface countertops{' '}
              <FontAwesomeIcon icon="external-link-alt" className="fs--1" />
            </a>
          </li>
        </ul>
      )}
      {materialType === 'Laminate' && (
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://precisioncountertops.com/sink-products-laminate/"
            >
              Sinks for laminate countertops{' '}
              <FontAwesomeIcon icon="external-link-alt" className="fs--1" />
            </a>
          </li>
        </ul>
      )}
    </>
  );
};
SinkShopping.propTypes = {
  materialType: PropTypes.string
};

const SinkSummary = ({ job }) => {
  const { roomInfo } = job;
  // const { sinkLibDetails } = useContext(WizardContext);
  // console.log({ roomInfo, sinkLibDetails, sinkInfo });
  // these 2 functions are DUPLICATED!
  function sinkNotPurchased() {
    return job?.sinkInfo?.purchased === 'not yet';
  }

  function sinkOnsite() {
    return (
      job?.sinkInfo?.purchased !== 'yes' ||
      (job?.sinkInfo?.purchased === 'yes' &&
        job?.sinkInfo?.arrival === 'on site')
    );
  }

  return !job?.sinkInfo ? null : sinkNotPurchased() || !sinkOnsite() ? (
    <ConfirmSinkDetails />
  ) : (
    <>
      <hr />
      <h5>Sink</h5>
      {/* {job?.sinkInfo?.purchased === 'new' && (
        <p style={{ fontStyle: 'italic' }}>
          I'll handle payment later for this new sink.
        </p>
      )} */}
      {(job?.sinkInfo?.confirmExisting === 'yes' ||
        job?.sinkInfo?.purchased === 'new') && <ConfirmedSink job={job} />}
      {(job?.sinkInfo?.confirmExisting === 'no' ||
        job?.sinkInfo?.purchased === 'not yet') && (
        <>
          <p style={{ fontStyle: 'italic' }}>
            I'll call you soon to discuss sink options.
          </p>
          <SinkShopping materialType={roomInfo?.materialType} />
        </>
      )}
      {job?.sinkInfo?.purchased === 'no sink' && (
        <p>(no sink in your countertops)</p>
      )}
      {job?.sinkInfo?.purchased === 'old' && (
        <>
          <p>You plan to reuse your existing sink.</p>
          <p>
            We know it often seems like a good idea to try to re-install your
            old sink, but from our experience, the old sink is rarely in good
            condition. Then the project gets delayed at the last minute, and
            there is a {prices.secondTrip} fee if the team has to make a second
            trip.{' '}
            <strong>
              We strongly recommend that you purchase a new sink for your new
              countertop.
            </strong>
          </p>
          <p>
            Please text me back if you change your mind or if you'd like to
            discuss further.
          </p>
        </>
      )}
      {job?.sinkInfo?.purchased === 'yes' && (
        <>
          <p>
            You have purchased a new{' '}
            <strong>
              {job?.sinkInfo?.dualMount === 'not sure'
                ? ''
                : job?.sinkInfo?.dualMount === 'purchased'
                ? 'dual mount'
                : job?.sinkInfo?.type}
            </strong>{' '}
            sink{' '}
            {job?.sinkInfo?.dualMount === 'purchased' &&
              '(which will be installed as topmount)'}
            {job?.sinkInfo?.arrival === 'date'
              ? ' that is expected to arrive approximately ' +
                shortDate(job?.sinkInfo?.arrivalDate)
              : job?.sinkInfo?.arrival === 'on site' &&
                ' that is already on site'}
            .
          </p>
          {job?.sinkInfo?.dualMount === 'purchased' && (
            <img
              className="img-fluid"
              alt="Dual mount sink"
              src="https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fdualmount-top.png"
            />
          )}
          {job?.sinkInfo?.type.match(/farm/) && (
            <p>
              Since you are supplying a farm sink, it needs to be resting in
              place but not glued down when our technician comes to measure.
            </p>
          )}
          {isTopmount(job?.sinkInfo) && (
            <p style={{ fontStyle: 'italic' }}>
              Note: our installers aren’t allowed to caulk the sink once it’s in
              place, so make sure the person doing the plumbing takes care of
              that step.
            </p>
          )}
          {job?.sinkInfo?.dualMount === 'not sure' && (
            <p>
              I will call you to determine whether your sink is a dual mount
              sink.
              <img
                className="img-fluid"
                alt="Vessel sink"
                src="https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fdualmount.png"
              />
            </p>
          )}
          <p>
            Reminder: when your new sink arrives, be sure to unpack it and{' '}
            <strong>inspect it thoroughly</strong> for any visible defects or
            damage. Look for cracks, scratches, dents, or chips on the surface.
          </p>
          <p>
            Also, <strong>measure the sink</strong>, including its width, depth,
            and height. Compare these measurements to the cabinet sink box
            dimensions to confirm a proper fit in the cabinet's interior space.
          </p>
        </>
      )}
      {['yes', 'no', 'notsure'].includes(job?.sinkInfo?.chipMinimizer) && (
        <>
          <p className="mb-1">
            <strong>Chip Minimizer</strong>
          </p>
          {job?.sinkInfo?.chipMinimizer === 'yes' && (
            <p>
              You have purchased the{' '}
              <Link to={'/chipminimizer'}>Chip Minimizer</Link> (
              {prices.chipMinimizer})
            </p>
          )}
          {job?.sinkInfo?.chipMinimizer === 'no' && (
            <p>
              You declined the <Link to={'/chipminimizer'}>Chip Minimizer</Link>{' '}
              ({prices.chipMinimizer}).
            </p>
          )}
          {job?.sinkInfo?.chipMinimizer === 'notsure' && (
            <p>
              I will call you to discuss the{' '}
              <Link to={'/chipminimizer'}>Chip Minimizer</Link>.
            </p>
          )}
        </>
      )}
    </>
  );
};

SinkSummary.propTypes = {
  job: PropTypes.object
};

export default SinkSummary;

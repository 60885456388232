import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
export default function BackButton() {
  const navigate = useNavigate();
  return (
    <Button className="rounded-circle py-2 px-3" onClick={() => navigate(-1)}>
      <FontAwesomeIcon icon="fa-solid fa-angle-left" />
    </Button>
  );
}

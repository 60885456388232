import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
function SliderComponent({ list, style }) {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 2
  };
  return (
    <Slider {...settings}>
      {list.map((item, index) => (
        <div key={index}>
          <h2 className="fs-0 fw-normal">{item.title}</h2>
          <p className="text-black fs--1">
            {item?.key} {item?.keyValue}
          </p>
          <div className="test">
            <img src={item.img} className={style} />
          </div>
        </div>
      ))}
    </Slider>
  );
}
SliderComponent.propTypes = {
  list: PropTypes.array,
  style: PropTypes.string
};

export default SliderComponent;

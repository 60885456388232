import React, { Fragment } from 'react';
import { Spinner, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Loading = ({ msg = 'Retrieving personalized data ...' }) => {
  return (
    <Fragment>
      <div className="spinner-wrapper-home">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>

      <Row className="flex-center">{msg}</Row>
    </Fragment>
  );
};

Loading.propTypes = {
  msg: PropTypes.string
};

export default Loading;

import React from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Wrapper from './components/Wrapper';
import { previousRoute } from './helpers/routes';

export default function DuplicateStep() {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  return (
    <Wrapper>
      <p>This is a placeholder. We will have to repeat for other rooms.</p>
      <Button
        className="w-100 my-3"
        onClick={() => navigate(previousRoute(pathname, 1) + '/appliances')}
      >
        Continue
      </Button>
    </Wrapper>
  );
}

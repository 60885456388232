import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row } from 'react-bootstrap';
import CustomCheckbox from '../components/CustomCheckbox';

const Confirm = ({ register, errors, startingRooms, job }) => {
  const { contactName, addressLine1, city, state, zip, email, phone } =
    job.address;

  const [otherRoom, setOtherRoom] = useState(false);
  useEffect(() => {}, [otherRoom]);
  return (
    <>
      <WizardInput
        label="Name"
        name="name"
        defaultValue={contactName}
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('name')
        }}
      />
      <WizardInput
        label="Job Site Address"
        name="address"
        defaultValue={addressLine1}
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('address')
        }}
      />
      <WizardInput
        label="City"
        name="city"
        defaultValue={city}
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('city')
        }}
      />
      <Row className="g-2 mb-3">
        <WizardInput
          errors={errors}
          label="State"
          defaultValue={state}
          name="state"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('state')
          }}
        />
        <WizardInput
          errors={errors}
          label="Zip"
          name="zip"
          defaultValue={zip}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('zip')
          }}
        />
      </Row>
      <WizardInput
        type="email"
        errors={errors}
        label="Email"
        name="email"
        defaultValue={email}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('email', {
            required: 'Email field is required'
          })
        }}
      />
      <WizardInput
        label="Cell Phone"
        name="phone"
        errors={errors}
        defaultValue={phone}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('phone')
        }}
      />
      <WizardInput
        type="checkbox"
        errors={errors}
        label={<>It's OK to text this number</>}
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: 'You need to agree the terms and privacy policy.'
          })
        }}
      />
      <div className="border-top w-100"></div>
      <div className="fs--1 my-3">
        In which area or areas are you having countertops installed?<br></br>
        (Select all that apply)
      </div>
      {startingRooms.map((item, index) => (
        <div key={index}>
          {item != 'Other' ? (
            <WizardInput
              type="checkbox"
              errors={errors}
              label={<>{item}</>}
              name={item}
              formControlProps={{
                ...register(item)
              }}
            />
          ) : (
            <div>
              <CustomCheckbox
                name={'other-room'}
                label={<div className="ms-2">Other</div>}
                value={otherRoom}
                checked={otherRoom}
                toggleCheck={e => setOtherRoom(!e)}
              />
              {otherRoom && (
                <WizardInput
                  label="Describe Other Areas"
                  name="otherRoom"
                  errors={errors}
                  formGroupProps={{ className: 'mb-3' }}
                  formControlProps={{
                    ...register('otherRoom', {
                      required: 'Please describe your other areas'
                    })
                  }}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

Confirm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  startingRooms: PropTypes.array,
  job: PropTypes.any
};

export default Confirm;

import React, { useContext, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';

const Cabinets = () => {
  const navigate = useNavigate();
  const { cabinetInfo, setCabinetInfo, job } = useContext(WizardContext);

  useEffect(() => {
    if (!job.roomInfo || job.cabinetsConfirmed) {
      navigate('../..');
    }
  }, []);
  const toggleCheck = value => {
    setCabinetInfo({ ...cabinetInfo, type: value });
  };
  const next = () => {
    if (cabinetInfo.type === 'new' || cabinetInfo.type === 'new and old') {
      navigate('new');
    } else {
      navigate('existing');
    }
  };

  return (
    <Wrapper>
      <div>
        <p>
          Will we be installing your countertops on your existing cabinets, or
          are you getting new cabinets?
        </p>
        <CustomCheckbox
          name="cabinets"
          type="radio"
          label="New cabinets"
          value="new"
          toggleCheck={toggleCheck}
          checked={cabinetInfo?.type === 'new'}
        />
        <CustomCheckbox
          name="cabinets"
          type="radio"
          label="Existing cabinets"
          value="old"
          toggleCheck={toggleCheck}
          checked={cabinetInfo?.type === 'old'}
        />
        <CustomCheckbox
          name="cabinets"
          type="radio"
          label="A mix of new and existing cabinets"
          value="new and old"
          toggleCheck={toggleCheck}
          checked={cabinetInfo?.type === 'new and old'}
        />
      </div>
      <Button
        className="w-100 my-3"
        variant="primary"
        onClick={next}
        disabled={!cabinetInfo?.type}
      >
        Continue
      </Button>
    </Wrapper>
  );
};

export default Cabinets;

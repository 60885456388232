import React from 'react';
import PropTypes from 'prop-types';
function Video2({ file, autoPlay, vertical }) {
  return autoPlay ? (
    <div className="video-responsive my-3">
      <iframe
        src={file}
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      ></iframe>
    </div>
  ) : vertical ? (
    <div className="my-3">
      <video
        src={file}
        title="Video Player"
        frameBorder="0"
        allowFullScreen={true}
        controls={true}
        width={'100%'}
        height={'400px'}
        preload="auto"
      ></video>
    </div>
  ) : (
    <div className="video-responsive my-3">
      <video
        src={file}
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        controls={true}
        width={'100%'}
        height={'auto'}
      ></video>
    </div>
  );
}
Video2.propTypes = {
  file: PropTypes.string,
  autoPlay: PropTypes.bool,
  vertical: PropTypes.bool
};
export default Video2;

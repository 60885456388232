import React, { useContext, useEffect, useState } from 'react';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { WizardContext } from 'context/Context';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isoShortDate } from 'si/helpers/isoShortDate';

export const msWeek = 1000 * 60 * 60 * 24 * 7;

export default function Unknown() {
  const { sinkInfo, setSinkInfo, job, handleSinkDetails } =
    useContext(WizardContext);
  const [dateVal, setDateVal] = useState('');
  const [weeks, setWeeks] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    if (!job.roomInfo || job.sinkMoraName) {
      navigate('../../');
    }
    setDateVal(sinkInfo?.arrivalDate || '');
    // console.log('sinkInfo', sinkInfo);
    // console.log('job.sinkInfo', job.sinkInfo);
  }, []);

  const toggleCheck = value => {
    if (value === 'yes') setSinkInfo({ ...sinkInfo, purchased: value });
    else
      setSinkInfo({
        ...sinkInfo,
        purchased: value,
        arrival: null,
        arrivalDate: null
      });
  };
  const arrivalData = (value, date = null) => {
    setSinkInfo({ ...sinkInfo, arrival: value, arrivalDate: date });
  };
  function next() {
    if (sinkInfo?.purchased === 'yes') {
      navigate('../inspect');
    } else if (sinkInfo?.purchased === 'not yet') {
      navigate('../add-sink');
    } else if (sinkInfo?.purchased === 'old') {
      navigate('../existing');
    } else {
      handleSinkDetails();
      navigate('../..');
    }
  }
  const arrivalWeeks = e => {
    const value = e.target.value;
    setWeeks(value);
    const date = new Date().getTime() + msWeek * value;
    const isoDate = isoShortDate(new Date(date));
    setDateVal(isoDate);
    setSinkInfo({ ...sinkInfo, arrival: 'date', arrivalDate: isoDate });
  };

  return (
    <Wrapper>
      <div>
        <h2 className="fs-0">
          We didn’t receive details about your sink. Did you already order a new
          sink?
        </h2>
        <CustomCheckbox
          name="purchased"
          type="radio"
          label="Yes"
          value="yes"
          checked={sinkInfo?.purchased === 'yes'}
          toggleCheck={toggleCheck}
        />
        <CustomCheckbox
          name="purchased"
          type="radio"
          label="No, I still need to purchase a sink."
          value="not yet"
          checked={sinkInfo?.purchased === 'not yet'}
          toggleCheck={toggleCheck}
        />
        <CustomCheckbox
          name="purchased"
          type="radio"
          label="No, I’m planning to use my old sink."
          value="old"
          checked={sinkInfo?.purchased === 'old'}
          toggleCheck={toggleCheck}
        />
        <CustomCheckbox
          name="purchased"
          type="radio"
          label="No, the countertops in this room don't have a sink."
          value="no sink"
          checked={sinkInfo?.purchased === 'no sink'}
          toggleCheck={toggleCheck}
        />
      </div>
      {sinkInfo?.purchased === 'yes' && (
        <div className="mt-5">
          <h2 className="fs-0">Great. When do you expect it to arrive?</h2>
          <Form>
            <CustomCheckbox
              name="arrival"
              type="radio"
              label="It’s already on site"
              value="on site"
              checked={sinkInfo?.arrival === 'on site'}
              toggleCheck={arrivalData}
            />
            <Form.Check
              type="radio"
              id="date"
              name="arrival"
              label={
                <>
                  Approximately
                  <p className="mx-3 my-2">
                    <input
                      type={'number'}
                      min={1}
                      max={30}
                      value={weeks}
                      onChange={arrivalWeeks}
                    />{' '}
                    week(s)
                  </p>
                  <p className="mx-4 my-2">or</p>
                  <p className="mx-3 my-0">
                    <input
                      type={'date'}
                      value={dateVal}
                      onChange={e => {
                        setDateVal(e.target.value);
                        arrivalData('date', e.target.value);
                      }}
                    />
                  </p>
                </>
              }
              checked={sinkInfo?.arrival === 'date'}
              onChange={() => arrivalData('date', dateVal)}
            />
          </Form>
        </div>
      )}
      <Button
        className="w-100"
        onClick={next}
        disabled={
          !sinkInfo?.purchased ||
          (sinkInfo?.purchased === 'yes' &&
            (!sinkInfo?.arrival ||
              (sinkInfo?.arrival === 'date' && !sinkInfo?.arrivalDate)))
        }
      >
        Continue
      </Button>
    </Wrapper>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { WizardContext } from 'context/Context';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { isoShortDate } from 'si/helpers/isoShortDate';

const msWeek = 1000 * 60 * 60 * 24 * 7;

const NewCabinets = () => {
  const navigate = useNavigate();
  const [dateVal, setDateVal] = useState('');
  const [weeks, setWeeks] = useState('');
  const {
    cabinetInfo,
    setCabinetInfo,
    job,
    handleCabinetDetails,
    cabinetFollowUp
  } = useContext(WizardContext);

  useEffect(() => {
    if (!cabinetFollowUp() && (!job.roomInfo || job.cabinetsConfirmed)) {
      navigate('../..');
    }
  }, []);
  const toggleCheck = value => {
    setCabinetInfo({
      ...cabinetInfo,
      status: value,
      type: cabinetInfo?.type || job?.cabinetInfo?.type,
      installDate: undefined
    });
  };
  const arrivalDate = value => {
    setDateVal(value);
    setCabinetInfo({
      ...cabinetInfo,
      status: 'date',
      type: cabinetInfo?.type || job?.cabinetInfo?.type,
      installDate: value
    });
  };
  const arrivalWeeks = e => {
    const value = e.target.value;
    setWeeks(value);
    const date = new Date().getTime() + msWeek * value;
    const isoDate = isoShortDate(new Date(date));
    setDateVal(isoDate);
    setCabinetInfo({
      ...cabinetInfo,
      status: 'date',
      type: cabinetInfo?.type || job?.cabinetInfo?.type,
      installDate: isoDate
    });
  };
  const next = () => {
    if (cabinetInfo?.type === 'new and old') {
      navigate('../existing');
    } else {
      handleCabinetDetails();
      navigate('../..');
    }
  };

  return (
    <Wrapper>
      <div>
        {cabinetFollowUp() ? (
          <p>Have your new cabinets been installed yet?</p>
        ) : (
          <p>Fantastic. When will your new cabinets be installed?</p>
        )}
        <CustomCheckbox
          name="New Cabinets"
          type="radio"
          label={
            cabinetFollowUp()
              ? `Yes, they're installed`
              : 'They’re already installed'
          }
          value="installed"
          toggleCheck={toggleCheck}
          checked={cabinetInfo?.status === 'installed'}
        ></CustomCheckbox>
        <Form.Check
          name="New Cabinets"
          type="radio"
          id="date"
          label={
            <>
              {cabinetFollowUp()
                ? 'No, we expect them to be installed approximately'
                : 'Approximately'}
              <p className="mx-3 my-2">
                <input
                  type={'number'}
                  min={1}
                  max={30}
                  value={weeks}
                  onChange={arrivalWeeks}
                />{' '}
                week(s)
              </p>
              <p className="mx-4 my-2">or</p>
              <p className="mx-3 my-0">
                <input
                  type={'date'}
                  value={dateVal}
                  onChange={e => {
                    arrivalDate(e.target.value);
                  }}
                />
              </p>
            </>
          }
          checked={cabinetInfo?.status === 'date'}
          onChange={() => toggleCheck('date')}
        />
        <CustomCheckbox
          name="New Cabinets"
          type="radio"
          label="I'm not sure"
          value="not sure"
          toggleCheck={toggleCheck}
          checked={cabinetInfo?.status === 'not sure'}
        ></CustomCheckbox>
        {cabinetInfo?.status === 'not sure' && (
          <p>
            If you're not sure, I'll check in with you every couple of weeks. We
            won't be able to schedule your digital template until your new
            cabinets are installed.
          </p>
        )}
      </div>
      <Button
        className="w-100 my-3"
        variant="primary"
        onClick={next}
        disabled={!cabinetInfo?.status}
      >
        Continue
      </Button>
    </Wrapper>
  );
};
export default NewCabinets;

import React, { useContext, useEffect, useState } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Button, Alert } from 'react-bootstrap';
import Wrapper from './components/Wrapper';
import Video2 from './components/Video2';
import { prices } from 'prices';

function TileInstall() {
  const { job, selectedRooms, handleSplashes } = useContext(WizardContext);
  const navigate = useNavigate();
  const [decision, setDecision] = useState('');

  useEffect(() => {
    if (!job.roomInfo) {
      navigate('../');
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, []);

  return job.tileZip ? (
    <Wrapper>
      <div className="container">
        <h4>Tile Backsplash Services</h4>
        <Row>
          <p>Have you already purchased your tile?</p>
          <p>
            If so, we can install the tile you've purchased on your own for an
            install fee of {prices.tileInstallation}.
          </p>
          <p>
            If not, we also offer a Deluxe Tile Package that includes 3" by 6"
            tile for {prices.tileDeluxe}
          </p>
          <p>
            We’ll also tear out and dispose of any old backsplash, as well as
            repair any drywall and prep the area for proper installation.
          </p>
        </Row>
        <Row className="flex-center pt-0 pb-3">
          <Video2 file="/tileinstall/TileClips.mp4" autoPlay={true} />
        </Row>

        <Row>
          <p>
            Would you like Precision to install the tile you've purchased (
            {prices.tileInstallation}) or provide tile ({prices.tileDeluxe})?
          </p>
          <Alert variant="secondary">
            Any tile you've purchased must be onsite when our installers arrive.
          </Alert>
        </Row>

        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="yes"
              label="Yes, I would like Precision to install my tile backsplash"
              checked={decision === 'yes'}
              onChange={() => {
                setDecision('yes');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="no"
              label="No, I've made other arrangements"
              checked={decision === 'no'}
              onChange={() => {
                setDecision('no');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="maybe"
              label="I'm not sure - let's discuss when you come to measure"
              checked={decision === 'maybe'}
              onChange={() => {
                setDecision('maybe');
              }}
            />
          </Row>
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            handleSplashes(selectedRooms, decision);
            navigate('../');
          }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  ) : (
    <Wrapper>
      <div className="container">
        <p>
          It looks as though you're installing the tile backsplash yourself, or
          you've made other installation plans. Is that correct?
        </p>
        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="self"
              label="Yes"
              checked={decision === 'self'}
              onChange={() => {
                setDecision('self');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="call"
              label="No, please call me later to discuss"
              checked={decision === 'call'}
              onChange={() => {
                setDecision('call');
              }}
            />
          </Row>
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            handleSplashes(selectedRooms, decision);
            navigate('../');
          }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  );
}

export default TileInstall;

import React from 'react';
// import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// import { WizardContext } from 'context/Context';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useWizardContext } from '../WizardProvider';

const CabinetSummary = ({ job }) => {
  const { cabinetInfo } = job;
  const navigate = useNavigate();
  const { cabinetFollowUp } = useWizardContext();
  // const { sinkLibDetails } = useContext(WizardContext);
  // console.log({ roomInfo, cabinetInfo });
  return !cabinetInfo ? null : (
    <>
      {cabinetFollowUp() && (
        <>
          {!cabinetFollowUp() && <hr style={{ border: '1px solid #bbbbbb' }} />}
          <h3 id="confirm-cabinets">Update New Cabinets</h3>
          <p>
            Have your new cabinets arrived?
            <br />
            <Button
              onClick={() => {
                navigate('./cabinets/new');
              }}
            >
              Update status of new cabinets ...
            </Button>
          </p>{' '}
          <hr style={{ border: '1px solid #bbbbbb' }} />
        </>
      )}

      {!cabinetFollowUp() && (
        <>
          <hr />
          <h5>Cabinets</h5>
          {(cabinetInfo?.type === 'new' ||
            cabinetInfo?.type === 'new and old') && (
            <p>
              You have new cabinets
              {cabinetInfo?.status === 'installed' &&
                ' that are already installed.'}
              {cabinetInfo?.status === 'date' &&
                ' that will be installed on approximately ' +
                  cabinetInfo?.installDate +
                  ". I'll follow up with you near that date."}
              {cabinetInfo?.status === 'not sure' &&
                ", but you're unsure when they will be installed. I'll call you soon to discuss."}
            </p>
          )}
          {(cabinetInfo?.type === 'old' ||
            cabinetInfo?.type === 'new and old') && (
            <p>
              You {cabinetInfo?.type === 'new and old' && ' also '} have
              existing cabinets
              {cabinetInfo?.confirmedLevel === 'understand' &&
                ' and have assured us they will be level when our technician comes to measure.'}
              {cabinetInfo?.confirmedLevel === 'question' &&
                ". I'll call you soon to discuss what it means for them to be level."}
            </p>
          )}
        </>
      )}
    </>
  );
};

CabinetSummary.propTypes = {
  job: PropTypes.object
};

export default CabinetSummary;

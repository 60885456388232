import React, { useContext } from 'react';
import { WizardContext } from 'context/Context';
import { Alert } from 'react-bootstrap';

function getTimeWindow(time) {
  const [hour, minute] = time.split(':');
  return `${make12Hour(time)} - ${make12Hour(
    `${parseInt(hour) + 3}:${minute}`
  )}`;
}

function make12Hour(time) {
  const [hour, minute] = time.split(':');
  const hour12 = hour % 12 || 12;
  return `${hour12}:${minute}${hour < 12 ? 'am' : 'pm'}`;
}

const Confirmed = () => {
  const {
    job,
    selectedRooms,
    decisionMaker,
    qaInfo,
    cooktopFollowUp,
    cabinetFollowUp,
    countFollowUps
  } = useContext(WizardContext);
  const { template, install /*, plumbingInstall*/ } = job;
  const qaComplete =
    qaInfo.satisfied || job?.voc?.activityComplete || job?.voc?.formComplete;
  const qaIssue = qaInfo?.contact === 'yes' && !job?.voc?.activityComplete;
  const qaFeedback = qaInfo?.contact === 'no' && !job?.voc?.activityComplete;

  // these 2 functions are DUPLICATED!
  function sinkNotPurchased() {
    return job?.sinkInfo?.purchased === 'not yet';
  }

  function sinkOnsite() {
    return (
      job?.sinkInfo?.purchased !== 'yes' ||
      (job?.sinkInfo?.purchased === 'yes' &&
        job?.sinkInfo?.arrival === 'on site')
    );
  }

  return (
    <>
      {template?.status === 'Approved' ||
      template?.status === 'Future' ||
      template?.status === 'In Progress' ? (
        <>
          <h4>Measure Confirmed</h4>
          <Alert variant="secondary">
            Measure date: <strong>{template?.dateFormatted}</strong>
            {template?.time && (
              <>
                <br />
                Arrival window: <strong>{getTimeWindow(template?.time)}</strong>
              </>
            )}
          </Alert>
        </>
      ) : install?.status === 'Approved' ||
        install?.status === 'Future' ||
        install?.status === 'In Progress' ? (
        <>
          <h4>Install Confirmed</h4>
          <Alert variant="secondary">
            Install date: <strong>{install?.dateFormatted}</strong>
            {install?.time && (
              <>
                <br />
                Arrival window: <strong>{getTimeWindow(install?.time)}</strong>
              </>
            )}
          </Alert>
          {!install?.time && (
            <p>
              <em>
                We will provide you with the time window of arrival 2 days
                before the installation appointment.
              </em>
            </p>
          )}
          <p>
            Please ensure that you{' '}
            {decisionMaker?.name && <>or {decisionMaker?.name}</>} will be there
            at the time of our arrival and remain for the whole appointment.
          </p>

          {job?.plumbingInstall?.dateFormatted && (
            <Alert variant="secondary">
              Plumbing reconnect date: {job?.plumbingInstall?.dateFormatted}
            </Alert>
          )}
        </>
      ) : (
        <>
          {qaComplete && job?.plumbingInstall && (
            <Alert variant="secondary">
              We are scheduled to reconnect your plumbing on{' '}
              {job?.plumbingInstall?.dateFormatted}
            </Alert>
          )}
          {qaComplete && job?.tileInstall && (
            <Alert variant="secondary">
              We are scheduled to install your tile backsplash on{' '}
              {job?.tileInstall?.dateFormatted}
            </Alert>
          )}
          <h4>Thank you!</h4>
        </>
      )}
      {job.problem ? (
        <p>
          I'll give you a call soon to straighten things out and confirm the
          rest of your details.
        </p>
      ) : qaIssue ? (
        <>
          <p>
            We received your message and will respond to your issue as soon as
            possible.
          </p>
        </>
      ) : qaFeedback ? (
        <>
          <p>
            We appreciate your feedback and will use it to improve our service.
          </p>
        </>
      ) : qaComplete ? (
        <>
          <p>Your countertops are installed 😃</p>
          <p>
            To access care and maintenance instructions, please visit our{' '}
            <a href="https://precisioncountertops.com/warranty-registration/">
              warranty registration
            </a>{' '}
            page. By selecting the appropriate manufacturer, you'll also be able
            to register your warranty on their website.
          </p>
          <Alert variant="secondary" className="pb-0">
            <p>
              You'll be receiving a brief customer satisfaction survey from Home
              Depot to grade the quality of service you received specifically
              from Precision Countertops.
            </p>
            <p>
              If your crew did an excellent job for you (i.e., arrived within
              the expected timeframe, displayed a friendly, professional
              attitude, and cleaned up the work space upon their departure),
              please mark yourself as EXTREMELY SATISFIED.
            </p>
            <p>
              Please also provide any feedback you have about your crew in the
              Comment section of the survey as this sends an email to our
              management team here at Precision Countertops.
            </p>
          </Alert>

          <p>
            Thank you for choosing Home Depot and Precision Countertops, and
            don't hesitate to contact us with any questions or concerns.
            {job?.spsNumber && (
              <>
                {' '}
                Please reference your project ID# when communicating with us
                about your project: {job.spsNumber}
              </>
            )}
          </p>
        </>
      ) : install?.status === 'Approved' || install?.status === 'Future' ? (
        <>
          {/* <p>
            Your install date is confirmed for{' '}
            <strong>{install?.dateFormatted}</strong>
            {job?.plumbingInstall?.dateFormatted && (
              <>
                {' '}
                (and we will return {job.plumbingInstall.dateFormatted} to
                connect your plumbing)
              </>
            )}
            .
          </p> */}
          {/* {plumbingInstall && (
            <p>
              Your plumbing install date is confirmed for{' '}
              <strong>{plumbingInstall?.dateFormatted}</strong>.
            </p>
          )} */}
        </>
      ) : install?.status === 'Need New Date' ? (
        <p>
          Our schedulers are working on a new install date for you. We'll send
          you another text to confirm this date soon!
        </p>
      ) : install?.status === 'Will Call' ? (
        <p>
          I'll give you a call soon to find a date for your countertops install.
        </p>
      ) : install?.status === 'Ready To Send' ||
        install?.status === 'Pending Approval' ? (
        <p>
          We're working to schedule your countertops install and will get back
          to you soon with a date.
        </p>
      ) : install?.status === 'Installed' ? (
        <p>
          Your countertops installation is complete. We will follow up with you
          soon to make sure everything is as expected.
        </p>
      ) : install?.status === 'In Progress' ? (
        <p>
          Your countertops installation is in progress. We will follow up with
          you soon to make sure everything is as expected.
        </p>
      ) : template?.status === 'Approved' || template?.status === 'Future' ? (
        <>
          {!template.time && (
            <p>
              <em>
                We will provide you with the time window of arrival 2 days prior
                to your appointment.
              </em>
            </p>
          )}
          <p>
            Please ensure that you{' '}
            {decisionMaker?.name && <>or {decisionMaker?.name}</>} will be there
            at the time of our arrival and remain for the whole appointment.
          </p>
        </>
      ) : template?.status === 'Need New Date' ? (
        <p>
          Our schedulers are working on a new measure date for you. We'll send
          you another text to confirm this date soon!
        </p>
      ) : template?.status === 'Will Call' ? (
        <p>
          I'll give you a call soon to find a date for your countertops measure.
        </p>
      ) : template?.status === 'Ready To Send' ||
        template?.status === 'Pending Approval' ? (
        <p>
          We're working to schedule your countertops measure and will get back
          to you soon with a date.
        </p>
      ) : template?.status === 'Templated' ? (
        <p>
          Your countertops have been measured. We'll contact you soon to confirm
          your installation date.
        </p>
      ) : template?.status === 'In Progress' ? (
        <p>
          Your countertops are in the process of being measured. We'll contact
          you soon to confirm your installation date.
        </p>
      ) : job.cabinetsConfirmed ? (
        <>
          {countFollowUps() > 0 ? (
            <>
              <p>Thanks for confirming your countertop details.</p>
              <p>
                You have {countFollowUps()} outstanding item
                {countFollowUps() > 1 && 's'} to address in order to schedule
                your measure:
              </p>
              <ul className="my-2 mb-3">
                {(sinkNotPurchased() || !sinkOnsite()) && (
                  <li>
                    <a href="#confirm-sink">Update Sink Selection</a>
                    {job?.sinkInfo?.type && (
                      <em className="fs--1">
                        {' '}
                        - let us know when your sink has arrived
                      </em>
                    )}
                  </li>
                )}
                {cooktopFollowUp() && (
                  <li>
                    <a href="#confirm-cooktop">Update Cooktop/Range</a>
                    <em className="fs--1">
                      {' '}
                      - let us know when your cooktop/range has arrived
                    </em>
                  </li>
                )}
                {cabinetFollowUp() && (
                  <li>
                    <a href="#confirm-cabinets">Update Cabinets</a>
                    <em className="fs--1">
                      {' '}
                      - let us know when your cabinets have arrived
                    </em>
                  </li>
                )}
              </ul>
              {/* <p className="mt-3">
                After that's complete, we'll schedule your final measure
                appointment.
              </p> */}
            </>
          ) : (
            <p>
              Thanks for confirming your countertop details. I'll send you a
              text when your measure is scheduled!
            </p>
          )}
        </>
      ) : selectedRooms && selectedRooms.length > 0 ? (
        <p>
          Thanks for confirming your material and splash details. I'll give you
          a call soon to cover the rest!
        </p>
      ) : (
        <p>
          Your contact information is confirmed, and we're reviewing your order.
          You'll receive another text in a day or two so you can confirm all of
          the details about your new countertops.
        </p>
      )}
      {job?.verifyOrder &&
        template?.status !== 'Templated' &&
        !install?.status &&
        countFollowUps() === 0 && (
          <Alert variant="secondary">
            Reminder:{' '}
            <strong>
              you need to have all your sinks, faucets, ranges, and cooktops on
              site
            </strong>{' '}
            when our technician comes to take final measurements for the digital
            template.
          </Alert>
        )}
      <p>
        If you have {(job.problem || qaIssue) && 'more'} questions, just reply
        to my text or call{' '}
        <a href={`tel:1-${job?.PC?.phone || '800-548-4445'}`}>
          {job?.PC?.phone || '800.548.4445'}
        </a>
        . I work M-F from 7a - 4p Pacific Time.
      </p>
      {job?.PC?.firstName && (
        <p style={{ fontStyle: 'italic' }}>-{job.PC.firstName}</p>
      )}
    </>
  );
};

export default Confirmed;

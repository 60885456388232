import React, { useContext, useEffect, useState } from 'react';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { WizardContext } from 'context/Context';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isoShortDate } from 'si/helpers/isoShortDate';
import { msWeek } from './Unknown';

export default function SinkPurchased() {
  const { sinkInfo, setSinkInfo, job, handleSinkDetails } =
    useContext(WizardContext);
  const [dateVal, setDateVal] = useState('');
  const [weeks, setWeeks] = useState('');
  const [correctType, setCorrectType] = useState(null);
  const [sinkType, setSinkType] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!job.roomInfo || job.sinkMoraName) {
      navigate('../../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setSinkInfo({
        ...job?.sinkInfo,
        ...sinkInfo,
        purchased: 'yes',
        arrival: null,
        arrivalDate: null
      });
      if (job.sinkInfo?.type) {
        if (job.sinkInfo.dualMount === 'purchased') {
          setSinkType('dual mount (will be installed as top mount)');
        } else {
          setSinkType(job.sinkInfo.type);
        }
      }
    }
    // console.log('sinkInfo', sinkInfo);
    // console.log('job.sinkInfo', job.sinkInfo);
  }, []);
  const arrivalData = (value, date = null) => {
    setSinkInfo({ ...sinkInfo, arrival: value, arrivalDate: date });
  };
  function next() {
    if (correctType === 'yes') {
      // console.log('sinkInfo', sinkInfo);
      handleSinkDetails();
      navigate('../..');
    } else {
      navigate('../inspect');
    }
  }
  const arrivalWeeks = e => {
    const value = e.target.value;
    setWeeks(value);
    const date = new Date().getTime() + msWeek * value;
    const isoDate = isoShortDate(new Date(date));
    setDateVal(isoDate);
    setSinkInfo({ ...sinkInfo, arrival: 'date', arrivalDate: isoDate });
  };
  function knownSink() {
    return sinkType && correctType !== 'no';
  }
  return (
    <Wrapper>
      <div className="mt-5">
        {sinkType && (
          <>
            <h2 className="fs-0">
              Did you order a
              {
                Array.from(sinkType)[0] === 'u' ? 'n' : '' // 'an' for undermount, 'a' for everything else
              }{' '}
              <span style={{ fontWeight: 'bold' }}>{sinkType}</span> sink?
            </h2>
            <Form>
              <CustomCheckbox
                name="confirmType"
                type="radio"
                label="Yes"
                value="yes"
                checked={correctType === 'yes'}
                toggleCheck={setCorrectType}
              />
              <CustomCheckbox
                name="confirmType"
                type="radio"
                label="No, I need to update the sink type"
                value="no"
                checked={correctType === 'no'}
                toggleCheck={setCorrectType}
              />
            </Form>{' '}
          </>
        )}
        {correctType === 'no' && (
          <>
            <p className="fs--1">
              OK, we'll have you update your sink details on the next screen.
            </p>
            <hr />
          </>
        )}
        <h2 className="fs-0">
          {knownSink() ? (
            <>
              Has your <span style={{ fontWeight: 'bold' }}>{sinkType}</span>{' '}
              sink arrived?
            </>
          ) : (
            'When do you expect your new sink to arrive?'
          )}
        </h2>
        <Form>
          <CustomCheckbox
            name="arrival"
            type="radio"
            label={knownSink() ? "Yes, it's on site" : 'It’s already on site'}
            value="on site"
            checked={sinkInfo?.arrival === 'on site'}
            toggleCheck={arrivalData}
          />
          <Form.Check
            type="radio"
            id="date"
            name="arrival"
            label={
              <>
                {knownSink()
                  ? 'No, we expect it to arrive approximately'
                  : 'Approximately'}

                <p className="mx-3 my-2">
                  <input
                    type={'number'}
                    min={1}
                    max={30}
                    value={weeks}
                    onChange={arrivalWeeks}
                  />{' '}
                  week(s)
                </p>
                <p className="mx-4 my-2">or</p>
                <p className="mx-3 my-0">
                  <input
                    type={'date'}
                    value={dateVal}
                    onChange={e => {
                      setDateVal(e.target.value);
                      arrivalData('date', e.target.value);
                    }}
                  />
                </p>
              </>
            }
            checked={sinkInfo?.arrival === 'date'}
            onChange={() => arrivalData('date', dateVal)}
          />
        </Form>
      </div>
      <Button
        className="w-100"
        onClick={next}
        disabled={
          !sinkInfo?.purchased ||
          (sinkInfo?.purchased === 'yes' &&
            (!sinkInfo?.arrival ||
              (sinkInfo?.arrival === 'date' && !sinkInfo?.arrivalDate))) ||
          (sinkType && !correctType)
        }
      >
        Continue
      </Button>
    </Wrapper>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Button } from 'react-bootstrap';
import Wrapper from './components/Wrapper';
import Video2 from './components/Video2';
import { prices } from 'prices';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TileSale() {
  const { job, handleSplashes } = useContext(WizardContext);
  const navigate = useNavigate();
  const [decision, setDecision] = useState('');

  useEffect(() => {
    // console.log('template status', job?.template?.status);
    console.log('job', job);
    if (job.id && (!job.tileZip || job?.template?.status === 'Templated')) {
      navigate('../');
    }
  }, [job.id, job?.template?.status, job?.tileZip]);

  return job.tileZip ? (
    <Wrapper>
      <div className="container">
        <h5>
          Please select your backsplash option below
          {/* <Link to={'..'}>
            <FontAwesomeIcon icon="home" className="float-end" />
          </Link> */}
        </h5>
        <Row className="flex-center pt-1 pb-3">
          <Video2 file="/tileinstall/TileClips-short.mp4" autoPlay={true} />
        </Row>
        <Row className="flex-center pt-1 pb-3">
          <h4>Deluxe Tile Package {prices.tileDeluxe}</h4>
          <p className="mb-1">
            Let us handle the hard part! With our Deluxe Tile Package, you don’t
            have to lift a finger. For {prices.tileDeluxe}, we take care of
            everything, including:
          </p>
          <ul className="ps-5">
            <li>Precision-supplied 3"x6" white tile</li>
            <li>Grout for a seamless finish</li>
            <li>Wall preparation for flawless results</li>
            <li>Outlet Cutouts</li>
          </ul>
          <p className="mb-1">
            Enjoy peace of mind with professional installation—no mess, no
            crooked lines, just perfection. Plus, you can personalize your
            project with these options:
          </p>
          <ul className="ps-5">
            <li>Tile finish: Matte or Glossy</li>
            <li>Layout style: Brickset or Straight Stacked</li>
            <li>Edge treatment: White, Chrome, or Aluminum Metal</li>
          </ul>
          <p>We do the hard work, so you can sit back and enjoy the results!</p>
          <img
            alt="Tile Backsplash - Deluxe layout options"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-tileinstall%2Ftile%20backsplash%20options.png"
            width="350"
          />
        </Row>

        <Row className="flex-center pt-0 pb-3">
          <hr />
          <h4>Standard Package {prices.tileInstallation}</h4>
          <p className="mb-1">
            Transform your space with our hassle-free Standard Package! For just{' '}
            {prices.tileInstallation}, we’ll handle everything except the tile.
            You provide the tile, and we take care of the rest, including:
          </p>
          <ul className="ps-5">
            <li>Expert tile installation</li>
            <li>Edge protectors for a clean, professional finish</li>
            <li>Grout for a seamless look</li>
            <li>Wall preparation to ensure a flawless result</li>
            <li>Outlet Cutouts</li>
          </ul>
          <p>
            Leave the details to us—sit back and enjoy your beautiful new space!
          </p>
          <img
            alt="Tile Backsplash - Deluxe layout options"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-tileinstall%2Ftile%20backsplash%20non%20subway.jpg"
            width="350"
          />
          {/* <Alert variant="secondary">
            Any tile you've purchased must be onsite when our installers arrive.
          </Alert> */}
        </Row>

        <Row>
          <hr />
          <p>Click your choice below to move forward with your project:</p>
        </Row>

        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="deluxe"
              label={`I'd like the deluxe package`}
              checked={decision === 'deluxe'}
              onChange={() => {
                setDecision('deluxe');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="yes"
              label={<>I'd like the standard package</>}
              checked={decision === 'yes'}
              onChange={() => {
                setDecision('yes');
              }}
            />
            {/* <Alert variant="secondary">
              The tile you've purchased must be onsite when our installers
              arrive.
            </Alert> */}
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="no"
              label="I do not want to purchase either package and will install my own backsplash"
              checked={decision === 'no'}
              onChange={() => {
                setDecision('no');
              }}
            />
          </Row>
          {/* <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="maybe"
              label="I'm not sure - let's discuss when you come to measure"
              checked={decision === 'maybe'}
              onChange={() => {
                setDecision('maybe');
              }}
            />
          </Row> */}
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            handleSplashes(job.rooms, decision, true); // true means postOV
            navigate('../');
          }}
        >
          Submit My Selection
        </Button>
      </div>
    </Wrapper>
  ) : (
    <Wrapper>
      <div className="container">
        <p>
          It looks as though you're installing the tile backsplash yourself, or
          you've made other installation plans. Is that correct?
        </p>
        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="self"
              label="Yes"
              checked={decision === 'self'}
              onChange={() => {
                setDecision('self');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="call"
              label="No, please call me later to discuss"
              checked={decision === 'call'}
              onChange={() => {
                setDecision('call');
              }}
            />
          </Row>
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            handleSplashes(job.rooms, decision, true); // true means postOV
            navigate('../');
          }}
        >
          Submit My Selection
        </Button>
      </div>
    </Wrapper>
  );
}

export default TileSale;

import React, { useContext, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import Wrapper from './components/Wrapper';
import ButtonGroup from './components/ButtonGroup';

const Color = () => {
  const { job, imgColor, imgColorSlab, handleConfirmColor } =
    useContext(WizardContext);
  const { roomInfo } = job;
  const navigate = useNavigate();

  useEffect(() => {
    if (!job.roomInfo) {
      navigate('../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [job]);

  return (
    <Wrapper>
      <div className="container">
        <p>
          It looks as though you've selected{' '}
          <strong>
            {roomInfo?.brand} {roomInfo?.color}
          </strong>{' '}
          for your countertops.
        </p>
        {imgColor && (
          <>
            <Row className="flex-center pt-1">
              <a style={{ textAlign: 'center' }} href={imgColor?.src}>
                Close-up
              </a>
            </Row>
            <Row className="flex-center pt-1 pb-3">
              <img
                // style={{ width: '400px' }}
                alt={`${roomInfo?.brand} ${roomInfo?.color}`}
                src={imgColor?.src}
              />
            </Row>
          </>
        )}
        {imgColorSlab && (
          <>
            <Row className="flex-center pt-1">
              <a style={{ textAlign: 'center' }} href={imgColorSlab?.src}>
                Full Slab
              </a>
            </Row>
            <Row className="flex-center pt-1 pb-3">
              <img
                // style={{ width: '400px' }}
                alt={`${roomInfo?.brand} ${roomInfo?.color} Slab`}
                src={imgColorSlab?.src}
              />
            </Row>
          </>
        )}
        {(imgColor || imgColorSlab) && (
          <Row>
            <p
              className="fs--1"
              style={{ fontStyle: 'italic', textAlign: 'center' }}
            >
              Pictures are representative ...
              <br />
              your specific slab(s) will vary
            </p>
          </Row>
        )}
        <p>
          Is{' '}
          <strong>
            {roomInfo?.brand} {roomInfo?.color}
          </strong>{' '}
          correct?
        </p>
        <ButtonGroup
          primaryFunc={() => {
            handleConfirmColor();
            navigate('../');
            // if (job.disclosures.length > 0 && !job.disclosuresAccepted) {
            //   navigate(`../disclosure/${job.disclosures[0].toLowerCase()}`);
            // } else if (job.offerRendering) {
            //   navigate('../rendering');
            // } else {
            //   navigate('../edge');
            // }
          }}
          primaryText="Yes, it's correct!"
          secondaryFunc={() => navigate('../problem')}
          secondaryText="Make Changes"
        />
      </div>
    </Wrapper>
  );
};

export default Color;

import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useWizardContext } from '../WizardProvider';
import { ExternalAction } from './SinkSelector';

const ConfirmSinkDetails = () => {
  const navigate = useNavigate();
  const { sinkInfo, setSinkInfo, job } = useWizardContext();

  return (
    <>
      <hr />
      <hr style={{ border: '1px solid #bbbbbb' }} />
      <h3 id="confirm-sink">Update Sink Selection</h3>
      <p>
        Before we can schedule your final measure appointment, you need to
        purchase a sink, and it must be on site.
      </p>
      {job?.sinkInfo?.arrival === 'date' && (
        <p>
          Has your {job?.sinkInfo?.type} sink arrived? Please update your sink's
          status and details:
          <br />
          <Button
            onClick={() => {
              setSinkInfo({
                ...sinkInfo,
                purchased: 'yes',
                arrival: null,
                arrivalDate: null
              });
              navigate('./sink/0/purchased');
            }}
          >
            Update sink status ...
          </Button>
        </p>
      )}
      {job?.sinkInfo?.arrival !== 'date' && (
        <p>
          If you've purchased a sink from elsewhere, please let us know:{' '}
          <Button
            onClick={() => {
              setSinkInfo({
                ...sinkInfo,
                purchased: 'yes',
                arrival: null,
                arrivalDate: null
              });
              navigate('./sink/0/purchased');
            }}
          >
            I purchased a sink ...
          </Button>
        </p>
      )}
      {job?.roomInfo?.materialType &&
        job?.roomInfo?.materialType !== 'Laminate' && (
          <>
            <p>
              If you still need to purchase a sink, we can help you choose the
              right sink for you:
              <br />
              <Button
                onClick={() => {
                  navigate('./sink/0/choose');
                }}
              >
                Help me choose a sink ...
              </Button>
            </p>
          </>
        )}
      {job?.roomInfo?.materialType === 'Laminate' && (
        <>
          <p>
            For laminate countertops like yours, the sink must be a top mount
            sink. You can find a top mount sink at Home Depot at the link below.{' '}
            <strong>
              Note that it must be in your home for your final measure
              appointment.
            </strong>
          </p>
          <p>
            Can't decide between 50/50, 60/40, or single bowl? Choose what
            you're used to! Unless you have a strong opinion about wanting to
            choose something new, most people prefer what they already know.
          </p>
          <ExternalAction
            link="https://www.homedepot.com/s/top%20mount%20kitchen%20sink?NCNI-5"
            text="Show me top mount sinks at Home Depot"
          />
        </>
      )}
      <hr style={{ border: '1px solid #bbbbbb' }} />
    </>
  );
};

export default ConfirmSinkDetails;

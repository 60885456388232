import React, { useContext, useEffect } from 'react';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { WizardContext } from 'context/Context';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function DualMount() {
  const { sinkInfo, setSinkInfo, job, handleSinkDetails } =
    useContext(WizardContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!job.roomInfo || job.sinkMoraName) {
      navigate('../../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);
  function next() {
    if (sinkInfo?.dualMount === 'no') {
      // CHECK TO SEE IF MATERIAL IS STONE!
      if (
        (job?.roomInfo?.materialType === 'Stone' ||
          job?.roomInfo?.materialType === 'Ultracompact') &&
        !job?.chipMinimizerAlready
      ) {
        navigate('../../chipminizer/undermount-stone');
        return;
      }
    }
    handleSinkDetails();
    navigate('../..');
  }
  const toggleCheck = value => {
    setSinkInfo({ ...sinkInfo, dualMount: value });
  };
  return (
    <Wrapper>
      <div>
        <h2 className="fs-0 mb-3">
          Please note: we are NOT able to install "dual-mount" sinks as
          undermount sinks.
        </h2>
        <img
          className="img-fluid"
          alt="Dual Mount"
          src="https://library.countertopwizard.com/OtherAsset%2Fcw-sinktypes%2Fdualmount.png"
        />
        <p style={{ fontWeight: 'bold' }}>
          If your sink has faucet holes, we cannot install it as an undermount.
        </p>
        <CustomCheckbox
          name="mount"
          type="radio"
          label=" I confirm that my sink is NOT a dual-mount sink."
          value="no"
          checked={sinkInfo.dualMount === 'no'}
          toggleCheck={toggleCheck}
        />
        <CustomCheckbox
          name="mount"
          type="radio"
          label="I'm not sure if it is dual-mount."
          value="not sure"
          checked={sinkInfo.dualMount === 'not sure'}
          toggleCheck={toggleCheck}
        />
        <CustomCheckbox
          name="mount"
          type="radio"
          label=" I already purchased a dual-mount sink and confirm that it will be installed as topmount."
          value="purchased"
          checked={sinkInfo.dualMount === 'purchased'}
          toggleCheck={toggleCheck}
        />
      </div>
      <Button className="w-100" disabled={!sinkInfo?.dualMount} onClick={next}>
        Continue
      </Button>
    </Wrapper>
  );
}

import React, { useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';
import { useNavigate } from 'react-router-dom';
import { WizardContext } from 'context/Context';
import { prices } from 'prices';

export default function PlumbingReconnect() {
  const navigate = useNavigate();

  const { plumbingInfo, setPlumbingInfo, job, handlePlumbingDetails } =
    useContext(WizardContext);

  useEffect(() => {
    if (!job.roomInfo || job.plumbingConfirmed || !job.reconnectZip) {
      navigate('../..');
    }
  }, []);

  const toggleCheck = value => {
    setPlumbingInfo({ ...plumbingInfo, reconnect: value });
  };

  const next = () => {
    handlePlumbingDetails();
    navigate('../..');
  };

  return (
    <Wrapper>
      <p>
        Precision can reconnect your plumbing after countertop installation.
      </p>
      <p>
        Our basic package is {prices.plumbingReconnect} and includes
        reconnecting your sink, faucet, existing dishwasher, and existing
        garbage disposal. We also have packages to include additional sinks,
        water filter, Insta-Hot, Range, etc.
      </p>
      <p>
        Note that we cannot work with hardwired appliances, because our plumbers
        cannot do electrical work. We also cannot install new appliances.
      </p>
      <p>
        Would you like Precision to reconnect your plumbing after installing
        your countertops?
      </p>
      <CustomCheckbox
        name="reconnect"
        type="radio"
        label="Yes, I'd like Precision to reconnect my plumbing"
        value="yes"
        checked={plumbingInfo?.reconnect === 'yes'}
        toggleCheck={toggleCheck}
      />
      <CustomCheckbox
        name="reconnect"
        type="radio"
        label="No, I've made other arrangements to reconnect plumbing"
        value="no"
        checked={plumbingInfo?.reconnect === 'no'}
        toggleCheck={toggleCheck}
      />
      <CustomCheckbox
        name="reconnect"
        type="radio"
        label="I'm not sure"
        value="call"
        checked={plumbingInfo?.reconnect === 'call'}
        toggleCheck={toggleCheck}
      />
      {plumbingInfo?.reconnect === 'call' && (
        <p>We will discuss with you when your technician comes to measure.</p>
      )}
      <Button
        className="w-100"
        variant="primary"
        disabled={!plumbingInfo?.reconnect}
        onClick={next}
      >
        Continue
      </Button>
    </Wrapper>
  );
}

import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
//import Layout from './layouts/Layout';
import Layout from './si/layouts/Layout';
import './si/styles.css';
import is from 'is_js';

const App = () => {
  useEffect(() => {
    if (is.mobile()) document.body.style.background = 'white';
  }, []);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
      <div
        className="text-light text-center py-2 mb-0 mt-2"
        style={{ backgroundColor: '#263A64' }}
      >
        <a href="https://precisioncountertops.com">
          <img
            width="246"
            height="51"
            src="//precisioncountertops.com/wp-content/uploads/2020/04/pct-footer-logo.svg"
            data-src="//precisioncountertops.com/wp-content/uploads/2020/04/pct-footer-logo.svg"
            className="mt-3"
            alt="Precision Countertops Footer Logo"
          />
        </a>
        <p className="mt-3">
          <a href="tel:1-800-548-4445" className="text-light">
            800.548.4445
          </a>
        </p>
      </div>
    </Router>
  );
};

export default App;

import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import CustomCheckbox from '../components/CustomCheckbox';
import Video2 from '../components/Video2';
import Wrapper from '../components/Wrapper';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { prices } from 'prices';

const UndermountStone = () => {
  const { sinkInfo, setSinkInfo, job, handleSinkDetails } =
    useContext(WizardContext);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('is sink confirmed?', job);
    if (!job.roomInfo) {
      console.log('navigating to root');
      navigate('../../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);
  const toggleCheck = value => {
    setSinkInfo({ ...sinkInfo, chipMinimizer: value });
  };
  function next() {
    handleSinkDetails();
    navigate('../..');
  }
  return (
    <Wrapper className="m-4">
      <div>
        <p>
          Great. Undermount sinks are fantastic, but the stone around the sink
          is vulnerable to chipping - and those repairs aren't covered under
          warranty, so they used to be pricey to fix! <br />
          <br />
          That's why we developed a special treatment to prevent chipping before
          it happens. We ease the inside edge to smooth it out, making it less
          fragile
        </p>
      </div>
      <div></div>
      <Video2
        file={
          'https://library.countertopwizard.com/OtherAsset%2Fcw-chip-minimizer%2FChip%20Minimizer%20(Low%20Volume%20Music).mp4#t=0.1'
        }
      />
      <p>
        The cost of the Chip Minimizer is {prices.chipMinimizer}, which is less
        that the trip charge for a repair.
        <br /> <br />{' '}
        <strong>
          Would you like to add the Chip Minimizer edge to your order?
        </strong>
      </p>
      <div>
        <CustomCheckbox
          name="radiochip"
          type="radio"
          label="Yes"
          value="yes"
          checked={sinkInfo?.chipMinimizer === 'yes'}
          toggleCheck={toggleCheck}
        ></CustomCheckbox>
        <CustomCheckbox
          name="radiochip"
          type="radio"
          label="No"
          value="no"
          checked={sinkInfo?.chipMinimizer === 'no'}
          toggleCheck={toggleCheck}
        ></CustomCheckbox>
        <CustomCheckbox
          name="radiochip"
          type="radio"
          label="I'm not sure"
          value="notsure"
          checked={sinkInfo?.chipMinimizer === 'notsure'}
          toggleCheck={toggleCheck}
        ></CustomCheckbox>
      </div>
      {sinkInfo?.chipMinimizer === 'notsure' && (
        <p>We'll discuss with you when your technician comes to measure</p>
      )}
      <div>
        <div>
          <Button
            className="w-100 my-3"
            variant="primary"
            disabled={!sinkInfo?.chipMinimizer}
            onClick={next}
          >
            Continue
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default UndermountStone;

import React, { useEffect, useContext } from 'react';
import { WizardContext } from 'context/Context';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from './components/CustomCheckbox';
import Wrapper from './components/Wrapper';

export default function Appliances() {
  const navigate = useNavigate();
  const {
    cooktopInfo,
    setCooktopInfo,
    sinkInfo,
    setSinkInfo,
    job,
    handleCooktopDetails,
    cooktopFollowUp
  } = useContext(WizardContext);

  useEffect(() => {
    if (!cooktopFollowUp() && (!job.roomInfo || job.cooktopDecided)) {
      navigate('../');
    }
  }, []);
  const toggleCheck = () => {
    setCooktopInfo({ ...cooktopInfo, agreed: !cooktopInfo.agreed });
  };
  const toggleFarm = () => {
    setSinkInfo({ ...sinkInfo, farmInPlace: !sinkInfo.farmInPlace });
  };
  const next = () => {
    handleCooktopDetails();
    navigate('../cabinets');
  };

  function isFarm() {
    return job?.sinkInfo?.type && job?.sinkInfo?.type.match(/farm/i);
  }

  return (
    <Wrapper>
      <div>
        <p>
          Thank you! Please note that you’ll need to have all your sinks,
          faucets, ranges, and cooktops on site when our technician comes to
          take final measurements for the digital template.{' '}
        </p>
      </div>
      {/* <div className="border w-100 h-100  p-3 text-center">
        Aggreement stuff here
      </div> */}
      <div className="mt-3">
        <CustomCheckbox
          name="appliances"
          label="I agree to have all appliances, faucets, and sinks (except if purchased from Precision) on site when the Precision technician comes to measure"
          checked={cooktopInfo?.agreed}
          toggleCheck={toggleCheck}
        />
      </div>
      {isFarm() && (
        <>
          <p>
            At template, the technician will need your farm sink to be resting
            in place, but not glued down.
          </p>
          <CustomCheckbox
            name="farmInPlace"
            label="I agree that my farm sink will be resting in place but not glued down when the technician arrives to measure"
            checked={sinkInfo?.farmInPlace}
            toggleCheck={toggleFarm}
          />
        </>
      )}
      <Button
        className="w-100 my-3"
        variant="primary"
        onClick={next}
        disabled={!cooktopInfo?.agreed || (isFarm() && !sinkInfo?.farmInPlace)}
      >
        Continue
      </Button>
    </Wrapper>
  );
}

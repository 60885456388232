import React, { useState } from 'react';
import DisclosureLayout from './DisclosureLayout';
import PropTypes from 'prop-types';

export default function Marble({ standalone = false }) {
  const [list] = useState([
    {
      title: 'Staining',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/marble-staining-pitm3k3n9yjquc2o8gkwhjtoqn9w5jhcvbytekaow8.jpg'
    },
    {
      title: 'Scratching',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/marble-srcatching-1-pitolqq6zq04rh2rmfyapsjsjiroo3i8lp9x0akvwo.jpg'
    },
    {
      title: 'Finished kitchen',
      img: 'https://precisioncountertops.com/wp-content/uploads/elementor/thumbs/carrara-white-marble-p3li2adapsh1hdtzeund382h364ktn8ld2z3rm66iw.png'
    }
  ]);
  const [warranty] = useState({
    description:
      'All natural stone countertops come with a one (1) year installation and labor warranty.',
    warning: 'There is no warranty against staining on these materials.'
  });
  return (
    <DisclosureLayout
      standalone={standalone}
      title={'Marble Disclosure'}
      videoId={'JyXPtyVlW9I'}
      review={
        'PLEASE REVIEW THE INFORMATION BELOW REGARDING YOUR MARBLE COUNTERTOPS'
      }
      sliderList={list}
      aboutTitle={
        'Marble is a natural material and often displays great variations in background color, veining distribution and veining pattern.'
      }
      aboutSubTitle={
        'Fissures and pitting are naturally occurring features in this material and should not be considered defects. When purchasing marble for use in a kitchen, please consider the following:'
      }
      aboutList={[
        'This material is highly prone to staining.',
        'The slabs are not sealed and will stain easily and often',
        'Due to the nature of the minerals that compose this product pitting is very common and cannot be filled',
        'Most of these colors have large areas of factory fill - monochromatic resin that has a different shine and color than the natural stone',
        'Although these products can be extremely reflective at the time of installation, over time, this finish will dull - eventually being a matte finish over the period of several years',
        'Slabs are frequently smaller and therefore more seams may be required',
        'Natural stones that are not granite require special cleaning and will etch easily and quickly - often within just minutes of being exposed to many substances, including common foods and beverages'
      ]}
      aboutConclusion={
        'Caring for these natural stones requires extra attention. Spilled foods and beverages need to be cleaned up immediately. Cleaning should be done with soft towels and mild soap and water. We do not recommend the use of any over-the-counter “countertop cleaners” or polishes. Even with regular cleaning, the surface of these products will change and age over time - similar to other natural materials such as hardwoods.'
      }
      warranty={warranty}
    />
  );
}

Marble.propTypes = {
  standalone: PropTypes.bool
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const labels = {
  contactName: 'Your Name: ',
  addressLine1: 'Address: ',
  addressLine2: '',
  city: 'City: ',
  state: 'State: ',
  zip: 'Zip: ',
  cell: 'Cell: ',
  phone: 'Phone: ',
  phone2: 'Phone: ',
  email: 'Email: '
};

const ContactInfo = ({ address, textOK }) => {
  const items = Object.keys(labels).filter(l => address[l]);
  return (
    <Fragment>
      <Row>
        <Col>
          <table>
            <tbody>
              {items.map(i => (
                <tr key={i}>
                  <td>{labels[i]}&nbsp;</td>
                  <td>
                    {address[i]}
                    {i === 'cell' && textOK && (
                      <span
                        className="ml-2 text-secondary fs--1"
                        style={{ fontStyle: 'italic' }}
                      >
                        {' '}
                        (OK to text this number)
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Fragment>
  );
};

ContactInfo.propTypes = {
  address: PropTypes.object,
  textOK: PropTypes.bool
};

export default ContactInfo;

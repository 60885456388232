import React, { useContext, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import { Button, Row } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useParams, useNavigate } from 'react-router-dom';
import Wrapper from './components/Wrapper';

// DON'T USE THIS

function Splash() {
  const { room } = useParams();
  const { job, selectedRooms, setSelectedRooms, handleSplashes } =
    useContext(WizardContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!job.roomInfo || job.splashesConfirmed) {
      navigate('../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return selectedRooms && selectedRooms.length > 0 ? (
    <Wrapper>
      <FalconCardHeader
        className="text-center"
        title={selectedRooms[room].name}
        light={false}
      />
      <p>
        Got it. Let's confirm a few details about the{' '}
        <strong>{selectedRooms[room].name}</strong>.
      </p>
      <p>
        Are you planning to have Precision Countertops do your backsplash
        through Home Depot?
      </p>
      <Row className="flex-center py-3">
        <Button
          variant="outline-primary"
          color="primary"
          className="mx-2 my-1 btn-width"
          type="submit"
          onClick={() => {
            selectedRooms[room] = {
              ...selectedRooms[room],
              splash: 'no',
              splashType: null
            };
            setSelectedRooms(selectedRooms);
            if (selectedRooms.length > parseInt(room) + 1)
              navigate(`../splash/${parseInt(room) + 1}`);
            else {
              handleSplashes(selectedRooms);
              navigate('../');
            }
          }}
        >
          No, skip ahead
        </Button>
        <Button
          color="primary"
          className="mx-2 my-1 btn-width"
          type="submit"
          onClick={() => {
            selectedRooms[room].splash = 'yes';
            setSelectedRooms(selectedRooms);
            navigate(`../splashtype/${room}`);
          }}
        >
          Yes
        </Button>
      </Row>
    </Wrapper>
  ) : null;
}

Splash.propTypes = {
  roomType: PropTypes.string,
  step: PropTypes.number,
  setStep: PropTypes.func,
  setNext: PropTypes.func
};

export default Splash;

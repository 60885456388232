import React from 'react';
import Wrapper from './components/Wrapper';
import BackButton from './components/BackButton';
function Callme() {
  return (
    <Wrapper>
      <BackButton />
      <div className="mt-3">
        <p>
          No problem. Someone will call you shortly to answer you questions.
        </p>
      </div>
    </Wrapper>
  );
}
export default Callme;

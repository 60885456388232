import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PlumbingDisconnect from './PlumbingDisconnect';
import PlumbingReconnect from './PlumbingReconnect';

export default function PlumbingRoutes() {
  return (
    <Routes>
      <Route path="/disconnect" element={<PlumbingDisconnect />} />
      <Route path="/reconnect" element={<PlumbingReconnect />} />
    </Routes>
  );
}

import React, { useContext, useState, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import { Button, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Wrapper from './components/Wrapper';
import { useNavigate } from 'react-router-dom';

function Rooms() {
  const navigate = useNavigate();
  const {
    job,
    handleRooms,
    startingRooms,
    roomChecks,
    setRoomChecks,
    selectedRooms,
    otherRoom,
    setOtherRoom
  } = useContext(WizardContext);
  const [chooseRoomsError, setChooseRoomsError] = useState(false);

  useEffect(() => {
    if (!job.roomInfo) {
      navigate('../');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    console.log(selectedRooms);
  }, [selectedRooms]);

  function RoomCheckbox({ label }) {
    return (
      <Form>
        <Form.Check
          type="checkbox"
          id={label}
          label={label}
          checked={isChecked(label)}
          onChange={() => toggleCheck(label)}
        />
      </Form>
    );
  }

  RoomCheckbox.propTypes = {
    label: PropTypes.string
  };

  function isChecked(name) {
    return roomChecks[name];
  }

  function toggleCheck(name) {
    setRoomChecks({ ...roomChecks, [name]: !roomChecks[name] });
    chooseRoomsError &&
      setChooseRoomsError(
        Object.entries(roomChecks)
          .map(item => item[1])
          .reduce((pre, res) => pre || res, false)
      );
  }

  const setupRooms = () => {
    let UpdateChecks = roomChecks;

    if (UpdateChecks.Other && otherRoom.length != 0) {
      UpdateChecks = { ...UpdateChecks, [otherRoom]: true, Other: false };
    }

    const newrooms = Object.entries(UpdateChecks)
      .filter(([, value]) => value === true)
      .map(([key]) => ({
        name: key,
        splash: null,
        splashType: null
      }));
    if (newrooms.length === 0) {
      setChooseRoomsError(true);
      return;
    } else {
      // setSelectedRooms(newrooms);
      handleRooms(newrooms);
      navigate(`../splashtype/0`);
    }
    setChooseRoomsError(false);
  };

  return (
    <Wrapper>
      <div>
        <p>
          Perfect. Please select the room or rooms where the countertops will be
          installed.
        </p>
        {startingRooms.map(room => (
          <Row key={room} className="pl-3">
            <RoomCheckbox label={room} />
          </Row>
        ))}
        {isChecked('Other') && (
          <input
            className="w-100"
            type="text"
            id="otherRoom"
            placeholder="Other Room Name(s)"
            value={otherRoom}
            onChange={({ target }) => setOtherRoom(target.value)}
          />
        )}
        {chooseRoomsError && (
          <p className="text-danger pt-2">Please select at least one room.</p>
        )}
        <Row className="flex-center py-3">
          <Button
            color="primary transition"
            className="mx-2 my-1 save-comment-btn"
            type="submit"
            onClick={() => {
              setupRooms();
            }}
          >
            Continue
          </Button>
        </Row>
      </div>
    </Wrapper>
  );
}

export default Rooms;

import React from 'react';

const Intro = () => {
  return (
    <>
      <h4>Confirm Job Site Address &amp; Contact Info</h4>
      <p>
        We're eager to get started on your countertops! Please confirm the
        <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
          {' '}
          address where the countertops will be installed
        </span>{' '}
        as well as your contact info.
      </p>
    </>
  );
};

export default Intro;

import React, { useContext, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import { Row, Col, Button, Form } from 'react-bootstrap';

function formatPhone(phone) {
  if (!phone) return phone;
  if (typeof phone !== 'string') return phone;
  const m = phone.match(
    /[+]?0?1?\(?(\d{3})\)? ?[.-]? ?(\d{3}) ?[.-]? ?(\d{4})(.*)/
  );
  if (!m || !m[1] || !m[2] || !m[3]) return phone;
  return `${m[1]}-${m[2]}-${m[3]}${m[4] || ''}`;
}

const EditForm = () => {
  const { job, setJob, setAddress, handleConfirm } = useContext(WizardContext);

  function doFormats() {
    if (job?.address?.cell || job?.address?.phone || job?.address?.phone2) {
      const address = {
        ...job.address,
        cell: job.address.cell ? formatPhone(job.address.cell) : '',
        phone: job.address.phone ? formatPhone(job.address.phone) : '',
        phone2: job.address.phone2 ? formatPhone(job.address.phone2) : ''
      };
      setAddress(address);
      return address;
    }
  }

  useEffect(() => {
    doFormats();
  }, []);

  return (
    <>
      <h5>Update Job Site Address &amp; Contact Info</h5>
      <Row>
        <Col lg={12}>
          <Form.Group className="form-group">
            <Form.Label>Your Name</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="contact-name"
              value={job.address.contactName}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.contactName = target.value;
                setAddress(address);
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group className="form-group">
            <Form.Label>Address Line 1</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="address-line1"
              value={job.address.addressLine1}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.addressLine1 = target.value;
                setAddress(address);
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group className="form-group">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="address-line2"
              value={job.address.addressLine2}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.addressLine2 = target.value;
                setAddress(address);
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group className="form-group">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="city"
              value={job.address.city}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.city = target.value;
                setAddress(address);
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="state"
              label="State"
              value={job.address.state}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.state = target.value;
                setAddress(address);
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="zip"
              label="Zip"
              value={job.address.zip}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.zip = target.value;
                setAddress(address);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Mobile Phone</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="cell"
              value={job.address.cell}
              onChange={({ target }) => {
                setAddress({ ...job.address, cell: target.value });
              }}
              onBlur={({ target }) => {
                setAddress({ ...job.address, cell: formatPhone(target.value) });
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="d-flex align-items-center">
          <Form.Group>
            <Form.Check
              type="checkbox"
              id="textOK"
              label="OK to text this number"
              checked={job.textOK}
              onChange={({ target }) =>
                setJob({ ...job, textOK: target.checked })
              }
              inline
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Additional Phone 1</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="otherPhone"
              label="Additional Phone 1"
              value={job.address.phone}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.phone = target.value;
                setAddress(address);
              }}
              onBlur={({ target }) => {
                setAddress({
                  ...job.address,
                  phone: formatPhone(target.value)
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="form-group">
            <Form.Label>Additional Phone 2</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="otherPhone2"
              label="Additional Phone 2"
              value={job.address.phone2}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.phone2 = target.value;
                setAddress(address);
              }}
              onBlur={({ target }) => {
                setAddress({
                  ...job.address,
                  phone2: formatPhone(target.value)
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="form-group">
            <Form.Label>Email (needed for warranty information)</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="email"
              value={job.address.email}
              onChange={({ target }) => {
                const address = { ...job.address };
                address.email = target.value;
                setAddress(address);
              }}
            />
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              Please add additional decision makers and their contact info below
            </Form.Label>
            <Form.Control
              as="textarea"
              className="form-control"
              id="comments"
              value={job.comments}
              onChange={({ target }) => {
                setJob({ ...job, comments: target.value });
              }}
              type="textarea"
              rows={3}
            />
          </Form.Group>
        </Col>

        <Col xs={12} justify="center" className="action-wrapper">
          <Button
            color="primary"
            type="submit"
            onClick={() => {
              handleConfirm(doFormats());
            }}
          >
            Update and Save Information
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default EditForm;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'context/Context';
const runtimeConfigFile = '/runtime2.json';

const ConfigProvider = ({ children }) => {
  const [db, setDb] = useState();
  const [fabricator, setFabricator] = useState();
  const [endpoint, setEndpoint] = useState();
  const [endpoint2, setEndpoint2] = useState();
  const [libraryEndpoint, setLibraryEndpoint] = useState();
  const [test, setTest] = useState(null);
  const [filesBucket, setFilesBucket] = useState();

  useEffect(() => {
    async function getConfig() {
      try {
        console.log(`Getting config`);
        const res = await fetch(runtimeConfigFile); // if not found, return 500
        const data = await res.json();
        console.log(data);
        setTest(data.test);
        setDb(data.db);
        setFabricator(data.fabricator);
        setEndpoint(data.endpoint);
        setEndpoint2(data.endpoint2);
        setLibraryEndpoint(data.libraryEndpoint);
        setFilesBucket(data.filesBucket);
      } catch (e) {
        console.log(e);
      }
    }
    getConfig();
  }, []);

  const value = {
    db,
    fabricator,
    endpoint,
    endpoint2,
    test,
    filesBucket,
    libraryEndpoint
  };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node
};

export default ConfigProvider;

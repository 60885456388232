import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Video2 from './components/Video2';
import WrapperPlain from './components/WrapperPlain';
import { useNavigate } from 'react-router-dom';
import { prices } from 'prices';

export default function ChipMinimizerExplanation() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <WrapperPlain>
      <div>
        <p>
          Undermount sinks are fantastic, but the stone around the sink is
          vulnerable to chipping - and those repairs aren't covered under
          warranty, so they used to be pricey to fix! <br />
          <br />
          That's why we developed a special treatment to prevent chipping before
          it happens. We ease the inside edge to smooth it out, making it less
          fragile
        </p>
      </div>
      <div></div>
      <Video2
        file={
          'https://library.countertopwizard.com/OtherAsset%2Fcw-chip-minimizer%2FChip%20Minimizer%20(Low%20Volume%20Music).mp4#t=0.1'
        }
      />
      <p>
        The cost of the Chip Minimizer is {prices.chipMinimizer}, which is less
        that the trip charge for a repair.
      </p>
      <div>
        <div>
          <Button
            className="w-100 my-3"
            variant="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
    </WrapperPlain>
  );
}

import React, { useContext, useEffect } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomCheckbox from '../components/CustomCheckbox';
import Wrapper from '../components/Wrapper';

const OldCabinets = () => {
  const navigate = useNavigate();
  const {
    cabinetInfo,
    setCabinetInfo,
    job,
    handleCabinetDetails,
    cabinetFollowUp
  } = useContext(WizardContext);

  useEffect(() => {
    if (!cabinetFollowUp() && (!job.roomInfo || job.cabinetsConfirmed)) {
      navigate('../..');
    }
  }, []);
  const toggleCheck = value => {
    setCabinetInfo({ ...cabinetInfo, confirmedLevel: value });
  };
  const next = () => {
    handleCabinetDetails();
    navigate('../..');
  };
  return (
    <Wrapper>
      <p>
        You'll need to make sure any existing cabinets are secure and{' '}
        <strong>
          level within an 1/8th of an inch across a 6-foot expanse
        </strong>{' '}
        before we measure. If the tops aren't level, our technician won't be
        able to complete your template, and there is a fee for a second trip.
      </p>

      <CustomCheckbox
        name="old cabinets"
        type="radio"
        label=" I understand that my cabinets must be level before template"
        value="understand"
        toggleCheck={toggleCheck}
        checked={cabinetInfo?.confirmedLevel === 'understand'}
      ></CustomCheckbox>
      <CustomCheckbox
        name="old cabinets"
        type="radio"
        label="I have questions about this, please contact me"
        value="question"
        toggleCheck={toggleCheck}
        checked={cabinetInfo?.confirmedLevel === 'question'}
      ></CustomCheckbox>
      {cabinetInfo?.confirmedLevel === 'question' && (
        <p>
          This can be confusing! If you select this option, I'll call to discuss
          what it means to have your cabinets level before scheduling your
          digital template.
        </p>
      )}
      <Button
        className="w-100 my-3"
        variant="primary"
        onClick={next}
        disabled={!cabinetInfo?.confirmedLevel}
      >
        Continue
      </Button>
    </Wrapper>
  );
};

export default OldCabinets;

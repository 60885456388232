import React, { Fragment } from 'react';
import { Spinner, Row } from 'react-bootstrap';

const Saving = () => {
  return (
    <Fragment>
      <div className="spinner-wrapper-home">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Saving...</span>
        </Spinner>
      </div>

      <Row className="flex-center">Saving your information ...</Row>
    </Fragment>
  );
};

export default Saving;

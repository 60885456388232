import React, { useState, useContext, useEffect } from 'react';
import Wrapper from './components/Wrapper';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';

function Template() {
  const [currentSelection, setCurrentSelection] = useState('');
  const [currentSelection2, setCurrentSelection2] = useState('');
  const [moreInfoText, setMoreInfoText] = useState('');
  const { job, handleTemplate, decisionMaker, setDecisionMaker } =
    useContext(WizardContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!job.template || job.template.status !== 'Pending Approval') {
      navigate('../');
    }
  }, []);

  function formatDate(date) {
    const m = date.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (!m) return date;
    try {
      const newDate = new Date(m[1], m[2] - 1, m[3]);
      return newDate.toLocaleDateString();
    } catch {
      console.log('Failure formatting template date');
    }
    return date;
  }

  function enabled() {
    if (!currentSelection) return false;
    if (currentSelection === 'no') {
      return currentSelection2 || job?.template?.second;
    }
    // top level must be yes
    if (decisionMaker?.who === 'me') return true;
    if (decisionMaker?.who === 'other') {
      return decisionMaker?.name && decisionMaker?.cell && decisionMaker?.role;
    }
    return false;
  }

  const handleChange = e => {
    // console.log(e);
    if (e.target.id === 'me') {
      setDecisionMaker({ ...decisionMaker, who: 'me' });
    } else if (e.target.id === 'other') {
      setDecisionMaker({ ...decisionMaker, who: 'other' });
    } else {
      setDecisionMaker({ ...decisionMaker, [e.target.id]: e.target.value });
    }
  };

  return (
    <Wrapper>
      <div className="mt-3">
        <h4>Confirm Date of Measure</h4>
        {job?.template?.second ? (
          <>
            <p>
              Ok, we found another opening on{' '}
              <strong className="fw-bold">
                {job?.template?.dateFormatted}
              </strong>
              . to come measure for your new countertops. Please confirm to
              avoid any delays!
            </p>
            <Alert variant="secondary">
              Reminder - we provide you with an estimated time of arrival 2 days
              before the measure appointment, but{' '}
              <strong>
                <em>you will need to be available for the entire day.</em>
              </strong>
            </Alert>
          </>
        ) : (
          <>
            <p>
              Good news! We can do a final measure for your new countertops on{' '}
              <strong className="fw-bold">
                {job?.template?.dateFormatted}
              </strong>
              .
            </p>
            <Alert variant="secondary">
              We provide you with an estimated time of arrival 2 days before the
              measure appointment, but{' '}
              <strong>
                <em>
                  please note that you will need to be available for the entire
                  day.
                </em>
              </strong>
            </Alert>
            <p>
              Our schedule is filling up fast, so please confirm to lock in your
              spot!
            </p>
          </>
        )}
      </div>
      <Form className="outdoor-field">
        <>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="template"
              id="yes"
              label={<>Yes, that works!</>}
              checked={currentSelection === 'yes'}
              onChange={() => {
                setCurrentSelection('yes');
              }}
            />
          </Row>
        </>
        {currentSelection === 'yes' && (
          <>
            <div className="mx-2">
              <p>
                When we arrive to do your final measure, we will need a decision
                maker onsite{' '}
                <strong>
                  <em>throughout the entire process</em>
                </strong>{' '}
                to approve any changes and financial adjustments. Who will that
                be?
              </p>

              <Form.Check
                type="radio"
                name="decisionmaker"
                id="me"
                label={
                  <>
                    Myself
                    <br />{' '}
                    <span className="fw-normal" style={{ fontStyle: 'italic' }}>
                      {job?.address?.contactName}
                      <br />
                      Cell #:{' '}
                      {job?.address?.cell ||
                        job?.address?.phone ||
                        job?.address?.phone2}
                    </span>
                  </>
                }
                checked={decisionMaker?.who === 'me'}
                onChange={handleChange}
              />

              <Form.Check
                type="radio"
                name="decisionmaker"
                id="other"
                label={<>Additional Decision Maker</>}
                checked={decisionMaker?.who === 'other'}
                onChange={handleChange}
              />

              <div className="mx-4">
                <Col lg={12}>
                  <Form.Group className="form-group">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="form-group">
                    <Form.Label>Cell #</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="cell"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="form-group">
                    <Form.Label>Role</Form.Label>
                    <Form.Select
                      type="select"
                      className="form-control"
                      id="role"
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      <option value="contractor">Contractor</option>
                      <option value="designer">Designer</option>
                      <option value="homeowner">Homeowner</option>
                      <option value="family">Family/friend</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </div>
            </div>
          </>
        )}
        <>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="template"
              id="no"
              label={
                job?.template?.second ? (
                  <>No, I can't make that work. Please call me to discuss.</>
                ) : (
                  <>No, I can't make that work</>
                )
              }
              checked={currentSelection === 'no'}
              onChange={() => {
                setCurrentSelection('no');
              }}
            />
          </Row>
        </>
        {currentSelection === 'no' && !job?.template?.second && (
          <>
            <hr />
            <p className="fs--1 ms-2">
              Are you sure? The next opening we get in your area could be much
              farther out. If you're eager to have your new countertops
              installed, you may want to juggle some things on your schedule to
              make this measure date work.
            </p>
            <Row className="pl-3">
              <Form.Check
                type="radio"
                name="template2"
                id="yes2"
                label={`Ok, I'll find a way to make ${formatDate(
                  job?.template?.date
                )} work`}
                checked={currentSelection2 === 'yes2'}
                onChange={() => {
                  setCurrentSelection2('yes2');
                }}
              />
            </Row>
            <Row className="pl-3">
              <Form.Check
                type="radio"
                name="template2"
                id="no2"
                label={`No, I really can't make that date work`}
                checked={currentSelection2 === 'no2'}
                onChange={() => {
                  setCurrentSelection2('no2');
                }}
              />
            </Row>
            {currentSelection2 === 'no2' && (
              <Row className="me-1">
                <p className="fs--1 ms-2">
                  If there are dates or days of the week that you know will NOT
                  work, please provide that info here:
                </p>
                <Form.Control
                  as="textarea"
                  bssize="sm"
                  id="moreInfo"
                  value={moreInfoText}
                  onChange={({ target }) => setMoreInfoText(target.value)}
                  rows={5}
                />
              </Row>
            )}
          </>
        )}
        {/* <>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="splashtype"
              id="notsure"
              label="I'm not sure ... please call me to discuss"
              checked={currentSelection === 'notsure'}
              onChange={() => {
                setCurrentSelection('notsure');
              }}
            />
          </Row>
        </> */}
        <Row className="flex-center py-3">
          <Button
            color="primary"
            className="mx-2 my-1 save-comment-btn"
            disabled={!enabled()}
            onClick={() => {
              const status =
                currentSelection === 'yes' || currentSelection2 === 'yes2'
                  ? 'Approved'
                  : job.template.second || currentSelection === 'notsure'
                  ? 'Will Call'
                  : 'Need New Date';
              const template = { ...job.template, status };
              const details = {
                answer: currentSelection2 === 'yes2' ? 'yes' : currentSelection,
                areYouSure: currentSelection2,
                moreInfoText
              };
              handleTemplate(details.answer, template, details);
              navigate('../');
            }}
          >
            Continue
          </Button>
        </Row>
      </Form>
    </Wrapper>
  );
}
export default Template;
